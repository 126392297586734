import React, { useState,useEffect } from "react";
import SideBar from "../components/Sidebar";
import { Container, Row, Col } from "react-bootstrap";
import { Doughnut, Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Axios from "axios";
import { DatePicker, Space, Layout, Button, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Loader from "../components/Loader";
import Message from "../components/Message";

import VendorSidebar from "./VendorSidebar";
import CardWrapper from "../components/CardWrapper";
const { Header, Footer, Content } = Layout;
const MyEarnings = ({ history }) => {
  const { RangePicker } = DatePicker;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo) {
    history.push("/login");
  }

  const [loading, setLoading] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState("");
  const [dateInterval, setDateInterval] = useState("");
  const [result, setResult] = useState([]);

  useEffect(() => {
    // getPaymentData();
    getEarningDetails();
    window.scrollTo(0, 0);
  }, [dateInterval]);
  const getEarningDetails = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    try {
      let qry = "";
      dateInterval
        ? (qry = `/api/miscellaneous/estimate-earnings?dateInterval=${dateInterval}`)
        : startDate && endDate
        ? (qry = `/api/miscellaneous/estimate-earnings?startDate=${startDate}&endDate=${endDate}`)
        : (qry = `/api/miscellaneous/estimate-earnings?`);
      let response = await Axios.get(qry, config);
      if (response.status === 200) {
        setResult(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const handleDateInterval=(calendar)=>{
    setStartDate("");
    setEndDate("");
    setDateInterval(calendar)
  }

  const salesCategory = {
    labels: [
      "Clothing",
      "Music Instruments",
      "Health & Beauty Products",
      "Health Supplements",
      "Electronics",
      "Travel Products",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const monthlySales = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Total income",
        data: [12, 19, 3, 5, 2, 3, 16, 10, 18, 15, 8, 6],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <section>
        <Container
          fluid={userInfo && userInfo?.isAdminLogin === true ? true : false}
        >
          <Row>
            <div className="col-md-12">
              <h1 className="main-heading"> </h1>
            </div>
          </Row>
          <Row>
            {userInfo && userInfo?.isAdminLogin !== true && (
              <VendorSidebar profile={true} />
            )}
            <Col
              md={12}
              lg={userInfo?.isAdminLogin === true ? 12 : 9}
              xl={userInfo?.isAdminLogin === true ? 12 : 9}
            >
              <div className="paymentMethod-main wishlist-item-sec">
                <h4 className="heading">My Earnings</h4>
                {error && <Message variant="danger">{error}</Message>}

                {
                  <Layout className="myEarningsPicker">
                    <Header style={{ backgroundColor: "white" }}>
                      <Row>
                        <Col md={6}>
                          <span>
                            <h3>
                              Commission Rate: {result?.PlatformCommissionRate}%{" "}
                            </h3>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={7}>
                          <RangePicker
                            onChange={(e) => {
                              if (e.length != 0) {
                                setStartDate(
                                  e[0]?.toISOString()?.split("T")[0]
                                );
                                setEndDate(e[1]?.toISOString()?.split("T")[0]);
                              }
                            }}
                          />
                          <Button
                            type="primary"
                            icon={<SearchOutlined />}
                            onClick={() => {
                              setDateInterval("");
                              getEarningDetails();
                            }}
                          >
                            Estimate
                          </Button>
                        </Col>
                        <Col xl={5}>
                          <div className="monthlyButtons">
                            <Button
                              className="primary "
                              onClick={()=>handleDateInterval('today')}
                            >
                              Today
                            </Button>
                            <Button
                              className="secondary"
                              onClick={()=>handleDateInterval('weekly')}
                            >
                              Weekly
                            </Button>
                            <Button
                              className="success"
                              onClick={()=>handleDateInterval('monthly')}
                            >
                              Monthly
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Header>
                  </Layout>
                }

                {loading ? (
                  <Loader />
                ) : (
                  <section className="earnings-boxes">
                    <Row>
                      <Col xl={4} sm={6}>
                        <CardWrapper
                          title={" Total Orders"}
                          data={result?.totalOrders}
                          icon={"fas fa-shopping-bag"}
                          colorData={"dash-icon text-orange"}
                        />
                      </Col>
                      <Col xl={4} sm={6}>
                        <CardWrapper
                          title={" Total Sales"}
                          data={"$" + result?.totalSales}
                          icon={"fas fa-money-bill"}
                          colorData={"dash-icon text-warning"}
                        />
                      </Col>
                      <Col xl={4} sm={6}>
                        <CardWrapper
                          title={"My Earnings"}
                          data={"$" + result?.totalEarnings}
                          icon={"fas fa-dollar-sign"}
                          colorData={"dash-icon text-primary"}
                        />
                      </Col>
                      <Col xl={4} sm={6}>
                        <CardWrapper
                          title={" Total Platform Fees"}
                          data={"$" + result?.totalPlatformFees}
                          icon={"fas fa-dollar-sign"}
                          colorData={"dash-icon text-warning"}
                        />
                      </Col>
                      <Col xl={4} sm={6}>
                        <CardWrapper
                          title={" Total Merchant Fees"}
                          data={"$" + result?.totalMerchantFees}
                          icon={"fas fa-dollar-sign"}
                          colorData={"dash-icon text-secondary"}
                        />
                      </Col>
                      <Col xl={4} sm={6}>
                        <CardWrapper
                          title={"Shipping Charges"}
                          data={"$" + result?.totalShippingCharges}
                          icon={"fas fa-dollar-sign"}
                          colorData={"dash-icon text-primary"}
                        />
                      </Col>
                    </Row>
                  </section>
                )}

                {/* <section className="earnings-boxes">
                <Row>
                  <Col xl={5}>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Sales By Category</h3>
                        </div>
                        <div className="card-body">
                            <Doughnut data={salesCategory} />
                        </div>
                    </div>
                  </Col>
                  <Col xl={7}>
                    <div className="card monthlySales">
                        <div className="card-header">
                            <h3 className="card-title">Monthly Sales Statistics</h3>
                        </div>
                        <div className="card-body">
                          <Bar data={monthlySales} options={options} />
                        </div>
                    </div>
                  </Col>
                </Row>
              </section>
              <section className="earnings-boxes widget-table-two">
                <Row>
                  <Col xl={12}>
                    <div className="card">
                        <div className="card-header">
                                <h3 className="card-title">Recent Orders</h3>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th><div className="th-content">Customer</div></th>
                                        <th><div className="th-content">Product</div></th>
                                        <th><div className="th-content">Invoice</div></th>
                                        <th><div className="th-content th-heading">Price</div></th>
                                        <th><div className="th-content">Status</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                          <div className="td-content customer-name">
                                            <img src="../assets/img/user-img-1.png" alt="avatar" />
                                            <span>Luke Ivory</span>
                                          </div>
                                        </td>
                                        <td><div className="td-content product-brand text-primary">Headphone</div></td>
                                        <td><div className="td-content product-invoice">#46894</div></td>
                                        <td><div className="td-content pricing"><span className="">$56.07</span></div></td>
                                        <td><div className="td-content"><span className="badge badge-success">Paid</span></div></td>
                                    </tr>
                                    
                                    <tr>
                                        <td>
                                          <div className="td-content customer-name">
                                            <img src="../assets/img/user-img-1.png" alt="avatar" />
                                            <span>Andy King</span>
                                          </div>
                                        </td>
                                        <td><div className="td-content product-brand text-warning">Nike Sport</div></td>
                                        <td><div className="td-content product-invoice">#76894</div></td>
                                        <td><div className="td-content pricing"><span className="">$88.00</span></div></td>
                                        <td><div className="td-content"><span className="badge badge-primary">Shipped</span></div></td>
                                    </tr>
                                    <tr>
                                        <td>
                                          <div className="td-content customer-name">
                                            <img src="../assets/img/user-img-1.png" alt="avatar" />
                                            <span>Laurie Fox</span>
                                          </div>
                                        </td>
                                        <td><div className="td-content product-brand text-danger">Sunglasses</div></td>
                                        <td><div className="td-content product-invoice">#66894</div></td>
                                        <td><div className="td-content pricing"><span className="">$126.04</span></div></td>
                                        <td><div className="td-content"><span className="badge badge-success">Paid</span></div></td>
                                    </tr>                                            
                                    <tr>
                                        <td>
                                          <div className="td-content customer-name">
                                            <img src="../assets/img/user-img-1.png" alt="avatar" />
                                            <span>Ryan Collins</span>
                                          </div>
                                        </td>
                                        <td><div className="td-content product-brand text-warning">Sport</div></td>
                                        <td><div className="td-content product-invoice">#89891</div></td>
                                        <td><div className="td-content pricing"><span className="">$108.09</span></div></td>
                                        <td><div className="td-content"><span className="badge badge-primary">Shipped</span></div></td>
                                    </tr>
                                    <tr>
                                        <td>
                                          <div className="td-content customer-name">
                                            <img src="../assets/img/user-img-1.png" alt="avatar" />
                                            <span>Irene Collins</span>
                                          </div>
                                        </td>
                                        <td><div className="td-content product-brand text-primary">Speakers</div></td>
                                        <td><div className="td-content product-invoice">#75844</div></td>
                                        <td><div className="td-content pricing"><span className="">$84.00</span></div></td>
                                        <td><div className="td-content"><span className="badge badge-danger">Pending</span></div></td>
                                    </tr>
                                    <tr>
                                        <td>
                                          <div className="td-content customer-name">
                                            <img src="../assets/img/user-img-1.png" alt="avatar" />
                                            <span>Sonia Shaw</span>
                                          </div>
                                        </td>
                                        <td><div className="td-content product-brand text-danger">Watch</div></td>
                                        <td><div className="td-content product-invoice">#76844</div></td>
                                        <td><div className="td-content pricing"><span className="">$110.00</span></div></td>
                                        <td><div className="td-content"><span className="badge badge-success">Paid</span></div></td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                        </div>
                    </div>
                      
                  </Col>
                </Row>                  
              </section>  
                          */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MyEarnings;
