import React from 'react'

const CardWrapper = ({title,data=0,icon,colorData}) => {
  return (
    <div className="card">
    <div className="card-body">
      <div className="row mb-1">
        <div className="col">
          <p className="mb-1">{title}</p>
          <h3
            className="mb-0 number-font"
            style={{ padding: 0 }}
          >
            {data}
          </h3>
        </div>
        <div className="col-auto mb-0">
          <div className={`dash-icon text-orange ${colorData}`}>
            <i className={icon?icon:"fas fa-shopping-bag"}></i>
          </div>
        </div>
      </div>
      {/* <span className="fs-12 text-muted"> <strong>2.6%</strong> <i className="las la-arrow-up"></i> <span className="text-muted fs-12 ml-0 mt-1">than last week</span></span> */}
    </div>
  </div>
  )
}

export default CardWrapper