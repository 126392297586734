import { Table } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const OrderAttendeeList = () => {
  const [searchText, setSearchText] = useState("");
  const [filterModel, setFilterModel] = useState(false);
  const [data, setData] = useState([]);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [isLoading, setIsLoading] = useState(false);
  const [ticket, setTicket] = useState("");
  const params = useParams();
  const [tickets, setTickets] = useState([]);

  const { orderId } = params;

  useEffect(() => {
    getAttendees();
  }, []);

  const getAttendees = async () => {
    try {
      setIsLoading(true);
      const res = await Axios.get(
        `${process.env.REACT_APP_EVENT_API_URL}/admin/orders/attendees/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.eventToken}`,
          },
        }
      );
      if (res.data.status === 1) {
        setData(res.data.data);
        setTickets(res.data.data[0].tickets);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const handleSearch = async () => {};

  const handleFilter = async () => {
    try {
      setIsLoading(true);
      const res = await Axios.get(
        `${process.env.REACT_APP_EVENT_API_URL}/admin/orders/attendees/${orderId}?ticket=${ticket}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.eventToken}`,
          },
        }
      );
      if (res.data.status === 1) {
        setData(res.data.data);
        setTickets(res.data.data[0].tickets);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      width: "20%",
      render: (text, response) => `${response.eventName}`,
    },
    {
      title: "Attendee Name",
      dataIndex: "attendeeName",
      key: "attendeeName",
      width: "15%",
      render: (text, response) => response.name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      render: (text, response) => response.email,
    },

    {
      title: "Ticket Type",
      dataIndex: "ticketType",
      key: "ticketType",
      width: "10%",
      render: (text, response) => response.ticketType,
    },
    // {
    //   title: "Date of Order",
    //   dataIndex: "dateOfOrder",
    //   key: "dateOfOrder",
    //   width: "15%",
    //   render: (text, response) =>
    //     new Date(response.createdAt).toISOString().split("T")[0],
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, response) => response.status,
    },
    // {
    //   title: "Guard",
    //   dataIndex: "isGuard",
    //   key: "isGuard",
    //   width: "10%",
    //   render: (text, response) =>
    //     response.isGuard === true ? (
    //       <i className="fas fa-check" style={{ color: "green" }}></i>
    //     ) : (
    //       <i className="fas fa-times" style={{ color: "red" }}></i>
    //     ),
    // },
    // {
    //   title: "Show Attendees",
    //   dataIndex: "showAttendees",
    //   key: "showAttendees",
    //   width: "20%",
    //   render: (text, response) => (
    //     <>
    //       <Link to={`/admin/event/orders/attendees/${response._id}`}>
    //         Show Attendees
    //       </Link>
    //     </>
    //   ),
    // },
  ];
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading">Attendees</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="paymentMethod-main">
                <div>
                  <>
                    <div>
                      <Row className="align-items-center">
                        <Col className="md-6">
                          <h4 className="heading">Attendees Management</h4>
                        </Col>
                        <Col className="text-right">
                          <Button
                            variant="secondary"
                            className="my-3"
                            onClick={() => setFilterModel(true)}
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                        </Col>
                        {/* <Col>
                          <Form.Group
                            className="d-flex justify-content-end"
                            style={{
                              width: "300px",
                            }}
                          >
                            <input
                              placeholder="Search..."
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button onClick={handleSearch}>Search</Button>
                          </Form.Group>
                        </Col> */}
                      </Row>

                      <Table
                        dataSource={data}
                        columns={columns}
                        rowKey={"_id"}
                        loading={isLoading}
                        // expandable={{
                        //   expandedRowRender: (record) => {
                        //     return (
                        //       <div>
                        //         <span className="h5 mb-2">Tickets</span>
                        //         {record.tickets.map((ticket) => (
                        //           <p className="h6">Name: {ticket.name}</p>
                        //         ))}
                        //       </div>
                        //     );
                        //   },
                        // }}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={filterModel} onHide={() => setFilterModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Ticket Type</Form.Label>
                  <Form.Control
                    value={ticket}
                    onChange={(e) => {
                      setTicket(e.target.value);
                    }}
                    as="select"
                  >
                    <option value="">Please select Ticket</option>
                    {tickets?.map((ticket) => {
                      return <option value={ticket._id}>{ticket.name}</option>;
                    })}
                    {/* <option value="guard">guard</option>
                    <option value="promoter">promoter</option> */}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setFilterModel(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFilter}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderAttendeeList;
