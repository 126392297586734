import React, {useState} from 'react'
import {Container, Row, Col, Modal, Button} from 'react-bootstrap'

const SizeChart = ({sizeChart}) => {

          const [show, setShow] = useState(false);

          const handleClose = () => setShow(false);
          const handleShow = () => setShow(true);
        
          return(
                    <>
                     <Button style={{background:'none', color: '#2aa8f2',  paddingTop: '0px', paddingBottom: '10px', paddingLeft : '0px'}} onClick={handleShow}>
                         Size Chart?
                     </Button>
                     <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                             
                    </Modal.Header>
                    <Modal.Body>
                    <section className="policy-sec bg-gray">
            <Container>
                <Row>
                    <Col md={12}>
                       <img src={sizeChart}/>
                    </Col>
                </Row>
            </Container>
        </section>
                
                    </Modal.Body>
                    <Modal.Footer>
                             
                              <Button variant="primary" onClick={handleClose}>
                              Ok
                              </Button>
                    </Modal.Footer>
                    </Modal>
                    </>
          )

}

export default SizeChart