import React from "react";

const DashboardCard = ({
  title = "merch",
  amount = 100,
  percentage = 100,
  backgroundColor = "#ffffff",
  colorColor = "#000000",
  isProfile = false,
  subTitle1,
  subTitle2,
  subData2,
}) => {
  return (
    <div
      className="text-center
   
    rounded
    p-3
    m-2
    w-25
    shadow
    shadow-lg
    

    

    "
      style={{
        background: backgroundColor,
        color: colorColor,
        minHeight: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div>
        <span
          className="h4"
          style={{
            color: colorColor,
          }}
        >
          {title.toUpperCase()}
        </span>
      </div>
      <div
        style={{
          ...(subTitle1 && {
            display: "flex",
            justifyContent: "space-between",
          }),
        }}
      >
        {subTitle1 && <span>{subTitle1}</span>}

        <span>${amount}</span>
      </div>
      {subTitle2 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{subTitle2}</span>
          <span>${subData2}</span>
        </div>
      )}

      {/* <div className={isProfile ? "text-success" : "text-danger"}>
        {isProfile ? (
          <i class="las la-arrow-circle-up "></i>
        ) : (
          <i class="las la-arrow-circle-down "></i>
        )}
        <span>{percentage}%</span>
      </div> */}
    </div>
  );
};

export default DashboardCard;
