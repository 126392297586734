import React, { useState, useEffect } from "react";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { Table, Space, Popconfirm } from "antd";
import "antd/dist/antd.css";
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  Pagination,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";

const ReportedPosts = () => {
  const [posts, setPosts] = useState({
    posts: [],
    pages: 0,
  });

  const [comments, setComments] = useState({
    comments: [],
    pages: 0,
  });

  const [deleteModel, setDeleteModel] = useState(false);

  const [tab, setTab] = useState("posts");
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const { userInfo } = useSelector((state) => state.userLogin);
  // const [users, setUsers] = useState([]);

  // const getUsers = async () => {
  //   let config = {
  //     headers: {
  //       Authorization: `Bearer ${userInfo.token}`,
  //     },
  //   };
  //   try {
  //     setIsLoading(true);
  //     const res = await axios.get(`/api/users`, config);
  //     setUsers(res.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setError(error.message);
  //   }
  // };

  // useEffect(() => {
  //   getUsers();
  // }, []);

  const handleDeletePost = async (id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    try {
      setIsLoading(true);
      const res = await axios.delete(`/api/social/posts/${id}`, config);
      //   setPosts({
      //     ...posts,
      //     posts: posts.posts.filter((post) => post.id !== id),
      //   });
      await getPosts();
      setIsLoading(false);

      // alert("Post deleted successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const postColumn = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "80%",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "10%",

      render: (params) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDeletePost(params.object_id)}
        >
          <Button variant="danger" className="btn-sm">
            <i className="fas fa-trash"></i>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const getPosts = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    try {
      setIsLoading(true);
      const result = await axios.get(
        `/api/social/reported/posts?page=${page}`,
        config
      );

      setPosts(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      setError("No posts ");
    }
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  //   const handleSearch = async () => {
  //     let config = {
  //       headers: {
  //         Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     };
  //     try {
  //       if (tab === "posts") {
  //         setIsLoading(true);
  //         const result = await axios.get(
  //           `/api/social/posts?q=${searchText}&page=${page}`,
  //           config
  //         );

  //         setPosts(result.data);
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(true);
  //         const result = await axios.get(
  //           `/api/social/comments?q=${searchText}&page=${page}`,
  //           config
  //         );

  //         setComments(result.data);
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       setIsLoading(false);

  //       setError("No posts");
  //     }
  //   };

  const handleNext = () => {
    setPage(page + 1);
  };

  const handlePrev = () => {
    setPage(page - 1);
  };
  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1 className="main-heading">Posts</h1>
          </div>
        </div>
        <div className="row">
          {/* <VendorSidebar profile={true} /> */}
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="paymentMethod-main">
              <Row
                className="align-items-center "
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Col>
                  <h4 className="heading">Reported Posts</h4>
                </Col>
                <Row className=" ">
                  {/* <Col className="d-flex justify-content-end">
                    <Form.Group
                      className="d-flex justify-content-end"
                      style={{
                        width: "300px",
                      }}
                    >
                      <input
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <Button onClick={handleSearch}>Search</Button>
                    </Form.Group>
                  </Col> */}
                </Row>
              </Row>
              {isLoading ? (
                <Loader />
              ) : error ? (
                <Message variant="danger">{error}</Message>
              ) : tab === "posts" ? (
                <>
                  <Table
                    loading={isLoading}
                    columns={postColumn}
                    dataSource={posts.posts}
                    rowKey={"id"}
                    pagination={false}
                  />

                  <Pagination
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Pagination.Item
                      disabled={page == 1 ? true : false}
                      onClick={handlePrev}
                    >
                      {"Prev"}
                    </Pagination.Item>

                    {Array.from({ length: posts.pages }, (_, i) => (
                      <Pagination.Item
                        active={i + 1 === page}
                        onClick={() => setPage(i + 1)}
                      >
                        {i + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Item
                      onClick={handleNext}
                      disabled={
                        page == posts.pages || posts.posts.length < 1
                          ? true
                          : false
                      }
                    >
                      {"Next"}
                    </Pagination.Item>
                  </Pagination>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReportedPosts;
