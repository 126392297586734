import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
} from "./reducers/productReducers";

import {
  categoryListReducer,
  categoryDetailsReducer,
  categoryDeleteReducer,
  categoryCreateReducer,
  categoryUpdateReducer,
  categoryReviewCreateReducer,
  categoryTopRatedReducer,
} from "./reducers/categoryReducers";

import { cartReducer } from "./reducers/cartReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  notificationReducer,
  adminLoginReducer,
} from "./reducers/userReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
} from "./reducers/orderReducers";
import Axios from "axios";

const reducer = combineReducers({
  // adminLogin: adminLoginReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  categoryList: categoryListReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  notification: notificationReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const getItems = async () => {
  if (localStorage.getItem("userInfo")) {
    let response = await Axios.get(
      `/api/cart/cart-items/${JSON.parse(localStorage.getItem("userInfo"))._id}`
    );
    return response.data;
  } else {
    if (localStorage.getItem("cartId")) {
      let response = await Axios.get(
        `/api/cart/cart-items/${localStorage.getItem("cartId")}`
      );
      return response.data;
    } else {
      return [];
    }
  }
};

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const billingAddressFromStorage = localStorage.getItem("billingAddress")
  ? JSON.parse(localStorage.getItem("billingAddress"))
  : {};

const promoCodeObjFromStorage = localStorage.getItem("promoCodeObj")
  ? JSON.parse(localStorage.getItem("promoCodeObj"))
  : [];

const promoCodesFromStorage = localStorage.getItem("promoCodes")
  ? JSON.parse(localStorage.getItem("promoCodes"))
  : [];

const promoDiscount = localStorage.getItem("promoDiscount")
  ? parseInt(localStorage.getItem("promoDiscount"))
  : 0;
const unreadNotifications = localStorage.getItem("unreadNotifications")
  ? parseInt(localStorage.getItem("unreadNotifications"))
  : 0;

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    billingAddress: billingAddressFromStorage,
    promoCodeObj: promoCodeObjFromStorage,
    promoCodes: promoCodesFromStorage,
    promoDiscount: promoDiscount,
  },
  userLogin: { userInfo: userInfoFromStorage },
  unNotification: unreadNotifications,
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
