import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import VendorSidebar from "./VendorSidebar";
import Loader from "../components/Loader";
import axios from "axios";
import Message from "../components/Message";

import { reset } from "colors";

const SupportDetails = ({ history, match }) => {
  let dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [userInfo, history, dispatch]);

  const [details, setDetails] = useState(null);
  const [order, setOrder] = useState(null);
  let supportId = match.params.supportId;

  const [image, setImage] = useState(null);
  const [content, setContent] = useState(() => "");
  const [loading, setLoading] = useState(() => false);
  const [url, setUrl] = useState(() => "");
  const [error, setError] = useState(() => "");

  useEffect(() => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.get(`/api/miscellaneous/get-ticket-details/${supportId}`, config)
        .then((response) => {
          setDetails(response.data.data);
          setOrder(response.data.order);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleImageChange = async (e) => {
    if (e && e.target && e.target.files[0]) {
      setImage(e.target.files[0]);
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        setLoading(true);
        const { data } = await axios.post(
          "/api/resize-image/200/product200",
          formData,
          config
        );
        setUrl(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const reset = () => {
    setImage(null);
    setContent("");
    setLoading(false);
    setUrl("");
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (userInfo) {
      if (!content || content.trim() == "") {
        setError("Write a Comment");
        setTimeout(() => {
          setError("");
        }, 3500);
      }
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      let obj = {
        supportId: supportId,
        replyType:
          match.params.type == "vendor" ? "vendor_reply" : "customer_reply",
        content: content,
        image: url,
      };
      let response = await axios.post(
        "/api/miscellaneous/contact-vendor",
        obj,
        config
      );
      setDetails(response.data.data);
      reset();
    }

    //  setDetails(ticket);
  };

  const resolved = async (id) => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      let response = await axios.put(
        `/api/miscellaneous//ticket-resolved/${id}`,
        {},
        config
      );
      setDetails(response.data.data);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <section className="accountMain-wraper">
        <Container
          fluid={userInfo && userInfo?.isAdminLogin === true ? true : false}
        >
          <Row>
            <div className="col-md-12">
              <h1 className="main-heading"> </h1>
            </div>
          </Row>
          <Row>
            {userInfo && userInfo?.isAdminLogin !== true && (
              <VendorSidebar profile={true} />
            )}
            <Col
              md={12}
              lg={userInfo && userInfo?.isAdminLogin === true ? 12 : 9}
              xl={userInfo && userInfo?.isAdminLogin === true ? 12 : 9}
            >
              {!details ? (
                <Loader />
              ) : (
                <div className="paymentMethod-main">
                  <div className="support-heading">
                    <h4>{order ? order.variantName : ""}</h4>
                    <p>Order: #{order ? order._id : ""}</p>

                    <div className="supportProduct-desc">
                      <div className="prodcutImage">
                        <img src={order ? order.productImage : ""} />
                      </div>
                      <div className="prodcutContent">
                        <ul>
                          <li>
                            <strong>Price:</strong>{" "}
                            <span>{order ? order.price : ""}</span>
                          </li>
                          <li>
                            <strong>Quantity:</strong>{" "}
                            <span>{order ? order.qty : ""}</span>
                          </li>
                          <li>
                            <strong>Shipping:</strong>{" "}
                            <span>{order ? order.shippingCharge : ""}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="support-heading">
                    <h4>{details.subject}</h4>
                    <p>Ticket ID: #{details._id}</p>
                  </div>
                  <div className="status-container">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-label">Status</div>
                        <h5>
                          <div className="badge badge-info pending">
                            {details.status}
                          </div>
                        </h5>
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3">
                        <div className="form-label">Created</div>
                        <h5>
                          {details.createdAt
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                          , {details.createdAt.split("T")[1].split(".")[0]}
                        </h5>
                      </div>
                      <div className="col-md-3">
                        <div className="form-label">Last Updated</div>
                        <h5>
                          {details.updatedAt
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                          , {details.updatedAt.split("T")[1].split(".")[0]}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="ticket-detail">
                    {details.replies.length != 0 ? (
                      details.replies.map((e) => (
                        <div className="card">
                          <div className="card-body">
                            <div
                              className={
                                match.params.type == "vendor"
                                  ? e.replyType == "vendor_reply"
                                    ? "badge ticket-from badge-secondary"
                                    : "badge ticket-from badge-info"
                                  : e.replyType == "vendor_reply"
                                  ? "badge ticket-from badge-secondary"
                                  : "badge ticket-from badge-info"
                              }
                            >
                              {match.params.type == "vendor"
                                ? e.replyType == "vendor_reply"
                                  ? "You"
                                  : "Customer"
                                : e.replyType == "vendor_reply"
                                ? "Vendor"
                                : "You"}
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 border-md-right">
                                <div className="ticket-date-container">
                                  <div className="ticket-date">
                                    <i className="far fa-calendar"></i>
                                    <span>
                                      {e.createdAt
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join("/")}
                                    </span>
                                  </div>
                                  <div className="ticket-date">
                                    <i className="far fa-clock"></i>
                                    <span>
                                      {e.createdAt.split("T")[1].split(".")[0]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-9 col-md-8">
                                <div className="ticket-detail-content">
                                  <span>{e.content}</span>
                                  <br />
                                  {e.image && <Image src={e.image} />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}

                    {/* <div className="card">
                    <div className="card-body">
                      <div className="badge ticket-from badge-secondary">20i Support</div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 border-md-right">
                          <div className="ticket-date-container">
                            <div className="ticket-date">
                              <i className="far fa-calendar"></i>
                              <span>01/04/2022</span>
                            </div>
                            <div className="ticket-date">
                              <i className="far fa-clock"></i>
                              <span>18:32:29</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <div className="ticket-detail-content">
                              <span>
                                  Hi John,<br /><br />

                                  Thanks for getting in touch.<br /><br />

                                  That's been unblocked for you, please don't run a sudo command again or it will get blocked again.<br /><br />

                                  Best wishes,<br />
                                  Connor Staton<br />
                                  20i Support
                              </span>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer updateRating">
                      <div className="rating-heading">
                        <label class="form-label mr-2">How would you rate this response?</label>
                      </div>
                    </div>
                  </div> */}

                    {/* <div className="card">
                    <div className="card-body">
                      <div className="badge ticket-from badge-info">20i Support</div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 border-md-right">
                          <div className="ticket-date-container">
                            <div className="ticket-date">
                              <i className="far fa-calendar"></i>
                              <span>01/04/2022</span>
                            </div>
                            <div className="ticket-date">
                              <i className="far fa-clock"></i>
                              <span>18:33:22</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <div className="ticket-detail-content">
                              <span>
                                Thanks
                              </span>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                    {details.status == "pending" && (
                      <div className="card update-ticket-form">
                        <div className="card-body">
                          <div className="card-title">Update Ticket</div>
                          <form>
                            <div className="form-group">
                              {error && (
                                <Message variant="danger">{error}</Message>
                              )}
                              <textarea
                                className="form-control"
                                value={content}
                                onChange={(e) => {
                                  setContent(e.target.value);
                                }}
                                rows="5"
                                placeholder="Ticket comments"
                              ></textarea>
                            </div>

                            {!loading ? (
                              <div className="file-input">
                                <input
                                  type="file"
                                  name="file-input"
                                  id="file-input"
                                  className="file-input__input"
                                  onChange={handleImageChange}
                                />
                                <label
                                  className="file-input__label"
                                  for="file-input"
                                >
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="upload"
                                    className="svg-inline--fa fa-upload fa-w-16"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                                    ></path>
                                  </svg>
                                  <span>Upload file</span>
                                </label>
                              </div>
                            ) : (
                              <div class="spinner-grow" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            )}

                            <div className="small text-muted">
                              {!url ? (
                                <span>
                                  Supported filetypes are: .jpeg, .jpg, .png
                                </span>
                              ) : (
                                <Image src={url} />
                              )}
                            </div>
                          </form>
                        </div>
                        <div className="card-footer">
                          <div className="form-group mb-0">
                            <button
                              disabled={loading}
                              className="my-1 btn btn-primary"
                              onClick={submitHandler}
                            >
                              Send Reply
                            </button>
                            {userInfo && userInfo._id == details.issuedBy && (
                              <button
                                disabled={loading}
                                className="my-1 btn btn-outline-secondary"
                                onClick={() => {
                                  resolved(details._id);
                                }}
                              >
                                Update &amp; Resolved
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SupportDetails;
