import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { adminLogout, logout } from "../../actions/userActions";
import Logo from "./assets/images/logo.png";
import UserImage from "./assets/images/user.png";

const Header = ({ isOpen, toggleMenu }) => {
  const dispatch = useDispatch();
  const handleToggel = () => {
    console.log(`handleToggel`);
    toggleMenu(!isOpen);
  };
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.userLogin);

  const logoutHandler = () => {
    dispatch(adminLogout());
  };

  return (
    <header class="box-shadow">
      <div class="toggler right-toggler" onClick={handleToggel}>
        <div class={`bar right-bar`}>
          <span class="box-shadow1"></span>
          <span class="box-shadow1"></span>
          <span class="box-shadow1"></span>
        </div>
      </div>
      <div class="main-header">
        {/* <div class="logo">
          <img src={Logo} alt="" />
        </div> */}
        <div class="account">
          {/* <span
            style={{
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={logoutHandler}
          >
            <i className="la la-mail-forward"></i>
          </span> */}
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i className="las la-user"></i>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <i className="las la-cog"></i>
              </Link>
            </li>
            <li className="nav-item">
              <button className="nav-link" onClick={logoutHandler}>
                <i className="fas fa-sign-out-alt"></i>
              </button>
            </li>
            <div className="topbar-divider d-none d-sm-block"></div>
            <li className="nav-item">
              <Link to="#" className="nav-link">
                <span>
                  {userInfo.name} {userInfo.lastName}
                </span>
                <img src={UserImage} alt="img" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
