import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import { externalAdminLogin, logout } from "../../actions/userActions";

const ExternalAdminLogin = ({ match }) => {
  let token = match.params.authId;
  let url = match.params.redirect;

  let buff = new Buffer(url, "base64");
  let buff2 = new Buffer(buff.toString("ascii"), "base64");
  let redirect = buff2.toString("ascii");

  let history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo?.isAdminLogin === true) {
      history.push(`/${redirect}`);
    } else {
      if (token) {
        Axios.post(`/api/users/handle-external-admin-login`, { token: token })
          .then((res) => {
            if (res.data.isValid) {
              dispatch(externalAdminLogin(res.data.data));
              history.push(`/${redirect}`);
            } else {
              dispatch(logout());
            }
          })
          .catch((err) => {
            dispatch(logout());
          });
      } else if (!token) {
        dispatch(logout());
      }
    }
  }, []);

  return (
    <div>
      <section>
        <div
          style={{
            fontWeight: "bold",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
          }}
        >
          <Spin size="large" tip={"Please Wait"}></Spin>
          <br />
        </div>
      </section>
    </div>
  );
};

export default ExternalAdminLogin;
