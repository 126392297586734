import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { adminBaseUrl } from "../../config";
import UserImage from "./assets/images/user.png";
import Logo from "./assets/images/logo.png";
import Axios from "axios";
import {
  SOCIAL_URL,
  MUSIC_URL,
  TV_URL,
  CLASSES_URL,
} from "../../urls/OtherUrls";
import base644 from "base-64";

const SideBar = ({ isOpen, toggleMenu }) => {
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.userLogin);
  const [subMenu, setSubMenu] = React.useState({
    userManagement: false,
    merch: false,
    social: false,
    settings: false,
    tv: false,
    music: false,
    event: false,
  });

  // console.log(subMenu.merch);

  const toggelRef = useRef();

  useEffect(() => {
    // console.log(toggelRef.current);
    if (!isOpen) {
      toggelRef.current.className = "left-sidebar active";
      if (window.innerWidth <= 1024) {
        toggelRef.current.className = "left-sidebar show";
      }
    } else {
      toggelRef.current.className = "left-sidebar ";
    }
  }, [isOpen]);

  const handleToggel = () => {
    toggleMenu(!isOpen);
  };

  const handleLinkClick = (url, platform, link) => {
    let buff = new Buffer(link).toString("base64");
    let base64 = new Buffer(buff).toString("base64");
    if (userInfo) {
      var encodedData = base644.encode(base644.encode(userInfo.masterToken));

      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // if (platform == "tv") {
      //   window.location.href = `${url}/index.php?link1=external_auth&authId=${res?.data?.authKey}&path=${base64}`;
      // } else if (platform == "music") {
      //   window.location.href = `${url}/externalLogin?authId=${res?.data?.authKey}&path=${base64}`;
      // }

      // else if (platform == "classes") {
      //   window.location.href = `${url}/admin/login?auth=${encodedData}&redirectUrl=${link}`;
      // }

      Axios.post(`/api/users/tv-login`, {}, config)
        .then((res) => {
          if (res.data.isLoggedIn) {
            if (platform == "tv") {
              window.location.href = `${url}/index.php?link1=external_auth&authId=${res.data.authKey}&path=${base64}`;
            } else if (platform == "music") {
              window.location.href = `${url}/externalLogin?authId=${res.data.authKey}&path=${base64}`;
            } else if (platform == "classes") {
              window.location.href = `${url}/admin/login?auth=${encodedData}&redirectUrl=${link}`;
            }
            // else if (platform == "event") {
            //   window.location.href = `${link}/Auth/${res.data.authKey}`;
            // }
          } else {
            window.location.href = `${link}`;
          }
        })
        .catch((err) => {});
    } else {
      window.location.href = `/admin/login`;
    }
  };

  const Menus = [
    {
      name: "Dashboard",
      link: "/admin",
      icon: "las la-home",
      isInternal: true,
    },
    {
      name: "Wallet",
      link: "/admin/wallet",
      icon: " las la-dollar-sign",
      user: false,
      vendor: false,
      admin: true,
      isInternal: true,
    },
    {
      name: "User Management",
      link: "/admin/userlist",
      isInternal: true,
      icon: "las la-user",
    },
    {
      name: "Merch",
      link: "#",
      icon: "las la-shopping-bag",
      isOpen: subMenu.merch,
      ref: "merch",
      isInternal: true,

      subMenu: [
        {
          name: "Orders Management",
          link: "/admin/ordersReceived",
          icon: "/assets/img/cart.svg",
          user: true,
          vendor: true,
          admin: true,
          isInternal: true,
        },
        {
          name: "Product Management",
          link: "/admin/productlist",
          icon: "/assets/img/account-settings.svg",
          user: false,
          vendor: true,
          admin: true,
          isInternal: true,
        },
        {
          name: "Specification Management",
          link: "/admin/specifications",
          icon: "/assets/img/account-settings.svg",
          user: false,
          vendor: true,
          admin: true,
          isInternal: true,
        },
        {
          name: "Category Management",
          link: "/admin/categorylist",
          icon: "/assets/img/manage.svg",
          user: false,
          vendor: false,
          admin: true,
          isInternal: true,
        },

        {
          name: "Promo Codes Management",
          link: "/admin/promocode",
          icon: "/assets/img/manage.svg",
          user: false,
          vendor: true,
          admin: true,
          isInternal: true,
        },
        {
          name: "Variant Management",
          link: "/admin/variants",
          icon: "/assets/img/account-settings.svg",
          user: false,
          vendor: true,
          admin: true,
          isInternal: true,
        },

        {
          name: "Account Settings",
          link: "/admin/accountSetting",
          icon: "/assets/img/account-settings.svg",
          user: false,
          vendor: false,
          admin: true,
          isInternal: true,
        },
      ],
    },

    {
      name: "Music",
      link: "javascript:void(0)",
      icon: "las la-music",
      isOpen: subMenu.music,
      ref: "music",
      subMenu: [
        {
          name: "Dashboard",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin'),
          link: "admin",
          platform: "music",
          url: MUSIC_URL,
        },

        {
          name: "Settings",
          // link: handleLinkClick(MUSIC_URL, "music", 'admin/settings'),
          link: "admin/settings",
          platform: "music",
          url: MUSIC_URL,
        },
        {
          name: "Artist Requests",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/artist-access'),
          link: "admin/artist-access",
          platform: "music",
          url: MUSIC_URL,
        },
        {
          name: "Artists",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/artists'),
          link: "admin/artists",
          platform: "music",
          url: MUSIC_URL,
        },
        {
          name: "Albums",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/albums'),
          link: "admin/albums",
          platform: "music",
          url: MUSIC_URL,
        },
        {
          name: "Songs",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/songs'),
          link: "admin/songs",
          platform: "music",
          url: MUSIC_URL,
        },
        {
          name: "Playlists",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/playlists'),
          link: "admin/playlists",
          platform: "music",
          url: MUSIC_URL,
        },
        {
          name: "Channels",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/channels'),
          link: "admin/channels",
          platform: "music",
          url: MUSIC_URL,
        },
        {
          name: "Slide Show",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/slidshow'),
          link: "admin/slideshow",
          platform: "music",
          url: MUSIC_URL,
        },
        {
          name: "Bulk upload",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/bulk'),
          link: "admin/bulk",
          platform: "music",
          url: MUSIC_URL,
        },
      ],
    },

    // {
    //   name: "Event",
    //   link: "javascript:void(0)",
    //   icon: "las la-calendar",
    //   isOpen: subMenu.event,
    //   ref: "event",
    //   subMenu: [
    //     // {
    //     //   name: "Store Management",
    //     //   link: "/store-management",
    //     // },
    //   ],
    // },
    {
      name: "TV",
      link: "javascript:void(0)",
      icon: "las la-tv",
      isOpen: subMenu.tv,
      ref: "tv",
      subMenu: [
        {
          name: "Dashboard",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/bulk'),
          link: "admin-cp/",
          platform: "tv",
          url: TV_URL,
        },
        {
          name: "Settings",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/bulk'),
          link: "admin-cp/general-settings",
          platform: "tv",
          url: TV_URL,
        },
        {
          name: "Videos",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/bulk'),
          link: "admin-cp/manage-videos",
          platform: "tv",
          url: TV_URL,
        },
        {
          name: "Categories",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/bulk'),
          link: "admin-cp/manage_categories",
          platform: "tv",
          url: TV_URL,
        },
        {
          name: "Manage Comments",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/bulk'),
          link: "admin-cp/manage-comments",
          platform: "tv",
          url: TV_URL,
        },
        {
          name: "Tools",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/bulk'),
          link: "admin-cp/manage-announcements",
          platform: "tv",
          url: TV_URL,
        },
        {
          name: "Reports",
          // link: handleLinkClick(MUSIC_URL, "music", 'music/admin/bulk'),
          link: "admin-cp/manage-video-reports",
          platform: "tv",
          url: TV_URL,
        },
      ],
    },
    {
      name: "Social",
      link: "javascript:void(0)",
      icon: "las la-user-friends",
      isOpen: subMenu.social,
      ref: "social",
      subMenu: [
        {
          name: "Post Management",
          link: "/admin/social/posts/user",
          isInternal: true,
        },
        {
          name: "Reported Posts",
          link: "/admin/social/reported/posts",
          isInternal: true,
        },
      ],
    },
    {
      name: "Event",
      link: "javascript:void(0)",
      icon: "las la-calendar",
      isOpen: subMenu.event,
      ref: "event",
      subMenu: [
        {
          name: "Earning management",
          link: "/admin/event/earnings",
          isInternal: true,
        },
        {
          name: "User management",
          link: "/admin/event/users",
          isInternal: true,
        },
        {
          name: "Event management",
          link: "/admin/event/events",
          isInternal: true,
        },
        {
          name: "Order management",
          link: "/admin/event/orders",
          isInternal: true,
        },
      ],
    },
    {
      name: "Classes",
      link: "javascript:void(0)",
      icon: "las la-video",
      isOpen: subMenu.class,
      ref: "class",
      subMenu: [
        {
          name: "Dashboard",
          link: "/",
          // icon: "las la-home",
          platform: "classes",
          url: CLASSES_URL,
        },
        {
          name: "Instructor Management",
          link: "/instructor",
          // icon: "las la-chalkboard-teacher",
          platform: "classes",
          url: CLASSES_URL,
        },

        {
          name: " Manage Permission",
          link: "/permission",
          // icon: "las la-user-cog",
          platform: "classes",
          url: CLASSES_URL,
        },
        {
          name: " Manage Category",
          link: "/category",
          // icon: "las la-camera-retro",
          platform: "classes",
          url: CLASSES_URL,
        },
      ],
    },
  ];

  const handleSubmenu = (ref) => {
    setSubMenu({ [ref]: !subMenu[ref] });
  };

  return (
    <>
      <div ref={toggelRef}>
        <div class="toggler-wraper">
          <div class="danzflo-logo">
            <img src={Logo} alt="Danzflo" />
          </div>
          <button class="burger-dshBtn" onClick={handleToggel}>
            <span class="dshBtn_toggle">
              <span class="hamburger">
                <span></span>
                <span></span>
                <span></span>
              </span>
              <span class="hamburger-cross">
                <span></span>
                <span></span>
              </span>
            </span>
          </button>
        </div>
        {/* <div className="toggler" onClick={handleToggel}>
          <div className="bar left-bar">
            <span className="box-shadow1"></span>
            <span className="box-shadow1"></span>
            <span className="box-shadow1"></span>
          </div>
        </div>
        <div className="close-toggler">
          <div className="bar colse-bar" onClick={handleToggel}>
            <span className="box-shadow1"></span>
            <span className="box-shadow1"></span>
            <span className="box-shadow1"></span>
          </div>
        </div> */}

        {/* <div className="profile">
          <div className="image">
            <img src={UserImage} alt={userInfo.userName} />
          </div>
          <div className="detail">
            <h4>
              {userInfo.name} {userInfo.lastName}
            </h4>
            <p>singer</p>
          </div>
        </div> */}
        <div className="dsh-card-wraper">
          <div className="card-user-wraper">
            <div className="card-user">
              <div className="user-img-wraper">
                <div className="user-img">
                  <img src={UserImage} alt={userInfo.userName} />
                </div>
              </div>
            </div>
            <div className="user-heading">
              <h4>
                {userInfo.name} {userInfo.lastName}
              </h4>
              <p>singer</p>
            </div>
          </div>
        </div>
        <nav>
          <ul>
            {Menus.map((menu, index) => (
              <>
                <li key={index}>
                  {menu?.isInternal ? (
                    <Link
                      to={menu.link}
                      onClick={() => handleSubmenu(menu.ref)}
                    >
                      <i className={menu.icon}></i>
                      <span className="title-link">{menu.name}</span>
                      {menu.subMenu && (
                        <span
                          className={menu.isOpen ? "down active" : "down"}
                          // onClick={() => handleSubmenu(menu.ref)}
                        >
                          <i className="fas fa-angle-right"></i>
                        </span>
                      )}
                    </Link>
                  ) : (
                    <a href={menu.link}>
                      <div onClick={() => handleSubmenu(menu.ref)}>
                        <i className={menu.icon}></i>
                        <span className="title-link">{menu.name}</span>
                        {menu.subMenu && (
                          <span
                            className={menu.isOpen ? "down active" : "down"}
                          >
                            <i className="fas fa-angle-right"></i>
                          </span>
                        )}
                      </div>
                    </a>
                  )}
                  <ul
                    key={index}
                    className={!isOpen ? "d-none sub-menu" : "sub-menu"}
                    style={{
                      display: menu.isOpen ? "block" : "none",
                    }}
                  >
                    {menu.subMenu?.map((item, index) =>
                      item.isInternal ? (
                        <li key={index}>
                          <Link
                            className={
                              location.pathname === item.link ? "active" : ""
                            }
                            to={item.link}
                          >
                            {item.name}
                          </Link>
                        </li>
                      ) : (
                        <li key={index}>
                          <a
                            className={
                              location.pathname === item.link ? "active" : ""
                            }
                            href="javascript:void(0)"
                            onClick={() => {
                              handleLinkClick(
                                item.url,
                                item.platform,
                                item.link
                              );
                            }}
                          >
                            {item.name}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              </>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SideBar;
