import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { logout } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { externalLogin } from '../actions/userActions'

const ExternalAuth = ({ match }) => {
  let token = match.params.authId;
  let userName = match.params.userName;
  let mobile = match.params.mobile ? match.params.mobile : 0;
  let history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {

    if (userInfo && !userName) {
      history.push("/merch");
    } else {
      if (userName == 'artist') {
        history.push(`/register-vendor/music/${match.params.redirect}/artist/1`)
      }
      else if (userName == 'profile') {
        // history.push('/profile');
        if (userInfo) {
          history.push('/profile');
        } else {
          history.push("/merch");
        }
      } else {
        Axios.get(`/api/users/get-user-details/${userName}`)
          .then(res => {
            history.push(`/${userName}/store/${res.data._id}`);
          })
          .catch(err => {
            console.log(err);
          })
      }

    }
  }, [userInfo])

  useEffect(() => {
    if (token && mobile) {

      Axios.post(`/api/users/handle-external-login/${mobile}`, { token: token })
        .then((res) => {

          if (res.data.isValid) {
            dispatch(externalLogin(res.data.data));
          }
          else {
            dispatch(logout());
          }
        })
        .catch(err => {
          dispatch(logout());
        })
    } else if (!token && mobile) {
      dispatch(logout());
    }
  }, [])

  return (<div>
    <section >
      <div style={{ fontWeight: 'bold', position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' }}>
        <Spin size='large' tip={'Please Wait'}></Spin>
        <br />
      </div>
    </section>
  </div>)
}

export default ExternalAuth;