import { Table } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const EventManagement = () => {
  const [searchText, setSearchText] = useState("");
  const [filterModel, setFilterModel] = useState(false);
  const [data, setData] = useState([]);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_EVENT_API_URL}/admin/events`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.eventToken}`,
          },
        }
      );
      if (res.data.status === 1) {
        setData(res.data.data.events);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      width: "20%",
      render: (text, response) => `${response.title}`,
    },
    {
      title: "Organizer Name",
      dataIndex: "registeredName",
      key: "registeredName",
      width: "15%",
      render: (text, response) =>
        `${response.organizer.firstName} ${response.organizer.lastName}`,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "10%",
      render: (text, response) => `${response.category}`,
    },

    {
      title: "Ticket Type",
      dataIndex: "ticketType",
      key: "ticketType",
      width: "10%",
      render: (text, response) => response.ticketType.length,
    },
    {
      title: "Date of Order",
      dataIndex: "dateOfOrder",
      key: "dateOfOrder",
      width: "15%",
      render: (text, response) =>
        new Date(response.createdAt).toISOString().split("T")[0],
    },
    {
      title: "Cancelled",
      dataIndex: "isCancelled",
      key: "isCancelled",
      width: "10%",
      render: (text, response) =>
        response.isCancelled === true ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
        ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
        ),
    },
    // {
    //   title: "Guard",
    //   dataIndex: "isGuard",
    //   key: "isGuard",
    //   width: "10%",
    //   render: (text, response) =>
    //     response.isGuard === true ? (
    //       <i className="fas fa-check" style={{ color: "green" }}></i>
    //     ) : (
    //       <i className="fas fa-times" style={{ color: "red" }}></i>
    //     ),
    // },
    // {
    //   title: "Show Attendees",
    //   dataIndex: "showAttendees",
    //   key: "showAttendees",
    //   width: "20%",
    //   render: (text, response) => (
    //     <>
    //       <Link to={`/admin/event/orders/attendees/${response._id}`}>
    //         Show Attendees
    //       </Link>
    //     </>
    //   ),
    // },
  ];

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let res = {};
      if (searchText !== "") {
        if (status !== "") {
          res = await Axios.get(
            `${process.env.REACT_APP_EVENT_API_URL}/admin/events?search=${searchText}&status=${status}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.eventToken}`,
              },
            }
          );
          if (res.data.status === 1) {
            setData(res.data.data.events);
          }
        } else {
          res = await Axios.get(
            `${process.env.REACT_APP_EVENT_API_URL}/admin/events?search=${searchText}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.eventToken}`,
              },
            }
          );
          if (res.data.status === 1) {
            setData(res.data.data.events);
          }
        }
      } else {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/events`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.events);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleFilter = async () => {
    setIsLoading(true);
    try {
      let res = {};

      if (searchText !== "") {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/events?search=${searchText}&status=${status}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.events);
        }
      } else {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/events?status=${status}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.events);
        }
      }
      setFilterModel(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading">Events</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="paymentMethod-main">
                <div>
                  <>
                    <div>
                      <Row className="align-items-center">
                        <Col className="md-6">
                          <h4 className="heading">Event Management</h4>
                        </Col>
                        <Col className="text-right">
                          <Button
                            variant="secondary"
                            className="my-3"
                            onClick={() => setFilterModel(true)}
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                        </Col>
                        <Col>
                          <Form.Group
                            className="d-flex justify-content-end"
                            style={{
                              width: "300px",
                            }}
                          >
                            <input
                              placeholder="Search..."
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button onClick={handleSearch}>Search</Button>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Table
                        dataSource={data}
                        columns={columns}
                        rowKey={"_id"}
                        loading={isLoading}
                        //   expandable={{
                        //     expandedRowRender: (record) => {
                        //       return (
                        //         <div>
                        //           <span className="h5 mb-2">Tickets</span>
                        //           {record.tickets.map((ticket) => (
                        //             <p className="h6">Name: {ticket.name}</p>
                        //           ))}
                        //         </div>
                        //       );
                        //     },
                        //   }}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={filterModel} onHide={() => setFilterModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    as="select"
                  >
                    <option value="">Please select status</option>
                    <option value="active">active</option>
                    <option value="cancelled">cancelled</option>
                    <option value="upcoming">upcoming</option>

                    {/* <option value="guard">guard</option>
                 <option value="promoter">promoter</option> */}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setFilterModel(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFilter}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventManagement;
