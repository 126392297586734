import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import Sidebar from "../components/Sidebar";
import { Row, Col, Container } from "react-bootstrap";
import Axios from "axios";
import ReviewProduct from "./ReviewProduct";
import { useSelector } from "react-redux";
import VendorSidebar from "./VendorSidebar";
// import VendorSidebar from "./VendorSidebar";

const OrderDetailsScreen = ({ match }) => {
  const [loading, setLoading] = useState(() => true);
  const [e, setE] = useState(() => {});
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  let orderId = match.params.orderId;
  console.log(orderId);

  useEffect(() => {
    let config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    console.log(config);
    Axios.get(`/api/orders/order-details/${orderId}`, config)
      .then((res) => {
        console.log(res.data);
        setE(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section>
      <Container
        fluid={userInfo && userInfo?.isAdminLogin === true ? true : false}
      >
        <Row>
          <div className="col-md-12">
            <h1 className="main-heading"> </h1>
          </div>
        </Row>
        <Row>
          {userInfo && userInfo?.isAdminLogin !== true && (
            <VendorSidebar profile={true} />
          )}
          <Col
            md={12}
            lg={userInfo && userInfo?.isAdminLogin === true ? 12 : 9}
            xl={userInfo && userInfo?.isAdminLogin === true ? 12 : 9}
          >
            <div className="paymentMethod-main">
              <h4 className="heading">
                <span>Order Details</span>
              </h4>

              <div className="account-personalInfo">
                {loading ? (
                  <Loader />
                ) : (
                  <div className="order-detail box-shadow--">
                    <div className="title">
                      <Row>
                        <Col md={9} sm={12}>
                          <div className="full-size">
                            <h4>
                              {/* arriving wednesday dec-16-2020  */}
                              <span>(order # {e._id.toUpperCase()})</span>
                            </h4>
                            <p
                              style={{ color: e.isCancel ? "red" : "#90EE90" }}
                            >
                              {e.shipping_status}
                            </p>
                          </div>
                        </Col>
                        <Col md={3} sm={12}>
                          <div className="full-size text-right right-sec">
                            <h4>order placed</h4>
                            <p>
                              {e.createdAt.split("T")[0].split("-")[2] +
                                "-" +
                                e.createdAt.split("T")[0].split("-")[1] +
                                "-" +
                                e.createdAt.split("T")[0].split("-")[0]}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="order-detail-summary">
                      <Row>
                        <Col md={7}>
                          <strong>Shipping Address</strong>
                          <p>
                            {e.shippingAddress.name} <br />
                            {e.shippingAddress.address} <br />
                            {e.shippingAddress.city} <br />
                            ZIP : {e.shippingAddress.postalCode}
                          </p>
                        </Col>
                        <Col md={5}>
                          <div className="order-payment">
                            <strong>Order Summary</strong>
                            <p className="payment-column">
                              <span>Price</span>
                              <span>$ {e.price.toFixed(2)}</span>
                            </p>
                            <p className="payment-column">
                              <span>Total Price</span>
                              <span>
                                {" "}
                                $ {parseFloat(e.price * e.qty).toFixed(2)}
                              </span>
                            </p>
                            <p className="payment-column">
                              <span>Shipping</span>
                              <span> $ {e.shippingCharge.toFixed(2)}</span>
                            </p>
                            <p className="payment-column">
                              <span>Discount</span>
                              <span>
                                {" "}
                                $ {e.discount.toFixed(2)} off
                                {e.promoCode
                                  ? `off (PromoCode: ${e.promoCode.code})`
                                  : ``}
                              </span>
                            </p>
                            <p className="payment-column">
                              <span>Amount Paid</span>
                              <span>
                                {" "}
                                ${" "}
                                {(
                                  e.price * e.qty -
                                  e.discount +
                                  e.shippingCharge
                                ).toFixed(2)}{" "}
                                (incl. tax)
                              </span>
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={7}>
                          <strong>Billing Address</strong>
                          <p>
                            {e.BillingAddress.name} <br />
                            {e.BillingAddress.address} <br />
                            {e.BillingAddress.city} <br />
                            ZIP : {e.BillingAddress.postalCode}
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="order-detail-summary">
                      <Row className="align-items-center">
                        <Col md={2}>
                          <div className="image">
                            <img src={e.productImage} alt="" />
                          </div>
                        </Col>
                        <Col md={10}>
                          <h5>
                            {e.variantName}, {e.qty}-piece
                          </h5>
                          <p
                            className="payment-column"
                            style={{ marginBottom: "0" }}
                          >
                            <span>Amount Paid</span>
                            <span>
                              {" "}
                              ${" "}
                              {(
                                e.price * e.qty -
                                e.discount +
                                e.shippingCharge
                              ).toFixed(2)}{" "}
                              (incl. tax)
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </div>

                    {/* <figure className="single-item">
                    <div className="image">
                      <img src={e.productImage} alt="" />

                    </div>
                    <div className="content">
                      <h5>{e.variantName}, {e.qty}-piece</h5>

                      <p>Price $ {e.price.toFixed(2)}</p>
                      <p>Total Price $ {parseFloat(e.price * e.qty).toFixed(2)}</p>
                      <p>Shipping $ {e.shippingCharge.toFixed(2)}</p>
                      <p>Discount $ {e.discount.toFixed(2)} off{e.promoCode ? `off (PromoCode: ${e.promoCode.code})` : ``}</p>
                      <p>Amount Paid $ {((e.price * e.qty - e.discount) + (e.shippingCharge)).toFixed(2)} (incl. tax)</p>
                    </div>

                    <div className="content" style={{ paddingLeft: '0' }}>
                      <h5>Shipping Address</h5>
                      <p>{e.shippingAddress.name + ", " + e.shippingAddress.address + ", " + e.shippingAddress.city + ", ZIP:" + e.shippingAddress.postalCode}</p>

                      <h5>Shipping Provider</h5>
                            <p>{e.provider}</p>

                    </div>
                  </figure> */}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default OrderDetailsScreen;
