import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import Rating from "./Rating";
import Axios from "axios";

const Product = ({
  product,
  wishlist,
  setWishlist,
  userInfo,
  setNoti,
  isDanzfloMerch = false,
  vendor = false,
}) => {
  let history = useHistory();

  const [inWishList, setInWishlist] = useState(() =>
    product.existsInWishlist ? true : false
  );

  const addToWishlist = (id) => {
    let obj = null;

    if (userInfo) {
      if (isDanzfloMerch) {
        obj = {
          userId: userInfo._id,
          productId: id,
          fromPrintful: true,
        };
      } else {
        obj = {
          userId: userInfo._id,
          productId: id,
          fromPrintful: false,
        };
      }
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.post("/api/wishlist", obj, config)
        .then((res) => {
          setInWishlist(true);
          setTimeout(() => {
            setNoti("");
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setNoti("Please login to use wishlist feature!");
      window.scrollTo(0, 0);
      setTimeout(() => {
        setNoti("");
      }, 2000);
    }
  };

  const removeFromWishlist = (id) => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.delete(`/api/wishlist/${userInfo._id}/${id}`, config)
        .then((res) => {
          setInWishlist(false);
          setNoti("Removed from wishlist");
          setTimeout(() => {
            setNoti("");
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addToCartHandler = (id) => {
    if (isDanzfloMerch) {
      history.push(`/danzflo-merch/product/${id}`);
    } else {
      history.push(`/product/${id}`);
    }
  };

  return (
    <>
      <div className={`item product product-item box-shadow`}>
        <div className="product-item-info">
          <div className="product-top">
            <Link
              to={
                isDanzfloMerch
                  ? `/danzflo-merch/product/${product.external_id}`
                  : `/product/${
                      product.parentid == "parent"
                        ? product._id
                        : product.parentid
                    } ?isParent=${
                      product.parentid == "parent" ? true : false
                    }&id=${product._id} `
              }
              className="product photo product-item-photo"
            >
              <span className="product-image-container">
                <span
                  className="parent_lazy product-image-wrapper lazy_loaded"
                  style={{ paddingBottom: "100%" }}
                >
                  <img
                    src={
                      isDanzfloMerch
                        ? product.thumbnail_url
                        : product.thumbnailImage
                        ? product.thumbnailImage
                        : product.images[0]
                    }
                    className="img-fluid product-image-photo"
                  />
                </span>
              </span>
            </Link>
            {product.qty <= 0 && (
              <div className="soldOutImg">
                <img src="/img/sold-out.png" alt="Sold Out" />
              </div>
            )}
          </div>
          <div className="product details product-item-details">
            <strong className="product name product-item-name">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{product.name}</Tooltip>}
              >
                <Link
                  to={
                    isDanzfloMerch
                      ? `/danzflo-merch/product/${product.external_id}`
                      : `/product/${
                          product.parentid == "parent"
                            ? product._id
                            : product.parentid
                        }?isParent=${
                          product.parentid == "parent" ? true : false
                        }&id=${product._id}`
                  }
                  className="product-item-link"
                >
                  {product.name.split(" ").length <= 7
                    ? product.name
                        .split(" ")
                        .filter((val, i) => i < 8)
                        .map((e, i) => e + " ")
                    : product.name
                        .split(" ")
                        .filter((val, i) => i < 8)
                        .map((e, i) => {
                          return i != 7 ? e + " " : e + "....";
                        })}
                </Link>
              </OverlayTrigger>
            </strong>
            {!isDanzfloMerch && (
              <Row>
                <Col xs={6}>
                  <div className="price-box price-final_price">
                    {!isDanzfloMerch ? (
                      product.offerPrice ? (
                        <span className="dic">{`${Math.ceil(
                          ((product.price - product.offerPrice) /
                            product.price) *
                            100
                        )}% Off`}</span>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {/* <span className="dic">(20% off)</span> */}

                    {!isDanzfloMerch ? (
                      product.offerPrice ? (
                        <>
                          <span className="special-price">
                            <span className="price-container price-final_price tax weee">
                              <span className="price-label">Special Price</span>
                              <span className="price-wrapper ">
                                <span className="price">
                                  ${product.offerPrice}
                                </span>
                              </span>
                            </span>
                          </span>
                          <span className="old-price">
                            <span className="price-container price-final_price tax weee">
                              <span className="price-label">Regular Price</span>
                              <span className="price-wrapper">
                                <span className="price">${product.price}</span>
                              </span>
                            </span>
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="special-price">
                            <span className="price-container price-final_price tax weee">
                              <span className="price-label">Price</span>
                              <span className="price-wrapper ">
                                <span className="price">${product.price}</span>
                              </span>
                            </span>
                          </span>
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
                {!isDanzfloMerch ? (
                  <Col xs={6}>
                    <div className="rating-reviews">
                      <div className="star">
                        <Rating
                          value={product.rating}
                          text={`${product.numReviews} reviews`}
                        />
                      </div>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            )}
            <div className="product-item-inner">
              <div className="product actions product-item-actions">
                <div className="actions-primary">
                  <form>
                    <button
                      disabled={product.qty <= 0 ? true : false}
                      style={{
                        cursor: product.qty <= 0 ? "no-drop" : "pointer",
                      }}
                      type="submit"
                      title="Add to Cart"
                      onClick={() => {
                        addToCartHandler(
                          isDanzfloMerch ? product.external_id : product._id
                        );
                      }}
                      className="action tocart primary"
                    >
                      <span>Add to Cart</span>
                    </button>
                  </form>
                </div>
                {inWishList ? (
                  <div className="actions-secondary">
                    <a
                      className="action towishlist"
                      onClick={() => {
                        removeFromWishlist(
                          isDanzfloMerch ? product.external_id : product._id
                        );
                      }}
                      title="Already in Wishlist"
                    >
                      <span>
                        <i className="fas fa-heart"></i> Already in Wishlist
                      </span>
                    </a>
                  </div>
                ) : (
                  <div className="actions-secondary">
                    <a
                      className="action towishlist"
                      onClick={() => {
                        addToWishlist(
                          isDanzfloMerch ? product.external_id : product._id
                        );
                      }}
                      title="Add to Wish List"
                    >
                      <span>
                        <i className="far fa-heart"></i> Add to Wish List
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
