import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Toast,
  Breadcrumb,
  Form,
  Accordion,
  Card,
  Button,
} from "react-bootstrap";
import Product from "../components/Product";
import Message from "../components/Message";

import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import ProductCarousel from "../components/ProductCarousel";
import ProductFilter from "../components/ProductFilter";
import Meta from "../components/Meta";
import { listCategoryProducts } from "../actions/productActions";
import { Empty } from "antd";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Axios from "axios";
import VendorSidebar from './VendorSidebar'

const CategoryScreen = ({ match }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.id]);

  let history = useHistory();

  const c = match.params.id;
  const slug = match.params.name;

  if (!c) {
    history.push("/");
  }

  const discount = {
    name: "Discount",
    values: [10, 20, 30, 40, 50, 60, 70],
  };

  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const [load, setLoad] = useState(() => false);
  const [filters, setFilters] = useState(() => []);

  const [variants, setVariants] = useState(() => []);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(listCategoryProducts(c, pageNumber));
    Axios.get(`/api/specifications/get-filters-by-category/${c}`)
      .then((res) => {
        setFilters(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get(`/api/variants/all-variants/${c}`)
      .then((res) => {
        setVariants(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, c, slug, pageNumber]);

  const [filterType, setFilterType] = useState(() => "latest");
  const [category, setCategory] = useState(() => undefined);

  const [wishlist, setWishlist] = useState(() => []);

  const [displayProduct, setDisplayProduct] = useState(() => []);
  const [noti, setNoti] = useState(() => "");

  const [value, setValue] = useState(() => {
    return { min: 1, max: 5000 };
  });

  const [appliedFilters, setAppliedFilters] = useState(() => []);
  const [appliedVariants, setAppliedVariants] = useState(() => []);
  const [rating, setRating] = useState(() => 0);
  const [discount1, setDiscount] = useState(() => 0);

  const [hasFilter, setHasFilter] = useState(() => false);

  const applyFilter = (name, value) => {
    let data = [...appliedFilters];
    setHasFilter(true);

    if (data.length == 0) {
      let obj = {
        name: name,
        value: [value],
      };
      data = [...data, obj];
    } else {
      let matchName = 0;
      let i = 0;
      for (let k = 0; k < data.length; ++k) {
        if (data[k].name.replaceAll(" ", "") == name.replaceAll(" ", "")) {
          matchName = 1;
          i = k;
          break;
        }
      }
      if (matchName == 1) {
        let match = 0;
        for (let j = 0; j < data[i].value.length; ++j) {
          if (
            data[i].value[j]
              .replaceAll(" ", "")
              .includes(value.replaceAll(" ", ""))
          ) {
            match = 1;
          }
        }
        if (match == 1) {
          let buffer = data[i].value.filter(
            (e) => e.replaceAll(" ", "") != value.replaceAll(" ", "")
          );
          data[i].value = buffer;
          if (buffer.length == 0) {
            let data1 = data.filter(
              (val) => val.name.replaceAll(" ", "") != name.replaceAll(" ", "")
            );
            data = [...data1];
          }
        } else {
          data[i].value = [...data[i].value, value];
        }
      } else {
        let obj = {
          name: name,
          value: [value],
        };
        data = [...data, { name: obj.name, value: [...obj.value] }];
      }
    }
    setAppliedFilters(data);
  };

  const applyVariantFilter = (name, value) => {
    let data = [...appliedVariants];
    setHasFilter(true);
    if (data.length == 0) {
      let obj = { name: name, value: [value] };

      data = [...data, obj];
    } else {
      let matchName = 0;
      let k = 0;
      for (let i = 0; i < data.length; ++i) {
        if (data[i].name.replaceAll(" ", "") == name.replaceAll(" ", "")) {
          matchName = 1;
          k = i;
          break;
        }
      }
      if (matchName == 1) {
        let match = 0;
        for (let i = 0; i < data[k].value.length; ++i) {
          if (
            data[k].value[i].replaceAll(" ", "") == value.replaceAll(" ", "")
          ) {
            match = 1;
            break;
          }
        }
        if (match == 1) {
          let buffer = data[k].value.filter(
            (e) => e.replaceAll(" ", "") != value.replaceAll(" ", "")
          );
          if (buffer.length == 0) {
            let data1 = data.filter(
              (e) => e.name.replaceAll(" ", "") != name.replaceAll(" ", "")
            );
            data = [...data1];
          } else {
            data[k].value = buffer;
          }
        } else {
          data[k].value = [...data[k].value, value];
        }
      } else {
        let obj = { name: name, value: [value] };
        data = [...data, obj];
      }
    }
    console.log(data);
    setAppliedVariants(data);
  };

  useEffect(() => {
    if (filterType == "latest") {
      let data = [];
      setDisplayProduct(products);
    } else if (filterType == "featured") {
      let data = [];
      data = products.filter((e) => e.featured == true);
      setDisplayProduct(data);
    } else if (filterType == "popular") {
      let data = [];
      data = products.filter((e) => e.featured == true);
      setDisplayProduct(data);
    } else if (filterType == "special") {
      let data = [];
      data = products.filter(
        (e) =>
          ((e.price - e.offerPrice) / e.price) * 100 >= 50 && e.offerPrice > 0
      );
      setDisplayProduct(data);
    }
  }, [productList, filterType]);

  useEffect(() => {
    let data = products;
    data = data.filter(
      (e) => e.category == category || e.subcategory == category
    );
    setDisplayProduct(data);
  }, [category]);

  useEffect(() => {
    setLoad(true);
    Axios.post(
      `/api/products/category/${c}?pageNumber=${pageNumber}&minPrice=${value.min}&maxPrice=${value.max}&rating=${rating}&discount=${discount1}`,
      { filters: appliedFilters, variants: appliedVariants }
    )
      .then((res) => {
        console.log(res.data);
        setDisplayProduct(res.data.products);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  }, [appliedFilters, appliedVariants, rating, discount1]);

  const sliderFilter = () => {
    setLoad(true);
    setHasFilter(true);

    Axios.post(
      `/api/products/category/${c}?pageNumber=${pageNumber}&minPrice=${value.min}&maxPrice=${value.max}&rating=${rating}&discount=${discount1}`,
      { filters: appliedFilters }
    )
      .then((res) => {
        setDisplayProduct(res.data.products);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
  //Can be latest----featured----popular----spacialdeals

  const goToHome = () => {
    history.push("/");
  };

  const resetAllFilters = (e) => {
    e.preventDefault();
    setDiscount(0);
    setRating(0);
    setAppliedFilters([]);
    setAppliedVariants([]);
    setValue({ min: 1, max: 5000 });
    setHasFilter(false);
  };

  return (
    <>
      <Meta />
      {!c ? (
        <ProductCarousel />
      ) : (
        <Breadcrumb>
          <Breadcrumb.Item
            onClick={() => {
              goToHome();
            }}
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item>Category</Breadcrumb.Item>
          <Breadcrumb.Item>
            {slug.split("-").map((e) => e + " ")}
          </Breadcrumb.Item>
        </Breadcrumb>
      )}

      <section className="accountMain-wraper">
        <Container>
          <div className="products-listing--">
            {/* <ProductFilter category={category} setCategory={setCategory} filterType={filterType} setFilterType={setFilterType} /> */}
            {/* <h1>Latest Products</h1> */}
            {error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <>
                {noti ? <Message>{noti}</Message> : <></>}

                <Row>
                  <div className="col-md-12 col-lg-4 col-xl-3">
                    <VendorSidebar category={true}/>

                    <div
                      className="account-sidebarLeft"
                      style={{ marginTop: "2%" }}
                    >
                      <div className="sidebar-accountMenu">
                        <div className="media">
                          <div className="mr-3 img profileImg">
                            <svg
                              width="20"
                              height="22"
                              viewBox="0 0 20 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4 1L1 5V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V5L16 1H4Z"
                                stroke="#2AA8F2"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M1 5H19"
                                stroke="#2AA8F2"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M14 9C14 10.0609 13.5786 11.0783 12.8284 11.8284C12.0783 12.5786 11.0609 13 10 13C8.93913 13 7.92172 12.5786 7.17157 11.8284C6.42143 11.0783 6 10.0609 6 9"
                                stroke="#2AA8F2"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                          <div className="media-body">
                            <h5 className="mt-0">
                              <span>Filters</span>
                              {hasFilter ? (
                                <Button
                                  variant={"secondary"}
                                  onClick={resetAllFilters}
                                >
                                  Clear
                                </Button>
                              ) : (
                                <></>
                              )}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div className="sidebar-accountMenu">
                        <ul style={{ height: "100%" }}>
                          <div className="filter-options">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Toggle eventKey="0">
                                Price Filter
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0">
                                <ul>
                                  <li key={""}>
                                    <InputRange
                                      maxValue={5000}
                                      minValue={0}
                                      value={value}
                                      onChangeComplete={sliderFilter}
                                      onChange={(value) => setValue(value)}
                                    />
                                  </li>
                                </ul>
                              </Accordion.Collapse>
                            </Accordion>
                          </div>

                          {filters.length != 0 ? (
                            filters.map((value, i) => {
                              return (
                                <>
                                  <div className="filter-options" key={i}>
                                    <Accordion defaultActiveKey="0">
                                      <Accordion.Toggle
                                        eventKey={i == 0 ? `1` : "1"}
                                      >
                                        {value.name}
                                      </Accordion.Toggle>
                                      <Accordion.Collapse
                                        eventKey={i == 0 ? `1` : "1"}
                                      >
                                        <ul>
                                          {value.specifications.map((e) => (
                                            <li>
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicCheckbox"
                                              >
                                                <Form.Check
                                                  type="checkbox"
                                                  checked={
                                                    appliedFilters.filter(
                                                      (val) =>
                                                        val.name.replaceAll(
                                                          " ",
                                                          ""
                                                        ) ==
                                                        value.name.replaceAll(
                                                          " ",
                                                          ""
                                                        )
                                                    ).length != 0
                                                      ? appliedFilters
                                                          .filter(
                                                            (val) =>
                                                              val.name.replaceAll(
                                                                " ",
                                                                ""
                                                              ) ==
                                                              value.name.replaceAll(
                                                                " ",
                                                                ""
                                                              )
                                                          )[0]
                                                          .value.filter(
                                                            (val1) =>
                                                              val1.replaceAll(
                                                                " ",
                                                                ""
                                                              ) ==
                                                              e.name.replaceAll(
                                                                " ",
                                                                ""
                                                              )
                                                          ).length != 0
                                                        ? true
                                                        : false
                                                      : false
                                                  }
                                                  label={e.name}
                                                  onClick={() => {
                                                    applyFilter(
                                                      value.name,
                                                      e.name
                                                    );
                                                  }}
                                                />
                                              </Form.Group>
                                            </li>
                                          ))}
                                        </ul>
                                      </Accordion.Collapse>
                                    </Accordion>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}

                          {variants.length != 0 ? (
                            variants.map((value, i) => {
                              return (
                                <>
                                  <div className="filter-options" key={i}>
                                    <Accordion defaultActiveKey="0">
                                      <Accordion.Toggle eventKey={"1"}>
                                        {value.variantsname}
                                      </Accordion.Toggle>
                                      <Accordion.Collapse eventKey={"1"}>
                                        <ul>
                                          {value.options.map((e) => (
                                            <li>
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicCheckbox"
                                              >
                                                <Form.Check
                                                  type="checkbox"
                                                  checked={
                                                    appliedVariants.filter(
                                                      (val) =>
                                                        val.name
                                                          .split(".")[1]
                                                          .replaceAll(
                                                            " ",
                                                            ""
                                                          ) ==
                                                        value.variantsname.replaceAll(
                                                          " ",
                                                          ""
                                                        )
                                                    ).length != 0
                                                      ? appliedVariants
                                                          .filter(
                                                            (val) =>
                                                              val.name
                                                                .split(".")[1]
                                                                .replaceAll(
                                                                  " ",
                                                                  ""
                                                                ) ==
                                                              value.variantsname.replaceAll(
                                                                " ",
                                                                ""
                                                              )
                                                          )[0]
                                                          .value.filter(
                                                            (val1) =>
                                                              val1.replaceAll(
                                                                " ",
                                                                ""
                                                              ) ==
                                                              e.value.replaceAll(
                                                                " ",
                                                                ""
                                                              )
                                                          ).length != 0
                                                        ? true
                                                        : false
                                                      : false
                                                  }
                                                  label={e.value}
                                                  onClick={() => {
                                                    applyVariantFilter(
                                                      value.variantsname.toLowerCase() ==
                                                        "color" ||
                                                        value.variantsname.toLowerCase() ==
                                                          "colour"
                                                        ? "variant." +
                                                            value.variantsname
                                                        : "availableVariantOption." +
                                                            value.variantsname,
                                                      e.value
                                                    );
                                                  }}
                                                />
                                              </Form.Group>
                                            </li>
                                          ))}
                                        </ul>
                                      </Accordion.Collapse>
                                    </Accordion>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}

                          <div className="filter-options">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Toggle eventKey={"1"}>
                                {`Ratings`}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={"1"}>
                                <ul>
                                  <li className="rating-filter">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicCheckbox"
                                    >
                                      <Form.Check
                                        type="radio"
                                        name={"ratings"}
                                        checked={rating == 4}
                                        onClick={() => {
                                          if (rating == 4) {
                                            setRating(0);
                                          } else {
                                            setHasFilter(true);
                                            setRating(4);
                                          }
                                        }}
                                      />
                                      <span>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i class="far fa-star"></i> & Up
                                      </span>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicCheckbox"
                                    >
                                      <Form.Check
                                        type="radio"
                                        name={"ratings"}
                                        checked={rating == 3}
                                        onClick={() => {
                                          if (rating == 3) {
                                            setRating(0);
                                          } else {
                                            setHasFilter(true);
                                            setRating(3);
                                          }
                                        }}
                                      />
                                      <span>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i> & Up
                                      </span>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicCheckbox"
                                    >
                                      <Form.Check
                                        type="radio"
                                        name={"ratings"}
                                        checked={rating == 2}
                                        onClick={() => {
                                          if (rating == 2) {
                                            setRating(0);
                                          } else {
                                            setHasFilter(true);
                                            setRating(2);
                                          }
                                        }}
                                      />
                                      <span>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i> & Up
                                      </span>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicCheckbox"
                                    >
                                      <Form.Check
                                        type="radio"
                                        name={"ratings"}
                                        checked={rating == 1}
                                        onClick={() => {
                                          if (rating == 1) {
                                            setRating(0);
                                          } else {
                                            setHasFilter(true);
                                            setRating(1);
                                          }
                                        }}
                                      />
                                      <span>
                                        <i className="fa fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i>
                                        <i class="far fa-star"></i> & Up
                                      </span>
                                    </Form.Group>
                                  </li>
                                </ul>
                              </Accordion.Collapse>
                            </Accordion>
                          </div>

                          <div className="filter-options">
                            <Accordion defaultActiveKey="0">
                              <Accordion.Toggle eventKey={"1"}>
                                {discount.name}
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={"1"}>
                                <ul>
                                  {discount.values.map((e) => (
                                    <li>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicCheckbox"
                                      >
                                        <Form.Check
                                          type="radio"
                                          checked={
                                            discount1 == e ? true : false
                                          }
                                          name={`discount`}
                                          onClick={() => {
                                            if (e == discount1) {
                                              setDiscount(0);
                                            } else {
                                              setHasFilter(true);
                                              setDiscount(e);
                                            }
                                          }}
                                          label={`${e}% off or more`}
                                        />
                                      </Form.Group>
                                    </li>
                                  ))}
                                </ul>
                              </Accordion.Collapse>
                            </Accordion>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-8 col-xl-9">
                    <div className="merch-product-listing">
                      <div className="mgs-productsWraper">
                        <div className="products-listing">
                          <ProductFilter
                            category={category}
                            setCategory={setCategory}
                            filterType={filterType}
                            setFilterType={setFilterType}
                          />

                          <div className="products products-grid mgs-products">
                            <div className="mgs-productsWraper">
                              <Row>
                                {loading || load ? (
                                  <Loader />
                                ) : displayProduct.length !== 0 ? (
                                  displayProduct.map((product) => (
                                    <Col
                                      key={product._id}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={4}
                                    >
                                      <Product
                                        setNoti={setNoti}
                                        wishlist={wishlist}
                                        setWishlist={setWishlist}
                                        userInfo={userInfo}
                                        product={product}
                                      />
                                    </Col>
                                  ))
                                ) : (
                                  <div style={{ margin: "auto" }}>
                                    <Empty
                                      image="/assets/img/logo.png"
                                      description={
                                        <span style={{ fontWeight: "bold" }}>
                                          No Product(s) to display
                                        </span>
                                      }
                                    ></Empty>
                                  </div>
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>

                <Paginate
                  pages={pages}
                  page={page}
                  keyword={slug ? slug : ""}
                  url={`/category/${match.params.name}/${match.params.id}`}
                />
              </>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default CategoryScreen;
