import React, { useEffect, useState, Fragment } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";

import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import { listCategories } from "../actions/categoryActions";
import { CATEGORY_CREATE_RESET } from "../constants/categoryConstants";

import SideBar from "../components/Sidebar";

import { Table, Space, Popconfirm } from "antd";
import "antd/dist/antd.css";
import Axios from "axios";
import { TreeSelect } from "antd";
// import VendorSidebar from "./VendorSidebar";

const Specifications = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;

  const { SHOW_ALL } = TreeSelect;

  const dispatch = useDispatch();

  const categoryList = useSelector((state) => state.categoryList);

  const { loading, error, page, pages } = categoryList;

  const productDelete = useSelector((state) => state.productDelete);
  const { loading: loadingDelete, success: successDelete } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: CATEGORY_CREATE_RESET });

    if (!userInfo || !userInfo.userType == "admin") {
      history.push("/login");
    }

    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listCategories("", pageNumber));
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ]);

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    Axios.get(`/api/category/getall`, config)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    Axios.get("/api/specifications", config)
      .then((res) => {
        console.log(res.data);
        setTableData(res.data);
        setLoading1(false);
      })
      .catch((err) => {
        setLoading1(false);
        console.log(err);
      });
  }, []);

  const [modalVisibility, setModalVisibility] = useState(() => false);

  const [msg, setMsg] = useState(() => "");
  const [isUpdate, setIsUpdate] = useState(() => false);
  const [loading1, setLoading1] = useState(() => true);
  const [tableData, setTableData] = useState(() => []);

  const [categories, setCategories] = useState(() => []);
  const [selectedCategories, setSelectedCategories] = useState(() => []);

  const [name, setName] = useState(() => "");
  const [id, setId] = useState(() => "");

  const [values, setValues] = useState(() => [
    {
      name: "",
    },
  ]);
  const [showInFilter, setShowInFilter] = useState(() => false);

  const [success, setSuccess] = useState(() => false);
  const [successMsg, setSuccessMsg] = useState(() => "");

  const [err, setErr] = useState(() => false);
  const [errorMsg, setErrorMsg] = useState(() => "");

  //Table Data
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      render: (text, e) => {
        return e.fromAdmin ? "Default" : "Custom";
      },
    },
    {
      title: "Specifications",
      dataIndex: "specifications",
      key: "specifications",
      render: (text, e) => {
        return e.specifications.map((val, i) =>
          e.specifications.length - 1 == i ? val.name + "" : val.name + ", "
        );
      },
    },
    {
      title: "Show In Filter",
      dataIndex: "showInFilter",
      key: "showInFilter",
      hidden: userInfo.userType == "admin" ? false : true,
      render: (text, e) =>
        e.showInFilter ? (
          <span style={{ color: "green" }}>
            <i className="fas fa-check"></i>
          </span>
        ) : (
          <span style={{ color: "red" }}>
            <i className="fas fa-times"></i>
          </span>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "16%",
      fixed: "right",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteHandler(record._id)}
          >
            <Button
              variant="secondary"
              disabled={
                userInfo.userType == "admin"
                  ? false
                  : record.fromAdmin
                    ? true
                    : false
              }
              style={{ color: "red" }}
            >
              <i className="fas fa-trash-alt"></i>
            </Button>
          </Popconfirm>
          <Button
            variant="secondary"
            disabled={
              userInfo.userType == "admin"
                ? false
                : record.fromAdmin
                  ? true
                  : false
            }
            style={{ color: "#2aa8f2" }}
            onClick={() => {
              editHandler(record._id);
            }}
          >
            <i className="fas fa-edit"></i>
          </Button>
        </Space>
      ),
    },
  ].filter((item) => !item.hidden);

  const editHandler = (id) => {
    setId(id);

    for (let i = 0; i < tableData.length; ++i) {
      if (tableData[i]._id == id) {
        setName(tableData[i].name);
        setValues(tableData[i].specifications);
        setSelectedCategories(tableData[i].categories);
        setIsUpdate(true);
        setShowInFilter(tableData[i].showInFilter);
        setModalVisibility(true);
        break;
      }
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    let emp = false;

    if (name.trim() == "") {
      setMsg("Enter Variant Name");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }
    for (let i = 0; i < values.length; ++i) {
      if (values[i].name.trim() === "") {
        setMsg("Attribute Cannot be empty");
        setTimeout(() => {
          setMsg("");
        }, 3000);
        emp = true;
        return;
      }
    }
    if (selectedCategories.length == 0) {
      setMsg("Select Categories");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }

    if (emp === false) {
      let data = {
        name: name,
        userId: userInfo._id,
        fromAdmin: userInfo.userType == "admin" ? true : false,
        specifications: values,
        showInFilter: showInFilter,
        categories: selectedCategories,
      };

      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      Axios.post("/api/specifications/add", data, config)
        .then((res) => {
          let tdata = {
            _id: res.data._id,
            name: name,
            userId: userInfo._id,
            fromAdmin: userInfo.userType == "admin" ? true : false,
            specifications: values,
            showInFilter: showInFilter,
            categories: selectedCategories,
          };
          setTableData([...tableData, tdata]);

          setMsg("");
          setName("");
          setValues([
            {
              name: "",
            },
          ]);
          setSelectedCategories([]);
          setModalVisibility(false);
          setSuccessMsg("Variant Added!!");
          setSuccess(true);
          setTimeout(hideMessage, 3000);
        })
        .catch((err) => {
          setMsg("Variant already exists");
        });
    }
  };

  const updateSpecifications = () => {
    let data = [...tableData];

    let empty = false;

    if (name.trim() == "") {
      setMsg("Enter variant name");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }

    for (let i = 0; i < values.length; ++i) {
      if (values[i].name.trim() == "") {
        setMsg("Attributes Cannot be empty");
        empty = true;
        setTimeout(() => {
          setMsg("");
        }, 3000);
        return;
      }
    }
    if (selectedCategories.length == 0) {
      setMsg("Select Categories");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }

    if (empty == false) {
      for (let i = 0; i < data.length; ++i) {
        if (data[i]._id == id) {
          data[i].variantsname = name;
          data[i].specifications = values;
          data[i].showInFilter = showInFilter;
          data[i].categories = selectedCategories;
        }
      }

      let passData = {
        id: id,
        name: name,
        specifications: values,
        showInFilter: showInFilter,
        categories: selectedCategories,
      };

      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      Axios.put("/api/specifications/update", passData, config)
        .then((res) => {
          let d1 = [...tableData];
          for (let i = 0; i < d1.length; ++i) {
            if (d1._id == id) {
              d1.specifications = values;
              d1.name = name;
              d1.showInFilter = showInFilter;
              d1.categories = selectedCategories;
            }
          }
          setTableData(d1);
          setMsg("");
          setName("");
          setValues([
            {
              value: "",
              name: "",
            },
          ]);
          setShowInFilter(false);
          setSelectedCategories([]);
          setModalVisibility(false);
          setSuccessMsg("Updated!!");
          setSuccess(true);
          setTimeout(hideMessage, 3000);
        })
        .catch((err) => {
          setMsg("Try again later");
        });
    }
  };

  const addCategoryCancel = () => {
    setMsg("");
    setName("");
    setValues([
      {
        value: "",
      },
    ]);
    setShowInFilter(false);
    setSelectedCategories([]);
    setModalVisibility(false);
    setIsUpdate(false);
  };

  const handleValueInput = (value, i) => {
    let val = [...values];
    val[i].name = value;
    val[i].value = "";
    setValues(val);
  };

  const addFeild = () => {
    let val = [...values];
    val.push({
      name: "",
    });

    setValues(val);
  };

  const hideMessage = () => {
    setErr(false);
    setSuccess(false);
    setErrorMsg("");
    setSuccessMsg("");
  };

  const removeField = (index) => {
    let val = [];
    for (let i = 0; i < values.length; ++i) {
      if (i != index) {
        val.push(values[i]);
      }
    }
    setValues(val);
  };

  const deleteHandler = (id) => {
    let data = [];

    let config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    setLoading1(true);

    Axios.delete(`/api/specifications/${id}`, config)
      .then((res) => {
        for (let i = 0; i < tableData.length; ++i) {
          if (tableData[i]._id != id) {
            data.push(tableData[i]);
          }
        }
        setLoading1(false);
        setTableData(data);
        setModalVisibility(false);
        setSuccessMsg("Variant Deleted!!");
        setSuccess(true);
        setTimeout(hideMessage, 3000);
      })
      .catch((err) => {
        setLoading1(false);
        setMsg("Try again later!");
      });
  };

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading"> </h1>
            </div>
          </div>
          <div className="row">
            {/* <VendorSidebar profile={true} /> */}
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="paymentMethod-main">
                <Row className="align-items-center">
                  <Col>
                    <h4 className="heading">Product Specifications</h4>
                  </Col>
                  <Col className="text-right">
                    <Button
                      style={{ marginRight: "1%" }}
                      variant="secondary"
                      className="my-3"
                      onClick={() => {
                        setModalVisibility(true);
                      }}
                    >
                      <i className="fas fa-plus"></i> Add Specifications
                    </Button>
                  </Col>
                </Row>
                {loadingDelete && <Loader />}
                {loadingCreate && <Loader />}
                {errorCreate && (
                  <Message variant="danger">{errorCreate}</Message>
                )}
                {err && <Message variant="danger">{errorMsg}</Message>}
                {success && <Message>{successMsg}</Message>}
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <>
                    <Table
                      loading={loading1}
                      columns={columns}
                      dataSource={tableData}
                      rowKey={"_id"}
                    />
                    <Modal show={modalVisibility} onHide={addCategoryCancel}>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {isUpdate
                            ? "Update Properties Specifications"
                            : "Add Properties Specifications"}
                        </Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        {msg && <Message variant="danger">{msg}</Message>}
                        <Form style={{ marginTop: "5%" }}>
                          <Form.Group controlId="name">
                            <Form.Label>Specification</Form.Label>
                            <Form.Control
                              type="name"
                              placeholder={"Name"}
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>

                          <Form.Group controlId="name">
                            <Form.Label>Show in filter</Form.Label>
                            <Form.Check
                              type="checkbox"
                              checked={showInFilter}
                              onChange={(e) => {
                                setShowInFilter(e.target.checked);
                              }}
                            />
                          </Form.Group>

                          <Form.Group controlId="slug">
                            <Form.Label>Specifications Attributes</Form.Label>
                            {values.map((e, i) => (
                              <Fragment key={`${e}~${i}`}>
                                {i == 0 ? (
                                  <>
                                    <div style={{ display: "flex" }}>
                                      <Form.Control
                                        type="name"
                                        style={{ width: "90%" }}
                                        placeholder={"Attribute"}
                                        value={e.name}
                                        onChange={(val) => {
                                          handleValueInput(val.target.value, i);
                                        }}
                                      ></Form.Control>
                                      <Button
                                        variant={"secondary"}
                                        onClick={addFeild}
                                      >
                                        <i className="fas fa-plus"></i>
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: "2%",
                                      }}
                                    >
                                      <Form.Control
                                        type="name"
                                        style={{ width: "90%" }}
                                        placeholder={"Attribute"}
                                        value={e.name}
                                        onChange={(val) => {
                                          handleValueInput(val.target.value, i);
                                        }}
                                      ></Form.Control>
                                      <Button
                                        variant={"secondary"}
                                        onClick={() => {
                                          removeField(i);
                                        }}
                                      >
                                        <i className="fas fa-minus"></i>
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </Fragment>
                            ))}
                          </Form.Group>

                          <Form.Group controlId="name">
                            <Form.Label>Categories</Form.Label>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">{`Categories that the variant is for`}</Tooltip>
                              }
                            >
                              <Button
                                style={{ background: "none", color: "#2aa8f2" }}
                              >
                                <i className="far fa-question-circle"></i>
                              </Button>
                            </OverlayTrigger>
                            <TreeSelect
                              treeData={categories}
                              value={selectedCategories}
                              onChange={(e) => {
                                setSelectedCategories(e);
                              }}
                              treeCheckable={true}
                              showCheckedStrategy={SHOW_ALL}
                              placeholder={"Select Categories"}
                              style={{
                                width: "100%",
                              }}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button variant="secondary" onClick={addCategoryCancel}>
                          Close
                        </Button>

                        <Button
                          variant="primary"
                          onClick={isUpdate ? updateSpecifications : handleSave}
                        >
                          {isUpdate
                            ? "Update Specifications"
                            : "Add Specifications"}
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Paginate pages={pages} page={page} isAdmin={true} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Specifications;
