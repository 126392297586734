import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import VendorDrawerForm from "./../components/vendorDrowerForm";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";

import Loader from "../components/Loader";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Sidebar from "../components/Sidebar";
import UserAddresses from "../components/UserAddresses";
import Axios from "axios";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import OtherUserDetails from "./OtherUserDetails";
import VendorSidebar from "./VendorSidebar";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const ProfileScreen = ({ location, history }) => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [successUpd, setSuccessUpd] = useState("");
  const [success1, setSuccess] = useState(() => false);
  const [error1, setError] = useState(() => false);
  const [copied, setCopied] = useState(() => false);
  const [selectedGender, setSelectedGender] = useState("");
  //category

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;
  useEffect(() => {
    console.log("Here");
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
      } else {
        setName(user.name);
        setEmail(user.email);
        setLastName(user.lastName);
        setDob(user.dob);
        setSelectedGender(user.gender);
      }
    }
  }, [dispatch, history, userInfo, user, success]);
  const submitHandler = (e) => {
    e.preventDefault();
    let d = new Date();
    if (!dob) {
      setMessage("Enter D.O.B");
      setTimeout(() => {
        setMessage("");
      }, 2000);
      return;
    }
    if (dob) {
      let dob1 = dob.split("-");
      if (dob1[0] > d.getFullYear()) {
        setMessage("D.O.B cannot be greater than today");
        setTimeout(() => {
          setMessage("");
        }, 2000);
        return;
      }
      if (dob1[0] >= d.getFullYear() && dob1[1] > parseInt(d.getMonth()) + 1) {
        setMessage("D.O.B cannot be greater than today");
        setTimeout(() => {
          setMessage("");
        }, 2000);
        return;
      }
      if (
        dob1[0] >= d.getFullYear() &&
        dob1[1] >= d.getMonth() + 1 &&
        dob1[2] > d.getDate()
      ) {
        setMessage("D.O.B cannot be greater than today");
        setTimeout(() => {
          setMessage("");
        }, 2000);
        return;
      }
    }
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          email,
          password,
          lastName,
          dob,
          gender: selectedGender,
        })
      );
    }
  };

  const AddUpdHandler = (status) => {
    if (status) {
      setSuccessUpd(status);
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(getUserDetails("profile"));
    }
  };

  const resendVerification = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      let response = await Axios.post(
        `api/users/resend-verification`,
        {},
        config
      );
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const storeLinkHandler = (e) => {
    e.preventDefault();

    history.push(`/${userInfo.name}/store/${userInfo._id}`);
  };

  const classes = useStyles();

  const handleLinkClick = (link, platform, userName) => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.post(`/api/users/tv-login`, {}, config)
        .then((res) => {
          if (platform == "tv") {
            if (res.data.isLoggedIn) {
              window.location.href = `${link}/index.php?link1=external_auth&authId=${res.data.authKey}&redirect=${userName}`;
            } else {
              window.location.href = `${link}/index.php?link1=external_auth`;
            }
          } else {
            window.location.href = `${link}`;
          }
        })
        .catch((err) => {});
      // window.location.href = `${link}/index.php?link1=external_auth&authId=${userInfo.otherToken}`;
      //  history.push(`${link}/index.php?link1=external_auth&authId=${userInfo.otherToken}`);
    } else {
      window.location.href = `${link}/index.php?link1=external_auth`;
    }
  };

  return (
    <section className="accountMain-wraper">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="main-heading"> </h1>
          </Col>
        </Row>
        <Row>
          <VendorSidebar handleLinkClick={handleLinkClick} profile={true} />
          <Col md={12} lg={9} xl={9}>
            <div className="paymentMethod-main">
              <h4 className="heading">
                <span>My Profile </span>
                <span style={{ float: "right" }}>
                  <CopyToClipboard
                    text={
                      userInfo
                        ? `https://danzflo.com/${userInfo.name}/store/${userInfo._id}`
                        : ``
                    }
                    onCopy={() => {
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 4000);
                    }}
                  >
                    <Button
                      variant="default"
                      style={{ fontSize: "125%", color: "#40a9ff" }}
                      title="Profile Link"
                    >
                      <i className="fas fa-copy"></i>
                    </Button>
                  </CopyToClipboard>
                  <Button
                    onClick={storeLinkHandler}
                    variant="default"
                    style={{ fontSize: "125%", color: "#40a9ff" }}
                    title="My Store"
                  >
                    <i className="fas fa-store"></i>
                  </Button>
                  <span style={{ marginLeft: "2px" }}>
                    <FacebookShareButton
                      url={
                        userInfo
                          ? `https://danzflo.com/vendor/all/${userInfo._id}`
                          : ""
                      }
                    >
                      <FacebookIcon size={25} round />
                    </FacebookShareButton>
                  </span>

                  <span style={{ marginLeft: "2px" }}>
                    <FacebookMessengerShareButton
                      url={
                        userInfo
                          ? `https://danzflo.com/vendor/all/${userInfo._id}`
                          : ""
                      }
                    >
                      <FacebookMessengerIcon size={25} round />
                    </FacebookMessengerShareButton>
                  </span>

                  <span style={{ marginLeft: "2px" }}>
                    <TwitterShareButton
                      url={
                        userInfo
                          ? `https://danzflo.com/vendor/all/${userInfo._id}`
                          : ""
                      }
                    >
                      <TwitterIcon size={25} round />
                    </TwitterShareButton>
                  </span>

                  <span style={{ marginLeft: "2px" }}>
                    <WhatsappShareButton
                      url={
                        userInfo
                          ? `https://danzflo.com/vendor/all/${userInfo._id}`
                          : ""
                      }
                    >
                      <WhatsappIcon size={25} round />
                    </WhatsappShareButton>
                  </span>
                </span>
              </h4>

              {userInfo && userInfo.isVerified ? (
                <></>
              ) : (
                <>
                  <Alert variant={"danger"}>
                    Please verify your email.
                    <Button
                      onClick={resendVerification}
                      style={{ background: "none", color: "blue" }}
                    >
                      Resend Verification
                    </Button>
                  </Alert>
                </>
              )}
              {success1 && <Alert variant="success">Verification sent</Alert>}
              {copied && <Alert variant="success">Copied to clipboard</Alert>}
              {error1 && (
                <Alert variant="danger">Cannot send verification</Alert>
              )}
              <div className="account-personalInfo">
                {message && <Message variant="danger">{message}</Message>}
                {}
                {success && (
                  <Message variant="success">Profile Updated</Message>
                )}
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <Form onSubmit={submitHandler}>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="name">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="name"
                            placeholder="First name"
                            value={name}
                            onChange={(e) => {
                              let reg = /[0-9]/;
                              if (reg.test(e.target.value)) {
                                if (!e.target.value) {
                                  setName(e.target.value);
                                }
                              } else {
                                setName(e.target.value);
                              }
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => {
                              let reg = /[0-9]/;
                              // let exp = new RegExp(e.target.value);
                              if (reg.test(e.target.value)) {
                                if (!e.target.value) {
                                  setLastName(e.target.value);
                                }
                              } else {
                                setLastName(e.target.value);
                              }
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="email">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <TextField
                          id="date"
                          label="D.O.B"
                          type="date"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          className={classes.textField}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="password">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="confirmPassword">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="confirmPassword">
                          <Form.Label>User Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={userInfo ? userInfo.userName : ""}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="mySelect">
                          <Form.Label>Gender:</Form.Label>
                          <Form.Control
                            as="select"
                            value={selectedGender}
                            onChange={(e) => setSelectedGender(e.target.value)}
                          >
                            <option value="" disabled>
                              -- Select Gender --
                            </option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Others</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={12} className="text-center">
                        <Button type="submit" variant="primary">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </div>
            </div>

            <div className="profile-address">
              {userInfo && <OtherUserDetails />}
            </div>

            <div className="profile-address">
              {userInfo && (
                <UserAddresses
                  profile={true}
                  Addresses={user?.addresses}
                  success={successUpd}
                  AddressUpdHandler={AddUpdHandler}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
// const ProfileScreen=[]
export default ProfileScreen;
