import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import Message from "../components/Message";

import Axios from "axios";

const ReviewProduct = ({ productId }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [show, setShow] = useState(() => false);
  const [rating, setRating] = useState(() => 0);
  const [comment, setComment] = useState(() => "");

  const [images, setImages] = useState(() => [{ id: 0, image: null }]);

  const [msg, setMsg] = useState(() => "");

  const handleClose = (e) => {
    setShow(false);
    reset();
  };
  const showModal = () => {
    setShow(true);
  };
  const reset = () => {
    setRating(0);
    setImages([{ id: 0, image: null }]);
    setComment("");
  };
  const submitHandler = async (e) => {
    e.preventDefault();

    if (rating == "0") {
      setMsg("Please rate the product");
      setTimeout(() => {
        setMsg("");
      }, 3500);
      return;
    }
    if (comment.trim() == "") {
      setMsg("Please enter comment");
      setTimeout(() => {
        setMsg("");
      }, 3500);
      return;
    }

    let imgData = [];

    if (images.length != 0) {
      try {
        for (let i = 0; i < images.length; ++i) {
          if (
            images[i].name.split(".")[1].toLowerCase() != "jpg" &&
            images[i].name.split(".")[1].toLowerCase() != "jpeg" &&
            images[i].name.split(".")[1].toLowerCase() != "png"
          ) {
            setMsg("Upload a valid thumbnail Image of JPG, JPEG or PNG");
            setTimeout(() => {
              setMsg("");
            }, 3500);
            return;
          }
        }

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        for (let i = 0; i < images.length; ++i) {
          const formData = new FormData();
          formData.append("image", images[i]);

          const { data } = await Axios.post(
            "/api/resize-image/200/reviews",
            formData,
            config
          );

          imgData = [...imgData, data];
        }
      } catch (err) {
        console.log(err);
      }
    }

    let config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    try {
      let response = await Axios.post(
        `/api/products/${productId}/reviews`,
        { rating: rating, comment: comment, images: imgData },
        config
      );
      setShow(false);
      reset();
    } catch (err) {
      setMsg(err.response.data.message);
      setTimeout(() => {
        setMsg("");
      }, 3500);
    }
  };

  const removeImage = (id) => {
    if (images.length > 1) {
      let data = images.filter((e) => e.id != id);
      setImages(data);
    }
  };

  const handleImages = (e, index) => {
    let data = [...images];
    for (let i = 0; i < data.length; ++i) {
      if (data[i].id == index) {
        if (data[i].image == null) {
          data[i].image = e.target.files[0];
          let id = data[images.length - 1].id + 1;

          data.push({ id: id, image: null });
        } else {
          data[i].image = e.target.files[0];
        }
      }
    }
    setImages(data);
  };

  return (
    <>
      <a className="order" onClick={showModal}>
        Review Product
      </a>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {msg ? <Message variant="danger">{msg}</Message> : <></>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="rating">
              <Form.Label>Rating</Form.Label>
              <Form.Control
                as="select"
                value={rating}
                onChange={(e) => setRating(e.target.value)}
              >
                <option value="0">Select...</option>
                <option value="1">1 - Poor</option>
                <option value="2">2 - Fair</option>
                <option value="3">3 - Good</option>
                <option value="4">4 - Very Good</option>
                <option value="5">5 - Excellent</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="comment">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                row="3"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="formFile">
              <Form.Label>Product Images</Form.Label>
              <Row className="editVariant-img-wraper">
                {images.length != 0 ? (
                  images.map((e, i) => (
                    <Col md={4} key={Math.random()}>
                      <div className="uploadImg-wraper">
                        <div className="img">
                          <Image
                            style={{ width: "100%" }}
                            src={
                              e.image
                                ? window.URL.createObjectURL(e.image)
                                : "/img/upload.PNG"
                            }
                          />
                          <button
                            onClick={(click) => {
                              click.preventDefault();
                              removeImage(e.id);
                            }}
                            className="close"
                          >
                            <i class="las la-times"></i>
                          </button>
                        </div>
                        <Form.File
                          id="product-image"
                          custom={true}
                          onChange={(val) => {
                            handleImages(val, e.id);
                          }}
                        ></Form.File>
                      </div>
                    </Col>
                  ))
                ) : (
                  <></>
                )}
              </Row>
              {/* <Form.Control className='form-control' type='file' 
                                             onChange={(e)=>{
                                               if(e.target.files){
                                                 setImages(e.target.files)
                                               }
                                             }}
                                            multiple={true}/> */}
            </Form.Group>

            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReviewProduct;
