import { Table } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CardWrapper from "../../../components/CardWrapper";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom";

const EarningManagement = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [earningData, setEarningData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { userInfo } = useSelector((state) => state.userLogin);
    const history = useHistory()

    // console.log(earningData?.result?.dataList, "---satya----")

    useEffect(() => {
        const getEarningData = async () => {
            setIsLoading(true);
            try {
                const res = await Axios.get(
                    `${process.env.REACT_APP_EVENT_API_URL}/admin/dashboard/earnings?page=${currentPage}&limit=${pageSize}`,
                    {
                        headers: {
                            Authorization: `Bearer ${userInfo.eventToken}`,
                        },
                    }
                );
                if (res.data?.status === 1) setEarningData(res.data.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
            }
        };

        getEarningData();
    }, [currentPage, pageSize]);

    const columns = [
        {
            title: "Organizer Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Events",
            dataIndex: "events",
            key: "events"
        },
        {
            title: "Orders",
            dataIndex: "orders",
            key: "orders"
        },
        {
            title: "Sales",
            dataIndex: "sales",
            key: "sales",
            render: (value) => Number(value).toFixed(2),

        },
        {
            title: "Merchant Fee",
            dataIndex: "merchantFee",
            key: "merchantFee",
            render: (value) => Number(value).toFixed(2),

        },
        {
            title: "Refunded Amount",
            dataIndex: "refundedAmount",
            key: "refundedAmount",
            render: (value) => Number(value).toFixed(2),

        },
        {
            title: "Organiser Earning",
            dataIndex: "organiserRevenue",
            key: "organiserRevenue",
            render: (value) => Number(value).toFixed(2),

        },
        {
            title: "Escrow Amount",
            dataIndex: "escrowAmount",
            key: "escrowAmount",
            render: (value) => Number(value).toFixed(2),

        },
        {
            title: "Promoter Commission",
            dataIndex: "promoterAmount",
            key: "promoterAmount",
            render: (value) => Number(value).toFixed(2),

        },
        {
            title: "Admin Earning",
            dataIndex: "adminRevenue",
            key: "adminRevenue",
            render: (value) => Number(value).toFixed(2),

        },

    ];

    return (
        <section>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="main-heading">Earnings</h1>
                    </div>
                </div>
                <Container fluid={true}>
                    <section className="earnings-boxes">
                        <Row>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={" Total Events"}
                                    data={earningData?.result?.totalEventsCount}
                                    icon={"fas fa-shopping-bag"}
                                    colorData={"dash-icon text-secondary"}
                                />
                            </Col>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={" Total Orders"}
                                    data={earningData?.result?.totalOrderCount}
                                    icon={"fas fa-shopping-bag"}
                                    colorData={"dash-icon text-orange"}
                                />
                            </Col>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={" Total Sales"}
                                    data={`$ ${Number(earningData?.result?.totalSaleCount || 0).toFixed(2)}`}
                                    icon={"fas fa-money-bill"}
                                    colorData={"dash-icon text-warning"}
                                />
                            </Col>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={"Total Merchant Fee"}
                                    data={`$ ${Number(earningData?.result?.totalMerchantFee || 0).toFixed(2)}`}
                                    icon={"fas fa-dollar-sign"}
                                    colorData={"dash-icon text-primary"}
                                />
                            </Col>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={"Total Refunded Amount"}
                                    data={`$ ${Number(earningData?.result?.totalRefundedAmount || 0).toFixed(2)}`}
                                    icon={"fas fa-dollar-sign"}
                                    colorData={"dash-icon text-primary"}
                                />
                            </Col>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={"Total Organiser Earning"}
                                    data={`$ ${Number(earningData?.result?.totalOrganiserRevenue || 0).toFixed(2)}`}
                                    icon={"fas fa-dollar-sign"}
                                    colorData={"dash-icon text-primary"}
                                />
                            </Col>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={"Total Escrow Amount"}
                                    data={`$ ${Number(earningData?.result?.totalEscrowAmount || 0).toFixed(2)}`}
                                    icon={"fas fa-dollar-sign"}
                                    colorData={"dash-icon text-warning"}
                                />
                            </Col>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={"Total Promoter Commission"}
                                    data={`$ ${Number(earningData?.result?.totalPromoterAmount || 0).toFixed(2)}`}
                                    icon={"fas fa-dollar-sign"}
                                    colorData={"dash-icon text-warning"}
                                />
                            </Col>
                            <Col xl={4} sm={6}>
                                <CardWrapper
                                    title={"Total Admin Earning"}
                                    data={`$ ${Number(earningData?.result?.totalAdminRevenue || 0).toFixed(2)}`}
                                    icon={"fas fa-dollar-sign"}
                                    colorData={"dash-icon text-warning"}
                                />
                            </Col>
                        </Row>
                    </section>
                </Container>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <div className="paymentMethod-main">
                            <div>
                                {isLoading ? (
                                    <Loader />
                                ) : <Table
                                    columns={columns}
                                    dataSource={earningData?.result?.dataList}
                                    rowKey={(record) => record.name}
                                    onRow={(record) => ({
                                        onClick: () => history.push(`/admin/event/organiserEarnings/${record.organiserId}`),
                                        style: { cursor: 'pointer' }
                                    })}
                                    pagination={{
                                        current: currentPage,
                                        pageSize: pageSize,
                                        total: earningData?.pagination?.total || 0,
                                        onChange: (page, pageSize) => {
                                            setCurrentPage(page);
                                            setPageSize(pageSize);
                                        },
                                        showSizeChanger: true,
                                    }}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EarningManagement;
