import React from "react";
import { Col, OverlayTrigger,Tooltip } from "react-bootstrap";
import { Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

const Address = (props) => {
  console.log(props)
  let Address = props.Address;
  const DeleteAddressModal = (addId) => {
    props.deleteHandler(addId);
  };
  const cart = useSelector((state) => state.cart);
  const { shippingAddress, cartItems, billingAddress } = cart;
  console.log(billingAddress)

  const OpenAddressModal = (Address) => {
    props.ModalCaller(Address);
  };
  const DeliveryAddress=(Address)=>{
    props.updDelivery(Address)
  }
  const BillingAddress = (Address) => {
        props.updtBillingAddress(Address)
  }
  let buffer = Address.Address2? Address.Address1+" "+Address.Address2+" "+Address.City+" "+Address.Country+" "+Address.State+" "+Address.Zip
                                 :
                                 Address.Address1+" "+Address.City+" "+Address.Country+" "+Address.State+" "+Address.Zip
return (
    <Col sm={12} md={6} xl={4} key={props.id}>
      <figure className="full-size single-address">
        <h5>{Address.FirstName }{ Address.LastName? " "+Address.LastName : ''}</h5>
           <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">
                  {buffer}
                </Tooltip>
                }
               
              >
              <p style={{cursor : 'pointer'}}>
              {buffer.split('').length<40?buffer.split('').filter((e,i)=>i<40).map((val)=>val):buffer.split('').filter((e,i)=>i<40).map((val, index)=>{return index!=39?val:val+"..."})}
              </p>
              </OverlayTrigger>
        
        <div className="billing-checkbox">
          {!props.isProfilepage &&  
          <div className="billing full-size">
            {/* {console.log(Address._id)}
            {console.log(billingAddress._id)}
            {console.log(Address._id == billingAddress._id)} */}
              <input
                className="custom-control-input billingcheck"
                id={Address._id}
                type="checkbox"
                checked = {Address._id == billingAddress._id}
                name={Address._id}
                onChange = {()=>{BillingAddress(Address)}}
              />
              <label
                className="custom-control-label"
                htmlFor={Address._id}
                style={{fontWeight : 'bold'}}
              >
                use as billing address
              </label>
            </div>}
        </div>
        <div className="button">
          {!props.isProfilepage && <a className="deliver"  onClick={()=>{ DeliveryAddress(Address) }} >Deliver to this address</a>}
          <div className="bttn">
            <a
              className="edit" 
              onClick={() => OpenAddressModal(Address)}
            >
              <i className="las la-edit"></i> {!props.isProfilepage && 'Edit'}</a>
            <Popconfirm
              title="Are you sure？"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => DeleteAddressModal(Address._id)}
            >
              <a className="delete" >
                <i className="las la-trash-alt"></i> {!props.isProfilepage && 'Delete'}
              </a>
            </Popconfirm>
          </div>
        </div>
        
      </figure>
    </Col>
  );
};
export default Address;
