import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listOrders } from "../actions/orderActions";
import SideBar from "../components/Sidebar";
import SimilarProduct from "../components/SimilarProduct";
import Loader from "../components/Loader";
import { Input, Table, Button } from "antd";
import Paginate from "../components/Paginate";
import ReceivedOrderSteps from "../components/ReceivedOrderSteps";
import VendorSidebar from "./VendorSidebar";
// import VendorSidebar from "./VendorSidebar";

const OrderReceived = ({ match, history }) => {
  const dispatch = useDispatch();

  const { Search } = Input;

  const pageNo = match.params.pageNo || 1;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [listOrder, setListOrder] = useState(() => []);
  const [pages, setPages] = useState(() => 0);
  const [loading, setLoading] = useState(() => true);

  const [keyword, setKeyword] = useState(() => "");

  if (!userInfo) {
    history.push("/login");
  }

  // useEffect(() => {
  //   if (
  //     userInfo &&
  //     (userInfo.userType == "admin" || userInfo.userType == "vendor")
  //   ) {
  //     dispatch(listOrders());
  //   } else {
  //     history.push("/login");
  //   }
  // }, [dispatch, history, userInfo]);

  useEffect(() => {
    getAllOrders();
    document.title = "Order Received";
  }, [pageNo]);

  const getAllOrders = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    window.scrollTo(0, 0);
    setLoading(true);
    Axios.get(
      `/api/orders/vendorGetOrdersById/${userInfo._id}/${pageNo}`,
      config
    )
      .then((res) => {
        setLoading(false);
        // console.log("orders----------->" + res.data.orders);
        setListOrder(res.data.orders);
        setPages(res.data.pages);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const searchById = async () => {
    if (keyword && keyword.trim() != 0) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      window.scrollTo(0, 0);
      setLoading(true);
      Axios.get(
        `/api/orders/vendorGetOrdersById/${userInfo._id}?orderId=${keyword}`,
        config
      )
        .then((res) => {
          setLoading(false);
          console.log(res.data.orders);
          setListOrder(res.data.orders);
          setPages(res.data.pages);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  const columns = [
    {
      title: "Product Image",
      dataIndex: "productImage",
      key: "productImage",
      render: (text, e) => {
        return <img src={e.productImage} style={{ width: "100%" }} />;
      },
    },
    {
      title: "Product Name",
      dataIndex: "variantName",
      key: "variantName",
    },
    {
      title: "Order Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "150px",
      render: (text, e) => {
        return (
          e.createdAt.split("T")[0].split("-")[2] +
          "-" +
          e.createdAt.split("T")[0].split("-")[1] +
          "-" +
          e.createdAt.split("T")[0].split("-")[0]
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Packing Status",
      dataIndex: "orderPacked",
      key: "orderPacked",
      render: (text, e) => {
        return {
          children: (
            <div>
              {e.orderPacked ? (
                <span class="badge bg-success" style={{ color: "white" }}>
                  Order Packed
                </span>
              ) : (
                <Button
                  onClick={() => {
                    packOrders(e._id);
                  }}
                >
                  Mark as packed
                </Button>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: "Track Order",
      dataIndex: "tracking_url_provider",
      key: "tracking_url_provider",
      render: (text, e) => (
        <a href={e.tracking_url_provider} target="_blank">
          Track
        </a>
      ),
    },
    {
      title: "Shipping Label",
      dataIndex: "label_url",
      key: "label_url",
      render: (text, e) => (
        <a href={e.label_url} target="_blank">
          Click
        </a>
      ),
    },
  ];

  const packOrders = async (id) => {
    try {
      setLoading(true);
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      let response = await Axios.put(
        `/api/orders/vendor/order-packed/${id}`,
        {},
        config
      );
      let data = [...listOrder];

      for (let i = 0; i < data.length; ++i) {
        if (data[i]._id == id) {
          data[i].orderPacked = true;
        }
      }
      setLoading(false);
      setListOrder(data);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <section>
        <Container
          fluid={userInfo && userInfo?.isAdminLogin === true ? true : false}
        >
          <Row>
            <div className="col-md-12">
              <h1 className="main-heading"> </h1>
            </div>
          </Row>
          <Row>
            {userInfo && userInfo?.isAdminLogin !== true && (
              <VendorSidebar profile={true} />
            )}
            <Col
              md={12}
              lg={userInfo?.isAdminLogin === true ? 12 : 9}
              xl={userInfo?.isAdminLogin === true ? 12 : 9}
            >
              <div className="paymentMethod-main myorder-info full-size">
                <h4 className="heading">Received Order Management</h4>
                <ReceivedOrderSteps />

                <Row className="justify-content-end">
                  <Col md={4}>
                    <div className="ordersReceived-search">
                      <Search
                        placeholder="Search by Order Id"
                        onSearch={searchById}
                        enterButton
                        value={keyword}
                        onChange={(e) => {
                          setKeyword(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="antd-date">
                  <Table
                    columns={columns}
                    dataSource={listOrder}
                    rowKey={"_id"}
                    loading={loading}
                    pagination={false}
                    expandable={{
                      expandedRowRender: (record) => (
                        <div>
                          <table className="table table-striped table-bordered table-hover ordersReceived">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Order Id</strong>
                                </td>
                                <td>{record._id.toUpperCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Shipping Address</strong>
                                </td>
                                <td>
                                  <address>
                                    {record.shippingAddress.name +
                                      ", " +
                                      record.shippingAddress.address +
                                      ", " +
                                      record.shippingAddress.city +
                                      ", ZIP:" +
                                      record.shippingAddress.postalCode}
                                  </address>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Billing Address</strong>
                                </td>
                                <td>
                                  <address>
                                    {record.BillingAddress.name +
                                      ", " +
                                      record.BillingAddress.address +
                                      ", " +
                                      record.BillingAddress.city +
                                      ", ZIP:" +
                                      record.BillingAddress.postalCode}
                                  </address>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Product SKU</strong>
                                </td>
                                <td>{record.sku}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Price</strong>
                                </td>
                                <td>
                                  {record.amount.price}
                                  <br />
                                  {record.amount.discount != 0 ? (
                                    <>
                                      <span>
                                        Discount : {record.amount.discount}
                                      </span>
                                      <br />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Shipping Charge</strong>
                                </td>
                                <td>{record.shippingCharge}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Total (Billable Amount)</strong>
                                </td>
                                <td>
                                  {parseFloat(
                                    record.amount.price -
                                      record.amount.discount +
                                      record.shippingCharge
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ),
                    }}
                  />
                </div>
                <Paginate
                  url="/admin/ordersReceived"
                  page={pageNo}
                  pages={pages}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {userInfo && userInfo?.isAdminLogin !== true && <SimilarProduct />}
    </>
  );
};

export default OrderReceived;
