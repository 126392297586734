import React, { useEffect } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import "./assets/style/admin.css";
import "./assets/style/responsive.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

const AdminLayout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <SideBar isOpen={isOpen} toggleMenu={setIsOpen} />
      <section className={`page-wrapper ${!isOpen ? "active" : ""}`}>
        <Header isOpen={isOpen} toggleMenu={setIsOpen} />

        {children}
      </section>
    </div>
  );
};

export default AdminLayout;
