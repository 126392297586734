import React, { useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  useHistory,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { logout } from "./actions/userActions";
import Loader from "./components/Loader";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
// import DanzfloMerch from './screens/DanzfloMerch'
// import PrintfulProductScreen from './screens/PrintfulProductScreen'
// import VendorProductList from './screens/VendorProductList'

// const = React.lazy(()=>)
import VendorRegistration from "./screens/VendorRegistration";

// const VendorRegistration = React.lazy(()=>import('./screens/VendorRegistration'));
// const ProductScreen = React.lazy(()=>'./screens/ProductScreen')
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
// const CartScreen = React.lazy(()=>'./screens/CartScreen')

// const LoginScreen = React.lazy(()=>'./screens/LoginScreen')
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
// const ForgotPasswordScreen = React.lazy(()=>'./screens/ForgotPasswordScreen')
import ProfileScreen from "./screens/ProfileScreen";
// const ProfileScreen = React.lazy(()=>'./screens/ProfileScreen')
import ShippingScreen from "./screens/ShippingScreen";
// const ShippingScreen = React.lazy(()=>'./screens/ShippingScreen')
import DeliveryScreen from "./screens/DeliveryScreen";
// const DeliveryScreen = React.lazy(()=>'./screens/DeliveryScreen')
import PaymentScreen from "./screens/PaymentScreen";
// const PaymentScreen = React.lazy(()=>'./screens/PaymentScreen')
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
// const PlaceOrderScreen = React.lazy(()=>'./screens/PlaceOrderScreen')
import OrderScreen from "./screens/OrderScreen";
// const OrderScreen = React.lazy(()=>'./screens/OrderScreen')
import UserListScreen from "./screens/UserListScreen";
// const UserListScreen = React.lazy(()=>'./screens/UserListScreen')
import UserEditScreen from "./screens/UserEditScreen";
// const UserEditScreen = React.lazy(()=>'./screens/UserEditScreen')
import ProductListScreen from "./screens/ProductListScreen";
// const ProductListScreen = React.lazy(()=>'./screens/ProductListScreen')
import ProductEditScreen from "./screens/ProductEditScreen";
// const ProductEditScreen = React.lazy(()=>'./screens/ProductEditScreen')
import OrderListScreen from "./screens/OrderListScreen";
// const OrderListScreen = React.lazy(()=>'./screens/OrderListScreen')
import OpenOrderScreen from "./screens/OpenOrderScreen";
// const OpenOrderScreen = React.lazy(()=>'./screens/OpenOrderScreen')
import CancelledOrdersScreen from "./screens/CancelledOrdersScreen";
// const CancelledOrdersScreen = React.lazy(()=>'./screens/CancelledOrdersScreen')
import DeliveredOrdersScreen from "./screens/DeliveredOrdersScreen";
// const DeliveredOrdersScreen = React.lazy(()=>'./screens/DeliveredOrdersScreen')
import CategoryListScreen from "./screens/CategoryListScreen";
// const CategoryListScreen = React.lazy(()=>'./screens/CategoryListScreen')
import Account from "./screens/Account/Account";
// const Account = React.lazy(()=>'./screens/Account/Account')
import PaymentMethods from "./screens/PaymentMethods";
// const PaymentMethods = React.lazy(()=>"./screens/PaymentMethods")
import Notifications from "./screens/Notifications";
// const Notifications = React.lazy(()=>'./screens/Notifications')
import Wishlist from "./screens/Wishlist";
// const Wishlist = React.lazy(()=>"./screens/Wishlist")
import AccountSetting from "./screens/AccountSettings";
// const AccountSetting = React.lazy(()=>"./screens/AccountSettings")
import VariantScreen from "./screens/VariantScreen";
// const VariantScreen = React.lazy(()=>'./screens/VariantScreen')
import AddProduct from "./screens/AddProduct";
// const AddProduct = React.lazy(()=>"./screens/AddProduct")
import PrivacyPolicy from "./screens/PrivacyPolicy";
// const PrivacyPolicy = React.lazy(()=>"./screens/PrivacyPolicy")
import TermsConditions from "./screens/TermsConditions";
// const TermsConditions = React.lazy(()=>"./screens/TermsConditions")
import PromoCode from "./screens/PromoCode";
// const PromoCode = React.lazy(()=>"./screens/PromoCode")
import ResetPassword from "./screens/ResetPassword";
// const ResetPassword = React.lazy(()=>'./screens/ResetPassword')
import CategoryScreen from "./screens/CategoryScreen";
// const CategoryScreen = React.lazy(()=>'./screens/CategoryScreen')
import OrderPlaced from "./screens/OrderPlaced";
// const OrderPlaced = React.lazy(()=>'./screens/OrderPlaced')
import OrderReceived from "./screens/OrderReceived";
// const OrderReceived = React.lazy(()=>'./screens/OrderReceived')
import VerifyScreen from "./screens/VerifyScreen";
// const VerifyScreen = React.lazy(()=>'./screens/VerifyScreen')
import QuicckBooksCallback from "./components/QuickBooksCallBack";
// const QuicckBooksCallback = React.lazy(()=>'./screens/VerifyScreen')
import StripeConnectSuccess from "./screens/StripeConnectSuccess";
// const StripeConnectSuccess = React.lazy(()=>"./screens/StripeConnectSuccess")
import StripeConnectFailure from "./screens/StripeConnectFailure";
// const StripeConnectFailure = React.lazy(()=>"./screens/StripeConnectFailure")
import ContachInfo from "./screens/ContacInfo";
// const ContachInfo = React.lazy(()=>'./screens/ContacInfo')
import NotFound from "./components/404Page";
// const NotFound = React.lazy(()=>'./components/404Page')
import AccountFee from "./screens/AccountFee";
// const AccountFee = React.lazy(()=>'./screens/AccountFee')
import CookiePolicy from "./screens/CookiePolicy";
// const CookiePolicy = React.lazy(()=>"./screens/CookiePolicy")
import HowDoesItWorks from "./screens/HowDoesItWorks";
// const HowDoesItWorks = React.lazy(()=>"./screens/HowDoesItWorks")
import VendorCancelledOrders from "./screens/VendorCancelledOrders";
// const VendorCancelledOrders = React.lazy(()=>'./screens/VendorCancelledOrders')
import AllOrdersAdmin from "./screens/AllOrdersAdmin";
// const AllOrdersAdmin = React.lazy(()=>'./screens/AllOrdersAdmin')
import VendorProfile from "./screens/VendorProfile";
// const VendorProfile = React.lazy(()=>'./screens/VendorProfile')
import Specifications from "./screens/Specifications";
// const Specifications = React.lazy(()=>'./screens/Specifications')
import OrderDetailsScreen from "./screens/OrderDetailsScreen";
// const OrderDetailsScreen = React.lazy(()=>'./screens/OrderDetailsScreen')
import MyEarnings from "./screens/MyEarnings";
// const MyEarnings = React.lazy(()=>'./screens/MyEarnings')
import VendorAllCancelledOrders from "./screens/VendorAllCancelledOrders";
// const VendorAllCancelledOrders = React.lazy(()=>'./screens/VendorAllCancelledOrders')
import VendorDeliveredOrders from "./screens/VendorDeliveredOrders";
// const VendorDeliveredOrders = React.lazy(()=>'./screens/VendorDeliveredOrders')
import ExternalAuth from "./screens/ExternalAuth";
// const ExternalAuth = React.lazy(()=>'./screens/ExternalAuth')
import Support from "./screens/Support";
import SupportDetails from "./screens/SupportDetails";
import AdminLayout from "./layout/Admin/AdminLayout";
import Dashboard from "./screens/Admin/Dashboard";
import AdminRoutes from "./AdminRoutes";
import AdminLoginScreen from "./screens/Admin/AdminLogin";
import ExternalAdminLogin from "./screens/Admin/ExternalAdminLogin";

const BaseScreen = React.lazy(() => import("./screens/BaseScreen"));
const HomeScreen = React.lazy(() => import("./screens/HomeScreen"));

const App = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const history = useHistory();

  // useEffect(() => {
  //   if (userInfo?.isAdminLogin) {
  //     history.push('/admin')
  //   }
  // }, [userInfo, history]);

  // useEffect(() => {
  //   if (userInfo && userInfo?.isAdminLogin === true) {
  //     window.location.href = '/admin'
  //     // history.push("/admin");
  //   }
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      let config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      Axios.get(`/api/users/check-login`, config)
        .then((res) => {
          if (res.data.status == false) {
            dispatch(logout());
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  }, []);

  // const role = "admin";

  return (
    <Router>
      <>
        {userInfo && userInfo?.isAdminLogin === true ? (
          <Suspense
            fallback={
              <div>
                <Loader />
              </div>
            }
          >
            <AdminRoutes />
            {/* <Redirect to="/admin" /> */}
          </Suspense>
        ) : (
          <>
            {/* <Route path="/" exact> */}
            <Header />

            <main>
              {/* <script src="/dist/zuck.min.js"></script> */}
              <Suspense
                fallback={
                  <div>
                    <Loader />
                  </div>
                }
              >
                <>
                  <Route path="/wishlist" component={Wishlist} exact />
                  <Route
                    path="/notifications"
                    component={Notifications}
                    exact
                  />
                  <Route path="/contact-info" component={ContachInfo} exact />

                  <Route
                    path="/ordersReceived/:pageNo?"
                    component={OrderReceived}
                    exact
                  />
                  <Route path="/my-earnings" component={MyEarnings} />

                  <Route
                    path="/raised-tickets/:type?"
                    component={Support}
                    exact
                  />
                  <Route
                    path="/support-details/:type/:supportId"
                    component={SupportDetails}
                    exact
                  />
                  <Route
                    path="/orders/:pageNo?"
                    component={OrderListScreen}
                    exact
                  />
                  <Route
                    path="/order-details/:orderId"
                    component={OrderDetailsScreen}
                    exact
                  />
                  <Route
                    path="/open-orders/:pageNo?"
                    component={OpenOrderScreen}
                    exact
                  />
                  <Route
                    path="/cancelled-orders/:pageNo?"
                    component={CancelledOrdersScreen}
                    exact
                  />
                  <Route
                    path="/delivered-orders/:pageNo?"
                    component={DeliveredOrdersScreen}
                    exact
                  />
                  <Route path="/profile" component={ProfileScreen} exact />
                  <Route
                    path="/:name/store/:vendorId/:categoryName?/:categoryId?/:pageNumber?"
                    component={VendorProfile}
                    exact
                  />
                  <Route path="/support" component={Support} exact />

                  <Route path="/promocode" component={PromoCode} exact />

                  <Route
                    path="/vendorAllCancelledOrders/:pageNo?"
                    component={VendorAllCancelledOrders}
                    exact
                  />
                  <Route
                    path="/vendorAllDeliveredOrders/:pageNo?"
                    component={VendorDeliveredOrders}
                    exact
                  />
                  <Route
                    path="/productlist"
                    component={ProductListScreen}
                    exact
                  />
                  {/* <Route path="/variants" component={VariantScreen} exact /> */}

                  <Route path="/addProduct" component={AddProduct} exact />
                  <Route path="/editProduct" component={AddProduct} exact />
                  <Route
                    path="/approve-return-orders/:pageNo?"
                    component={VendorAllCancelledOrders}
                    exact
                  />
                  <Route path="/order/:id" component={OrderScreen} exact />
                  <Route
                    path="/StripeAccount/success/:url?/:isMusic?/:isEvent?/:isClasses?/:isTv?"
                    component={StripeConnectSuccess}
                    exact
                  />
                  <Route
                    path="/StripeAccount/failure"
                    component={StripeConnectFailure}
                    exact
                  />
                  <Route path="/account" component={Account} exact />

                  <Route
                    path="/add-story/:fromSocial?"
                    component={BaseScreen}
                    exact
                  />

                  <Route path="/search/:keyword" component={HomeScreen} exact />
                  <Route
                    path="/category/:name/:id/:pageNumber?"
                    component={CategoryScreen}
                    exact
                  />
                  <Route
                    path="/page/:pageNumber"
                    component={HomeScreen}
                    exact
                  />
                  <Route
                    path="/vendor/profile"
                    component={VendorProfile}
                    exact
                  />
                  <Route
                    path="/search/:keyword/page/:pageNumber"
                    component={HomeScreen}
                    exact
                  />
                </>
                <Route path="/" component={BaseScreen} exact />
                {/* <Route path="/dashboard" component={BaseScreen} exact /> */}
                <Route
                  path="/register-vendor/:type?/:redirect?/:userId?/:mobile?"
                  component={VendorRegistration}
                  exact
                />
                <Route
                  path="/QuicckBooksCallback"
                  component={QuicckBooksCallback}
                  exact
                />
                <Route path="/login" component={LoginScreen} exact />
                <Route path="/register" component={RegisterScreen} exact />
                <Route
                  path="/forgot-password"
                  component={ForgotPasswordScreen}
                  exact
                />
                <Route
                  path="/danzflo-reset-password/:id?"
                  component={ResetPassword}
                  exact
                />
                {/* 
                <Route
                  path="/raised-tickets/:type?"
                  component={Support}
                  exact
                /> */}
                <Route path="/orderPlaced" component={OrderPlaced} exact />

                <Route path="/merch" component={HomeScreen} exact />
                <Route path="/product/:id" component={ProductScreen} exact />
                <Route path="/cart/:id?" component={CartScreen} exact />
                <Route path="/shipping" component={ShippingScreen} exact />
                <Route path="/delivery" component={DeliveryScreen} exact />
                <Route path="/payment" component={PaymentScreen} exact />
                <Route path="/placeorder" component={PlaceOrderScreen} exact />

                <Route path="/privacy-policy" component={PrivacyPolicy} exact />
                <Route
                  path="/terms-conditions"
                  component={TermsConditions}
                  exact
                />
                <Route
                  path="/verify-account/:userId?"
                  component={VerifyScreen}
                  exact
                />

                {/* <Route path='/danzflo-merch/:pageNumber?' component={DanzfloMerch} exact /> */}
                {/* <Route path='/danzflo-merch/product/:id' component={PrintfulProductScreen} exact /> */}
                <Route path="/cookie-policy" component={CookiePolicy} />
                <Route path="/how-does-it-work" component={HowDoesItWorks} />
                <Route
                  path="/auth/login/:authId?/:userName?/:mobile?/:redirect?"
                  component={ExternalAuth}
                />
                <Route path="/admin/login" component={AdminLoginScreen} exact />
                <Route
                  path="/auth/admin/login/:authId?/:redirect?"
                  component={ExternalAdminLogin}
                  exact
                />

                <Route path="/404" component={NotFound} />
                {/* <Redirect to="/404" /> */}
              </Suspense>
            </main>

            <Footer />
            {/* </Route> */}
          </>
        )}
      </>
    </Router>
  );
};

export default App;
