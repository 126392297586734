import Axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import VendorSidebar from "./VendorSidebar";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const Support = ({ history, match }) => {
  let dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
  }, [userInfo, history, dispatch]);

  const [queries, setQueries] = useState(() => []);
  const [loading, setLoading] = useState(() => true);
  let type = match.params.type ? match.params.type : "vendor";

  useEffect(() => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      setLoading(true);
      Axios.post(`/api/miscellaneous/get-vendor-tickets/${type}`, {}, config)
        .then((response) => {
          setQueries(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      <section>
        <Container
          fluid={userInfo && userInfo?.isAdminLogin === true ? true : false}
        >
          <Row>
            <div className="col-md-12">
              <h1 className="main-heading"> </h1>
            </div>
          </Row>
          <Row>
            {userInfo && userInfo?.isAdminLogin !== true && (
              <VendorSidebar profile={true} />
            )}
            <Col
              md={12}
              lg={userInfo && userInfo?.isAdminLogin === true ? 12 : 9}
              xl={userInfo && userInfo?.isAdminLogin === true ? 12 : 9}
            >
              <div className="paymentMethod-main">
                <div className="support-heading">
                  <h4>Support Tickets</h4>
                  <p>
                    View your past and active support tickets. You can close or
                    re-open tickets at any time.
                  </p>
                </div>

                <div className="support-tickets-listing">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <h4 className="card-title card-title--first">
                          Your Support Tickets
                        </h4>
                      </div>
                      <div className="col-md-6">
                        <div className="searchSupport-input">
                          <InputGroup className="mb-3">
                            <FormControl
                              placeholder="Search support tickets"
                              aria-label="Search support tickets"
                              aria-describedby="searchSupport"
                            />
                            <InputGroup.Append>
                              <Button>
                                <i className="las la-search"></i>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                    <div className="support-tickets-table">
                      <table>
                        <thead>
                          <tr>
                            {/* <th scope="col"><input type="checkbox" /></th> */}
                            <th scope="col">Ticket Number</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Last Updated</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading ? (
                            queries.length != 0 ? (
                              queries.map((e) => (
                                <tr>
                                  <td data-label="Ticket Number">#{e._id}</td>
                                  <td data-label="Summary">
                                    <p>{e.subject}</p>
                                  </td>
                                  <td data-label="Last Updated">
                                    {e.updatedAt
                                      .split("T")[0]
                                      .split("-")
                                      .reverse()
                                      .join("/")}
                                    , {e.updatedAt.split("T")[1].split(".")[0]}
                                  </td>
                                  <td data-label="Status">
                                    {e.replies[e.replies.length - 1]
                                      .replyType == "vendor_reply"
                                      ? "You Replied"
                                      : "Awaiting Your Reply"}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/support-details/${type}/${e._id}`}
                                      className="btn btn-link"
                                    >
                                      View Ticket
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <></>
                            )
                          ) : (
                            <tr>
                              <td data-label="Ticket Number"></td>
                              <td data-label="Summary"></td>
                              <td data-label="Last Updated">
                                <Loader />
                              </td>
                              <td data-label="Status"></td>
                              <td>
                                <a href="#" className="btn btn-link"></a>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* <div className="col-sm-12" style={{textAlign: "center"}}><a href="#" className="btn btn-sm btn-outline-secondary">View 72 closed tickets</a></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Support;
