import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { Table, Popconfirm, Checkbox } from "antd";

const UserManagement = () => {
  const [searchText, setSearchText] = useState("");
  const [filterModel, setFilterModel] = useState(false);
  const [data, setData] = useState([]);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_EVENT_API_URL}/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.eventToken}`,
          },
        }
      );
      if (res.data.status === 1) {
        setData(res.data.data.users);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text, response) => `${response.firstName} ${response.lastName}`,
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      width: "20%",
      render: (text, response) => response.userName,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "30%",
      render: (text, response) => (
        <a href={`mailto:${response.email}`}>{response.email}</a>
      ),
    },

    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      width: "10%",
      render: (text, response) => response.userType,
    },
    {
      title: "Promoter",
      dataIndex: "isPromoter",
      key: "isPromoter",
      width: "10%",
      render: (text, response) =>
        response.isPromoter === true ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
        ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
        ),
    },
    {
      title: "Guard",
      dataIndex: "isGuard",
      key: "isGuard",
      width: "10%",
      render: (text, response) =>
        response.isGuard === true ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
        ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
        ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: "20%",
    //   render: (text, response) => (
    //     <>
    //       {/* <Button
    //         variant="light"
    //         className="btn-sm"
    //         onClick={() => {
    //           editUser(response._id);
    //         }}
    //       >
    //         <i className="fas fa-edit"></i>
    //       </Button> */}
    //     </>
    //   ),
    // },
  ];

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let res = {};
      if (searchText !== "") {
        if (userType !== "") {
          res = await Axios.get(
            `${process.env.REACT_APP_EVENT_API_URL}/admin/users?search=${searchText}&userType=${userType}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.eventToken}`,
              },
            }
          );
          if (res.data.status === 1) {
            setData(res.data.data.users);
          }
        } else {
          res = await Axios.get(
            `${process.env.REACT_APP_EVENT_API_URL}/admin/users?search=${searchText}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.eventToken}`,
              },
            }
          );
          if (res.data.status === 1) {
            setData(res.data.data.users);
          }
        }
      } else {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.users);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleFilter = async () => {
    setIsLoading(true);
    try {
      let res = {};

      if (searchText !== "") {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/users?search=${searchText}&userType=${userType}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.users);
        }
      } else {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/users?userType=${userType}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.users);
        }
      }
      setFilterModel(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading">Users</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="paymentMethod-main">
                <div>
                  <>
                    <div>
                      <Row className="align-items-center">
                        <Col className="md-6">
                          <h4 className="heading">User Management</h4>
                        </Col>
                        <Col className="text-right">
                          <Button
                            variant="secondary"
                            className="my-3"
                            onClick={() => setFilterModel(true)}
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                        </Col>
                        <Col>
                          <Form.Group
                            className="d-flex justify-content-end"
                            style={{
                              width: "300px",
                            }}
                          >
                            <input
                              placeholder="Search..."
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button onClick={handleSearch}>Search</Button>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Table
                        dataSource={data}
                        columns={columns}
                        rowKey={"_id"}
                        loading={isLoading}
                        // expandable={{
                        //   expandedRowRender: (record) => (
                        //     <p style={{ margin: 0 }}>User Id: {record._id}</p>
                        //   ),
                        // }}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={filterModel} onHide={() => setFilterModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>User Type</Form.Label>
                  <Form.Control
                    value={userType}
                    onChange={(e) => {
                      setUserType(e.target.value);
                    }}
                    as="select"
                  >
                    <option value="">Please select UserType</option>
                    <option value="organizer">organizer</option>
                    <option value="guard">guard</option>
                    <option value="promoter">promoter</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setFilterModel(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFilter}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserManagement;
