import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import Message from "../components/Message";

import axios from "axios";

const IssueATicket = ({ orderId, vendorId, setSuccess, setError }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [show, setShow] = useState(() => false);
  const [subject, setSubject] = useState(() => "");
  const [content, setContent] = useState(() => "");
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(() => "");
  const [loading, setLoading] = useState(() => false);
  const [message, setMessage] = useState(() => "");

  const handleClose = (e) => {
    setShow(false);
    reset();
  };
  const showModal = () => {
    setShow(true);
  };
  const reset = () => {
    setSubject("");
    setContent("");
    setImage(null);
    setUrl("");
    setLoading(false);
  };
  const submitHandler = async (e) => {
    e.preventDefault();

    if (userInfo) {
      if (!subject || subject.trim() == "") {
        setMessage("Enter Subject");
        setTimeout(() => {
          setMessage("");
        }, 3500);
        return;
      }
      if (!content || content.trim() == "") {
        setMessage("Enter Subject");
        setTimeout(() => {
          setMessage("");
        }, 3500);
        return;
      }
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      let data = {
        vendorId: vendorId,
        orderId: orderId,
        subject: subject,
        data: {
          content: content,
          image: url,
        },
      };

      try {
        let response = await axios.post(
          "/api/miscellaneous/contact-vendor",
          data,
          config
        );
        setShow(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3500);
        reset();
      } catch (err) {
        setError(true);
        setTimeout(() => {
          setError(true);
        }, 3500);
        setShow(false);
        reset();
      }
    }
  };

  const handleImage = async (e) => {
    // console.log(e.target.files[0]);
    // return;
    if (e && e.target && e.target.files[0]) {
      setImage(e.target.files[0]);
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        setLoading(true);
        const { data } = await axios.post(
          "/api/resize-image/200/product200",
          formData,
          config
        );
        setUrl(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <a className="order" onClick={showModal}>
        Contact Vendor
      </a>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Connect With Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card update-ticket-form">
            <div className="card-body">
              {message && <Message variant="danger">{message}</Message>}
              <div className="card-title">Subject</div>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>

            <div className="card-body">
              <div className="card-title">Your Message</div>
              <form>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    value={content}
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                    rows="5"
                    placeholder="Ticket comments"
                  ></textarea>
                </div>
                {!loading ? (
                  <div className="file-input">
                    <input
                      type="file"
                      name="file-input"
                      id="file-input"
                      className="file-input__input"
                      onChange={handleImage}
                    />
                    <label className="file-input__label" for="file-input">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="upload"
                        className="svg-inline--fa fa-upload fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                        ></path>
                      </svg>
                      <span>Upload file</span>
                    </label>
                  </div>
                ) : (
                  <>
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </>
                )}
                <div className="small text-muted">
                  {!url ? (
                    <span>
                      Supported filetypes are: .jpeg, .jpg, .png up to a
                      filesize of 5MB.
                    </span>
                  ) : (
                    <Image src={url} />
                  )}
                </div>
              </form>
            </div>
            <div className="card-footer">
              <div className="form-group mb-0">
                <button
                  disabled={loading}
                  className="my-1 btn btn-primary"
                  onClick={submitHandler}
                >
                  Contact Vendor
                </button>
                {/* <button className="my-1 btn btn-outline-secondary">Update &amp; Resolved</button> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IssueATicket;
