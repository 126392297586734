import { Table } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ModalWapper from "../../components/Common/ModalWapper";

import moment from "moment";
const EventManagement = () => {
  const [data, setData] = useState([]);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [isLoading, setIsLoading] = useState(false);
  const [walletAmount, setWalletAmount] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    getAllTransactions();
    transferAmount({ isTransfer: false });
  }, []);
  useEffect(() => {
    getAllTransactions();
  }, [walletAmount]);

  const getAllTransactions = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(`/api/analytics/transactions`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      if (res.status === 200);
      setData(res.data.result);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const transferAmount = async (data) => {
    const { isTransfer = false, withdrawAmount } = data;
    setIsLoading(true);
    try {
      const res = await Axios.post(
        `/api/analytics/withdraw`,
        { isTransfer, withdrawAmount },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      setIsShow(false);

      if (res.status === 200) {
        if (isTransfer) {
          toast.success(res.data.message);
        }
        setWalletAmount(res.data);
        setIsShow(false);
      }

      setIsLoading(false);
    } catch (error) {
      setIsShow(false);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "10%",
      render: (text, response) => `$${response.amount}`,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (text, response) =>
        `${moment(response.createdAt).format("DD-MM-YYYY HH:MM")}`,
    },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: "15%",
    //   // render: (text, response) => response.status,
    // },
  ];

  const handleFilter = async () => {
    setIsLoading(true);
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setAmount(parseInt(inputValue?.trim()));
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (amount <= 0 || isNaN(amount)) {
      setError("Please enter a valid number greater than zero.");
      return;
    }
    transferAmount({ isTransfer: true, withdrawAmount: amount });
  };
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading">Transactions</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="paymentMethod-main">
                <div>
                  <>
                    <div>
                      <Row className="align-items-center">
                        <Col className="md-6">
                          <h4 className="heading">
                            Balance: $
                            {walletAmount?.result?.transferableAmount
                              ? walletAmount.result.transferableAmount?.toFixed(2)
                              : 0}
                          </h4>
                        </Col>
                        <Col className="text-right">
                          <Button
                            variant="primary"
                            className="my-3"
                            onClick={() => setIsShow((prev) => !prev)}
                            disabled={isLoading}
                          >
                            Withdraw
                          </Button>
                        </Col>
                      </Row>
                      <Table
                        dataSource={data}
                        columns={columns}
                        rowKey={"_id"}
                        loading={isLoading}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isShow && (
        <ModalWapper
          title="Withdraw Wallet Amount"
          handleModalSubmit={handleSubmit}
          show={isShow}
          setShow={setIsShow}
          closeButtonText="Cancel"
          submitButtonText="Withdraw"
          isLoading={isLoading}
        >
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={handleChange}
                  placeholder="Enter Amount"
                />
                {error && <div style={{ color: "red" }}>{error}</div>}
              </Form.Group>
            </Row>
          </Form>
        </ModalWapper>
      )}
    </>
  );
};

export default EventManagement;
