import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";

const HowToDecidePrice = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        style={{
          background: "none",
          color: "#2aa8f2",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
        onClick={handleShow}
      >
        How to decide price?
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          {/* <Modal.Title>How to decide price?</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <section className="policy-sec bg-gray">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="inner-sec full-size">
                    <div className="policy-detail box-shadow">
                      <h4>How to decide price?</h4>
                      {/* <h5>Commision and </h5> */}
                      <p>
                        The pricing policy of DanzFlo Merch is really simple and
                        straight forward. Here in this section we'll walk you
                        through the commision policy and other deductions, to
                        help you decide price of your products with the help of
                        an example.
                      </p>
                      <h6>Example</h6>
                      <p>
                        Let us assume that Mr. Doe wants to sell a product on
                        DanzFlo. So he decides to price the product at $100
                        inclusive of all taxes. And he lists his products and
                        variants in the platform, with all variants has the same
                        price, i.e. 100 USD.
                      </p>
                      <p>
                        Now, He receives an order for the product he listed and
                        the customer pays $107 including shipping. So, now Mr.
                        Doe packs the product and our delivery partner receives
                        it and delivers it to the destination.
                      </p>
                      <p>
                        As soon as the product is delivered, we pay our vendors.
                        Now, Mr. Doe will receive amount&#127881; after some
                        deductions.
                      </p>
                      <p>
                        Lets assume that DanzFlo takes a simple 10% commision on
                        amount at which the product is sold(excluding shipping)
                        for each sale.
                      </p>
                      <p>The calculations for deductions are as follows:</p>

                      <p>
                        Customer BreakDown
                        <br />
                        Product Costs: $100
                        <br />
                        Shipping: $7
                        <br />
                        ________________
                        <br />
                        Total: $107
                        <br />
                        <br />
                      </p>

                      <p>
                        Vendor BreakDown:
                        <br />
                        Product Sold At: $100
                        <br />
                        DanzFlo Takes: $10 ( 10% )<br />
                        Payment Gateway Fee: $3.5 (3.5% Approx.)
                        <br />
                        ________________________________
                        <br />
                        You Keep: $86.5(Approx.)
                        <br />
                      </p>
                      {/* <h5>Definitions</h5>
                                <p>For the purposes of this Privacy Policy:</p>
                                <ol>
                                    <li>
                                        <p><span style={{fontWeight: 'bold'}}>Account</span> means a unique account created for You to access our Service or parts of our Service.</p>
                                    </li>
                                </ol> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HowToDecidePrice;
