import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { Table, Popconfirm, Checkbox } from "antd";
import { Link } from "react-router-dom";

const OrderManagement = () => {
  const [searchText, setSearchText] = useState("");
  const [filterModel, setFilterModel] = useState(false);
  const [data, setData] = useState([]);
  const { userInfo } = useSelector((state) => state.userLogin);
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_EVENT_API_URL}/admin/orders`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.eventToken}`,
          },
        }
      );
      if (res.data.status === 1) {
        setData(res.data.data.orders);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      width: "20%",
      render: (text, response) => `${response.event[0].title}`,
    },
    {
      title: "Registered Name",
      dataIndex: "registeredName",
      key: "registeredName",
      width: "15%",
      render: (text, response) =>
        `${response.billingDetails.firstName} ${response.billingDetails.lastName}`,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: "10%",
      render: (text, response) => `$${response.total}`,
    },

    {
      title: "Ticket Type",
      dataIndex: "ticketType",
      key: "ticketType",
      width: "10%",
      render: (text, response) => response.tickets.length,
    },
    {
      title: "Date of Order",
      dataIndex: "dateOfOrder",
      key: "dateOfOrder",
      width: "15%",
      render: (text, response) =>
        new Date(response.createdAt).toISOString().split("T")[0],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, response) => response.status,
    },
    // {
    //   title: "Guard",
    //   dataIndex: "isGuard",
    //   key: "isGuard",
    //   width: "10%",
    //   render: (text, response) =>
    //     response.isGuard === true ? (
    //       <i className="fas fa-check" style={{ color: "green" }}></i>
    //     ) : (
    //       <i className="fas fa-times" style={{ color: "red" }}></i>
    //     ),
    // },
    {
      title: "Show Attendees",
      dataIndex: "showAttendees",
      key: "showAttendees",
      width: "20%",
      render: (text, response) => (
        <>
          <Link to={`/admin/event/orders/attendees/${response._id}`}>
            Show Attendees
          </Link>
        </>
      ),
    },
  ];

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let res = {};
      if (searchText !== "") {
        if (userType !== "") {
          res = await Axios.get(
            `${process.env.REACT_APP_EVENT_API_URL}/admin/orders?search=${searchText}&status=${userType}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.eventToken}`,
              },
            }
          );
          if (res.data.status === 1) {
            setData(res.data.data.orders);
          }
        } else {
          res = await Axios.get(
            `${process.env.REACT_APP_EVENT_API_URL}/admin/orders?search=${searchText}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.eventToken}`,
              },
            }
          );
          if (res.data.status === 1) {
            setData(res.data.data.orders);
          }
        }
      } else {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/orders`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.orders);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleFilter = async () => {
    setIsLoading(true);
    try {
      let res = {};

      if (searchText !== "") {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/orders?search=${searchText}&status=${userType}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.orders);
        }
      } else {
        res = await Axios.get(
          `${process.env.REACT_APP_EVENT_API_URL}/admin/orders?status=${userType}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.eventToken}`,
            },
          }
        );
        if (res.data.status === 1) {
          setData(res.data.data.orders);
        }
      }
      setFilterModel(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 className="main-heading">Orders</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="paymentMethod-main">
                <div>
                  <>
                    <div>
                      <Row className="align-items-center">
                        <Col className="md-6">
                          <h4 className="heading">Order Management</h4>
                        </Col>
                        <Col className="text-right">
                          <Button
                            variant="secondary"
                            className="my-3"
                            onClick={() => setFilterModel(true)}
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                        </Col>
                        <Col>
                          <Form.Group
                            className="d-flex justify-content-end"
                            style={{
                              width: "300px",
                            }}
                          >
                            <input
                              placeholder="Search..."
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button onClick={handleSearch}>Search</Button>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Table
                        dataSource={data}
                        columns={columns}
                        rowKey={"_id"}
                        loading={isLoading}
                        expandable={{
                          expandedRowRender: (record) => {
                            return (
                              <div>
                                <span className="h5 mb-2">Tickets</span>
                                {record.tickets.map((ticket) => (
                                  <p className="h6">Name: {ticket.name}</p>
                                ))}
                              </div>
                            );
                          },
                        }}
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={filterModel} onHide={() => setFilterModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    value={userType}
                    onChange={(e) => {
                      setUserType(e.target.value);
                    }}
                    as="select"
                  >
                    <option value="">Please select status</option>
                    <option value="cancelled">cancelled</option>
                    {/* <option value="guard">guard</option>
                    <option value="promoter">promoter</option> */}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setFilterModel(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFilter}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderManagement;
