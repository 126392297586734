import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TreeSelect } from "antd";
import Message from "../components/Message";

import Axios from "axios";

const AddVariants = ({ disabled, setBufferVariants, setVariantList }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { SHOW_ALL } = TreeSelect;

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    Axios.get(`/api/category/getall`, config)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [modalVisibility, setModalVisibility] = useState(() => false);
  const [values, setValues] = useState(() => [
    {
      value: "",
    },
  ]);
  const [categories, setCategories] = useState(() => []);
  const [selectedCategories, setSelectedCategories] = useState(() => []);
  const [msg, setMsg] = useState(() => "");

  const [name, setName] = useState(() => "");
  const [success, setSuccess] = useState(() => false);
  const [successMsg, setSuccessMsg] = useState(() => "");

  const [err, setErr] = useState(() => false);
  const [errorMsg, setErrorMsg] = useState(() => "");

  const addCategoryCancel = () => {
    setMsg("");
    setName("");
    setValues([
      {
        value: "",
      },
    ]);
    setSelectedCategories([]);
    setModalVisibility(false);
  };

  const handleValueInput = (value, i) => {
    let val = [...values];
    val[i].value = value;
    setValues(val);
  };

  const addFeild = () => {
    let val = [...values];
    val.push({
      value: "",
    });

    setValues(val);
  };

  const removeField = (index) => {
    let val = [];
    for (let i = 0; i < values.length; ++i) {
      if (i != index) {
        val.push(values[i]);
      }
    }
    setValues(val);
  };

  const handleSave = (e) => {
    e.preventDefault();
    let emp = false;

    if (name.trim() == "") {
      setMsg("Enter Variant Name");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }
    for (let i = 0; i < values.length; ++i) {
      if (values[i].value.trim() === "") {
        setMsg("Variant value Cannot be empty");
        setTimeout(() => {
          setMsg("");
        }, 3000);
        emp = true;
        return;
      }
    }
    if (selectedCategories.length == 0) {
      setMsg("Select Categories");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }

    if (emp === false) {
      let data = {
        variantsname: name,
        user: userInfo._id,
        options: values,
        categories: selectedCategories,
      };

      Axios.post("/api/variants/", data)
        .then((res) => {
          let tdata = {
            _id: res.data.data._id,
            variantsname: name,
            user: userInfo._id,
            options: values,
            categories: selectedCategories,
          };
          setBufferVariants((prev) => [...prev, tdata]);
          setVariantList((prev) => [...prev, tdata]);

          setMsg("");
          setName("");
          setValues([
            {
              value: "",
            },
          ]);
          setSelectedCategories([]);
          setModalVisibility(false);
          setSuccessMsg("Variant Added!!");
          setSuccess(true);
          setTimeout(hideMessage, 3000);
        })
        .catch((err) => {
          setMsg("Variant already exists");
        });
    }
  };

  const hideMessage = () => {
    setErr(false);
    setSuccess(false);
    setErrorMsg("");
    setSuccessMsg("");
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => {
          setModalVisibility(true);
        }}
        style={{
          background: "none",
          color: "#2aa8f2",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        + Add New
      </Button>
      <Modal show={modalVisibility} onHide={addCategoryCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{"Add Variant"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {msg && <Message variant="danger">{msg}</Message>}
          <Form style={{ marginTop: "5%" }}>
            <Form.Group controlId="name">
              <Form.Label>Variant Name</Form.Label>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">{`Products have variant attribute, For ex: A T-shirt have Size and Color as variant attribute. So in this case Size and Color can variant name`}</Tooltip>
                }
              >
                <Button style={{ background: "none", color: "#2aa8f2" }}>
                  <i className="far fa-question-circle"></i>
                </Button>
              </OverlayTrigger>
              <Form.Control
                type="name"
                placeholder={"Variant Name"}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="slug">
              <Form.Label>Variant Value</Form.Label>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">{`Products also has variant attribute, For ex: A T-shirt has Size as variant attribute, so the Variant attribute value can be anything that is valid size like XL, S, M, etc.`}</Tooltip>
                }
              >
                <Button style={{ background: "none", color: "#2aa8f2" }}>
                  <i className="far fa-question-circle"></i>
                </Button>
              </OverlayTrigger>
              {values.map((e, i) => (
                <Fragment key={`${e}~${i}`}>
                  {i == 0 ? (
                    <>
                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="name"
                          style={{ width: "90%" }}
                          placeholder={"Value"}
                          value={e.value}
                          onChange={(val) => {
                            handleValueInput(val.target.value, i);
                          }}
                        ></Form.Control>
                        <Button variant={"secondary"} onClick={addFeild}>
                          <i className="fas fa-plus"></i>
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ display: "flex", marginTop: "2%" }}>
                        <Form.Control
                          type="name"
                          style={{ width: "90%" }}
                          placeholder={"Value"}
                          value={e.value}
                          onChange={(val) => {
                            handleValueInput(val.target.value, i);
                          }}
                        ></Form.Control>
                        <Button
                          variant={"secondary"}
                          onClick={() => {
                            removeField(i);
                          }}
                        >
                          <i className="fas fa-minus"></i>
                        </Button>
                      </div>
                    </>
                  )}
                </Fragment>
              ))}
            </Form.Group>

            <Form.Group controlId="name">
              <Form.Label>Categories</Form.Label>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">{`Categories that the variant is for`}</Tooltip>
                }
              >
                <Button style={{ background: "none", color: "#2aa8f2" }}>
                  <i className="far fa-question-circle"></i>
                </Button>
              </OverlayTrigger>
              <TreeSelect
                treeData={categories}
                value={selectedCategories}
                onChange={(e) => {
                  setSelectedCategories(e);
                }}
                treeCheckable={true}
                showCheckedStrategy={SHOW_ALL}
                placeholder={"Select Categories"}
                style={{
                  width: "100%",
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={addCategoryCancel}>
            Close
          </Button>

          <Button variant="primary" onClick={handleSave}>
            {"Add Variant"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddVariants;
