import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";

const ReceivedOrderSteps = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <>
      <nav className="order-tabs">
        <LinkContainer
          to={`${
            userInfo?.isAdminLogin === true && userInfo.userType === "admin"
              ? "/admin/"
              : "/"
          }ordersReceived`}
        >
          <Nav.Link>
            <span>Received Orders</span>
          </Nav.Link>
        </LinkContainer>
        <LinkContainer
          to={`${
            userInfo?.isAdminLogin === true && userInfo.userType === "admin"
              ? "/admin/"
              : "/"
          }vendorAllCancelledOrders`}
        >
          <Nav.Link>
            <span>Cancelled Orders</span>
          </Nav.Link>
        </LinkContainer>
        <LinkContainer
          to={`${
            userInfo?.isAdminLogin === true && userInfo.userType === "admin"
              ? "/admin/"
              : "/"
          }vendorAllDeliveredOrders`}
        >
          <Nav.Link>
            <span>Delivered Orders</span>
          </Nav.Link>
        </LinkContainer>
        <LinkContainer
          to={`${
            userInfo?.isAdminLogin === true && userInfo.userType === "admin"
              ? "/admin/"
              : "/"
          }approve-return-orders`}
        >
          <Nav.Link>
            <span>Return Requests</span>
          </Nav.Link>
        </LinkContainer>
        {userInfo &&
        userInfo.userType === "admin" &&
        userInfo?.isAdminLogin === true ? (
          <LinkContainer to="/admin/all-orders">
            <Nav.Link>
              <span>All Orders</span>
            </Nav.Link>
          </LinkContainer>
        ) : (
          <></>
        )}
      </nav>
    </>
  );
};

export default ReceivedOrderSteps;
