import React, { useEffect, useState } from "react";
import { Route, Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import SearchBox from "./SearchBox";
import {
  logout,
  readAllNotifications,
  readNotification,
  setNotification as notiReducer,
} from "../actions/userActions";
import Axios from "axios";
import {
  TV_URL,
  SOCIAL_URL,
  MUSIC_URL,
  EVENT_URL,
  CLASSES_URL,
} from "../urls/OtherUrls";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [topMenuBtn, TopMenuActive] = useState(false);
  const [active, setActive] = useState(false);
  const [srchToggleActive, srchToggle] = useState(false);
  const [bellToggleActive, bellToggle] = useState(false);
  const [notifications, setNotifications] = useState(() => []);
  const [unread, setUnread] = useState(() => 0);
  const [notifyLoader, setNotifyLoader] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { unNotification } = useSelector((state) => state.notification);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const categoryList = useSelector((state) => state.categoryList);

  let history = useHistory();

  const logoutHandler = () => {
    dispatch(logout({ history }));
  };

  const [categories, setCategories] = useState(() => []);
  const [allcats, setAllCats] = useState(() => []);
  const [catShow, setCatShow] = useState(() => []);

  const [isDashBoard, setIsDashBoard] = useState(() => true);

  useEffect(() => {
    setCategories(categoryList.categories);
    let data = [];
    for (let i = 0; i < categoryList.categories.length; ++i) {
      data = [
        ...data,
        {
          name: categoryList.categories[i].name,
          visible: false,
        },
      ];
    }
    setCatShow(data);
    getAllcat(categoryList.categories);
  }, [categoryList]);

  useEffect(() => {
    Axios.get(`/api/category/getall`)
      .then((res) => {
        setCategories(res.data.data);
        let data = [];
        for (let i = 0; i < res.data.data.length; ++i) {
          data = [
            ...data,
            {
              name: res.data.data[i].name,
              visible: false,
            },
          ];
        }
        setCatShow(data);
        getAllcat(res.data.data);
        // all(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // console.log(history.location.pathname);
    if (
      history.location.pathname == "/" ||
      history.location.pathname == "/dashboard"
    ) {
      setIsDashBoard(true);
    } else {
      setIsDashBoard(false);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (userInfo) {
      // setInterval(() => {
      // console.log("Notification");
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.get(`/api/miscellaneous/get-notifications/${userInfo._id}`, config)
        .then((res) => {
          dispatch(notiReducer(res.data.unread));
          console.log(res.data);
          // setUnread(res.data.unread);
          // let noti = [...notifications];
          setNotifications((prev) => [...prev, ...res.data.notifications]);

          //  setNotifications(noti)
        })
        .catch((err) => {
          console.log(err);
        });
      // }, 60000);
    }
  }, [userInfo]);

  const getAllcat = (data) => {
    for (let i = 0; i < data.length; ++i) {
      setAllCats((old) => [...old, data[i]]);
      getAllcat(data[i].children);
    }
  };

  const getChildren = (data) => {
    return (
      <>
        {data ? (
          data.map((e, i) => (
            <ul
              key={e._id}
              className={`list-unstyled ${
                e.children && e.children.length != 0 ? "subCategory" : ""
              }`}
            >
              <span>
                <li className="px10--">
                  <a
                    href="#"
                    onClick={() => {
                      categoryRedirect(e.slug, e._id);
                    }}
                  >
                    {e.children.length != 0 ? <b>{e.name}</b> : `${e.name}`}
                  </a>
                </li>
                {e.children.length != 0 ? getChildren(e.children) : <></>}
              </span>
            </ul>
          ))
        ) : (
          <></>
        )}
      </>
    );
  };

  const getChildren1 = (data, name) => {
    return (
      <>
        <ul className="list-unstyled">
          {data ? (
            data.map((e, i) => (
              <li key={i}>
                <a
                  onClick={() => {
                    categoryRedirect1(e.slug, e._id, name);
                  }}
                  style={{
                    fontWeight: e.children.length != 0 ? "bold" : "initial",
                  }}
                >
                  {e.name}
                </a>
                {e.children.length != 0 ? (
                  getChildren1(e.children, name)
                ) : (
                  <></>
                )}
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
      </>
    );
  };

  const [selected, setSelected] = useState(() => "");
  const [show, setShow] = useState(() => false);

  const [activeCat, setActiveCat] = useState(() => "");

  const categoryRedirect = (name, id) => {
    setSelected(name);
    history.push(`/category/${name}/${id}`);
    closeAll();
  };

  const categoryRedirect1 = (name, id, buff) => {
    setSelected(name);
    history.push(`/category/${name}/${id}`);
  };

  const closeAll = () => {
    document.getElementById("mega-menu-mobile").className =
      "full-width mega-menu-sec";

    if (document.getElementById(activeCat)) {
      document.getElementById(activeCat).style.display = "none";
    }
  };

  const showMenu = () => {
    document.getElementById("mega-menu-mobile").className =
      "full-width mega-menu-sec active";
  };
  const hideMenu = () => {
    document.getElementById("mega-menu-mobile").className =
      "full-width mega-menu-sec";
    if (document.getElementById(activeCat)) {
      document.getElementById(activeCat).style.display = "none";
    }
  };

  const showMegaMenu = () => {
    if (
      document.getElementById("mega-menu-id").style.display == "none" ||
      document.getElementById("mega-menu-id").style.display == ""
    ) {
      document.getElementById("mega-menu-id").style.display = "block";
    } else {
      document.getElementById("mega-menu-id").style.display = "none";
    }
  };

  const showChild = (id) => {
    if (
      document.getElementById(id) &&
      (document.getElementById(id).style.display == "none" ||
        document.getElementById(id).style.display == "")
    ) {
      document.getElementById(id).style.display = "block";
    } else {
      if (document.getElementById(id)) {
        document.getElementById(id).style.display = "none";
      }
    }

    if (id != activeCat) {
      if (document.getElementById(activeCat)) {
        document.getElementById(activeCat).style.display = "none";
      }
      setActiveCat(id);
    }
  };

  const [scrolled, setScrolled] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let x = ["main-header"];
  var mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(
    navigator.userAgent.toLowerCase()
  );
  if (!mobile) {
    if (scrolled) {
      x.push("scrolled");
      document.querySelector("body").style.paddingTop = "140px";
    } else {
      document.querySelector("body").style.paddingTop = "0";
    }
  }

  const handleLinkClick = (link, platform) => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.post(`/api/users/tv-login`, {}, config)
        .then((res) => {
          // if (platform == "tv") {
          //   window.location.href = `${link}/index.php?link1=external_auth&authId=${res.data.authKey}`;
          // }

          if (res.data.isLoggedIn) {
            if (platform == "tv") {
              window.location.href = `${link}/index.php?link1=external_auth&authId=${res.data.authKey}`;
            }
            if (platform == "music") {
              window.location.href = `${link}/externalLogin?authId=${res.data.authKey}`;
            } else if (platform == "social") {
              window.location.href = `${link}/user/auth/login?authId=${res.data.authKey}`;
            } else if (platform == "event") {
              window.location.href = `${link}/Auth/${res.data.authKey}`;
            } else if (platform == "classes") {
              window.location.href = `${link}/auth/${res.data.authKey}`;
            }
          } else {
            window.location.href = `${link}`;
          }
        })
        .catch((err) => {});
    } else {
      window.location.href = `/login`;
    }
  };

  const redirectToPeople = () => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.post(`/api/users/tv-login`, {}, config)
        .then((res) => {
          window.location.href = `${SOCIAL_URL}/user/auth/login?authId=${res.data.authKey}&redirect=people`;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " year ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " month(s) ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " day(s) ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hrs ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minute ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  const handleReadAllNotifications = () => {
    setNotifyLoader(!notifyLoader);
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.put(`/api/miscellaneous/notifications/mark-all-read`, {}, config)
        .then((res) => {
          dispatch(readAllNotifications());
          setNotifyLoader(!notifyLoader);

          // console.log(res.data.message);
        })
        .catch((err) => {
          setNotifyLoader(!notifyLoader);
          console.log(err);
        });
    }
    bellToggle(false);
  };

  return (
    <header
      className={x.join(" ")}
      style={{
        display: location.pathname === "/admin/login" ? "none" : "block",
      }}
    >
      <div className="navbar-expand-lg logo-wraper">
        <div className="topBar">
          <div className="container">
            <Row>
              <Col xs={3} sm={4} md={4} lg={7} className="m-burgerWraper">
                <button
                  className={
                    topMenuBtn ? "navbar-toggler active" : "navbar-toggler"
                  }
                  type="button"
                  onClick={() => TopMenuActive(!topMenuBtn)}
                >
                  <div className="burger">
                    <span className="menu_toggle">
                      <span className="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>
                      <span className="hamburger-cross">
                        <span></span>
                        <span></span>
                      </span>
                    </span>
                  </div>
                </button>
                <div className={topMenuBtn ? "topNav active" : "topNav"}>
                  <div
                    className="navbar-mobile-back navbar-toggler"
                    onClick={() => TopMenuActive(!topMenuBtn)}
                  >
                    <span className="menu-back">
                      <i className="las la-angle-left"></i> back
                    </span>
                  </div>
                  <ul>
                    <li className="nav-item">
                      <Link
                        to="/"
                        className={isDashBoard ? `active` : ``}
                        onClick={() => {
                          setIsDashBoard(true);
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        onClick={() => {
                          handleLinkClick(SOCIAL_URL, "social");
                        }}
                      >
                        Social
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/merch"
                        className={isDashBoard ? `` : `active`}
                        onClick={() => {
                          setIsDashBoard(false);
                        }}
                      >
                        Merch
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        onClick={() => {
                          handleLinkClick(MUSIC_URL, "music");
                        }}
                      >
                        Music
                      </a>
                    </li>

                    <li className="nav-item">
                      {userInfo ? (
                        <a
                          href="#"
                          onClick={() => {
                            handleLinkClick(TV_URL, "tv");
                          }}
                        >
                          Danzflo TV
                        </a>
                      ) : (
                        <a href={TV_URL}>Danzflo TV</a>
                      )}
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        onClick={() => {
                          handleLinkClick(EVENT_URL, "event");
                        }}
                      >
                        Event
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        onClick={() => {
                          handleLinkClick(CLASSES_URL, "classes");
                        }}
                      >
                        Classes {process.env.REACT_APP_BETA}
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={9} sm={8} md={8} lg={5}>
                <div className="top-rightbar">
                  <div className="socials-header">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/DanzFlo-112915663809273"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-square">facebook</i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="myaccount-links">
                    <div className="language">
                      <span style={{ color: "white" }}>
                        <img src="/assets/img/us_flag.jpg" alt="EN" /> ENG
                      </span>
                    </div>
                  </div>
                  <div className="support-link">
                    <a href="https://danzflo.com/support/" target="_blank">
                      <i class="far fa-envelope"></i> Support
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Container>
          <Row>
            <Col xs={4} lg={3} className="middleLogoWraper">
              <div className="header-logo">
                <LinkContainer to="/" className="previous">
                  <Nav.Link>
                    <img src="/assets/img/logo.png" />
                  </Nav.Link>
                </LinkContainer>
              </div>
            </Col>
            <Col xs={8} lg={9} className="middleNotifications">
              <div className="menu-notifications-icon">
                <div className="header-srch">
                  <a
                    className={
                      srchToggleActive ? "srch-toggle is-active" : "srch-toggle"
                    }
                    onClick={() => srchToggle(!srchToggleActive)}
                  >
                    <i className="las la-search"></i>
                  </a>
                  <div
                    className={
                      srchToggleActive
                        ? "search-form animated bounceIn is-active"
                        : "search-form animated bounceIn"
                    }
                  >
                    <Route
                      render={({ history }) => <SearchBox history={history} />}
                    />
                  </div>
                </div>
                <div className="menu-icons">
                  <div className="bell-dropdown">
                    <a
                      className={bellToggleActive ? "bell is-active" : "bell"}
                      onClick={() => bellToggle(!active)}
                    >
                      <i className="far fa-bell"></i>
                      <span className="count">
                        {unNotification ? unNotification : 0}
                      </span>
                    </a>
                    <div
                      className={
                        bellToggleActive
                          ? "dropdown-content animated bounceIn is-active"
                          : "dropdown-content animated bounceIn"
                      }
                    >
                      <div className="bell-popup-content">
                        <div className="heading">
                          <h4>Notifications</h4>
                          <p>
                            <a
                              className={
                                bellToggleActive
                                  ? "box-close is-active"
                                  : "box-close"
                              }
                              onClick={() => bellToggle(active)}
                            >
                              <i className="las la-times"></i>
                            </a>
                          </p>
                        </div>
                        <div className="box-content">
                          {notifications.length != 0 ? (
                            notifications.slice(0, 2).map(
                              (e, i) =>
                                e.isRead === false && (
                                  <div className="media-box" key={i}>
                                    <div className="media">
                                      <img
                                        src={e.image}
                                        className="mr-3"
                                        alt="img"
                                        style={{ width: "25%" }}
                                      />
                                      <div className="media-body">
                                        <h5 className="mt-0">
                                          {e.notification}
                                        </h5>
                                        <p>
                                          <span>
                                            {timeSince(new Date(e.createdAt))}
                                          </span>
                                        </p>
                                        {/* <p><span>{
                                    (new Date(e.createdAt)).getHours()<24 ? `${(new Date(e.createdAt)).getHours()} hrs ago` : (e.createdAt.split("T")[0].split('-')[2]+"-"+e.createdAt.split("T")[0].split('-')[1]+"-"+e.createdAt.split("T")[0].split('-')[0])
                                    }</span></p> */}
                                      </div>
                                    </div>
                                  </div>
                                )
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          style={{
                            width: "100%",
                          }}
                          className="box-footer d-flex justify-content-around align-items-center"
                        >
                          <Link
                            onClick={() => {
                              bellToggle(false);
                            }}
                            to="/notifications"
                          >
                            View All Notifications
                          </Link>
                          <button
                            style={{
                              fontWeight: "bold",
                              fontSize: "12px",
                              // line-height: 22px,
                              color: "#2aa8f2",
                              background: "transparent",
                              border: "none",
                              // letter-spacing: -0.15912px,
                            }}
                            onClick={() => {
                              handleReadAllNotifications();
                            }}
                          >
                            Mark all read
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <LinkContainer to="/cart">
                    <Nav.Link>
                      <i className="fas fa-shopping-cart"></i>
                      <span className="count">{cartItems.length}</span>
                    </Nav.Link>
                  </LinkContainer>
                  <Nav className="ml-auto">
                    {userInfo ? (
                      <NavDropdown title={userInfo.name} id="username">
                        {/* <LinkContainer to={`/${userInfo.userName}/store/${userInfo._id}`}>
                          <NavDropdown.Item>Profile</NavDropdown.Item>
                        </LinkContainer> */}

                        <LinkContainer to="/profile">
                          <NavDropdown.Item>Profile</NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer to="#" onClick={redirectToPeople}>
                          <NavDropdown.Item>People</NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer to="/orders">
                          <NavDropdown.Item>Orders</NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer to="/wishlist">
                          <NavDropdown.Item>Wishlist</NavDropdown.Item>
                        </LinkContainer>

                        <NavDropdown.Item onClick={logoutHandler}>
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    ) : (
                      <LinkContainer to="/login">
                        <Nav.Link style={{ color: "#1890ff" }}>
                          <i className="fas fa-user"></i> Sign In
                        </Nav.Link>
                      </LinkContainer>
                    )}
                  </Nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {location?.pathname !== "/" && (
        <div className="mainSubCategory full-width">
          <div className="container">
            <div className="full-width position-relative">
              <button
                className="product-toggler"
                type="button"
                onClick={showMenu}
              >
                <div className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p>Product categories</p>
              </button>
              <div id={`mega-menu-mobile`} className="full-width mega-menu-sec">
                <div className="mobile-back-button" onClick={hideMenu}>
                  <span className="menu-back">
                    <i className="las la-angle-left"></i> back
                  </span>
                </div>
                <ul>
                  <li>
                    <a href="#">
                      All Categories{" "}
                      <span className="down-arrow" onClick={showMegaMenu}>
                        <i className="fas fa-caret-down"></i>
                      </span>
                    </a>
                    <ul className="mega-menu" id="mega-menu-id">
                      <div className="category-name">
                        {categories.map((e) => (
                          <li key={e._id}>
                            <a
                              href="#"
                              onClick={() => {
                                categoryRedirect(e.slug, e._id);
                              }}
                              onMouseOver={() => {
                                showChild(e._id);
                              }}
                            >
                              {e.name}
                            </a>
                            <span
                              className="right-arrow"
                              onClick={() => {
                                showChild(e._id);
                              }}
                            >
                              <i className="las la-angle-right"></i>
                            </span>
                            {e.children.length != 0 ? (
                              <ul
                                className="mega-category-link"
                                id={e._id}
                                style={{ display: "none" }}
                              >
                                <div className="row">
                                  {e.children.length != 0 ? (
                                    e.children.map((e1) => (
                                      <div
                                        className="col-xl-3 col-lg-4"
                                        key={Math.random()}
                                      >
                                        <div className="full-width">
                                          <li>
                                            <a
                                              href="#"
                                              onClick={() => {
                                                categoryRedirect(
                                                  e1.slug,
                                                  e1._id
                                                );
                                              }}
                                            >
                                              <b>{e1.name}</b>
                                            </a>
                                          </li>
                                          {e1.children.length != 0 ? (
                                            getChildren(e1.children)
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </ul>
                            ) : (
                              <></>
                            )}
                          </li>
                        ))}
                      </div>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
