import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import { MUSIC_URL, TV_URL } from "../../urls/OtherUrls";
import DashboardCard from "../../components/admin/dashboard/DashboardCard";
import DashboardChart from "../../components/admin/dashboard/DashboardChart";

const Dashboard = () => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [data, setData] = React.useState({});
  const [chartData, setChartData] = React.useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    if (userInfo) {
      getDetails();
    }
  }, []);

  const getDetails = async () => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const res = await Axios.get("/api/analytics", config);
      if (res.status === 200) {
        setData(res.data);
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        let event = {
          label: "Event Earnings",
          data: res?.data?.event?.eventData,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        };
        let music = {
          label: "Music Earnings",
          data: res?.data?.music?.musicData,
          borderColor: " rgba(152, 130, 73)",
          backgroundColor: " rgba(152, 130, 73, 0.5)",
        };
        let merch = {
          label: "Merch Earnings",
          data: res?.data?.merch?.merchData,
          borderColor: "rgba(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        };
        setChartData({
          labels: months,
          datasets: [event, music, merch],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* <section class="page-wrapper"> */}
      <section class="wrapper-body">
        <div class="container-fluid">
          <div class="d-flex justify-content-between">
            <DashboardCard
              title="Total"
              amount={parseFloat(data.total || 0).toFixed(2)}
              backgroundColor="rgb(0,206,252)"
              colorColor="white"
              subTitle1="Commission"
              subTitle2="Sale"
              subData2={parseFloat(data.totalSale || 0).toFixed(2)}
            />
            <DashboardCard
              title="Merch"
              amount={parseFloat(data.merch?.total || 0).toFixed(2)}
              subTitle1="Commission"
              subTitle2="Sale"
              subData2={parseFloat(data?.merch?.totalSale || 0).toFixed(2)}
              backgroundColor="rgba(255, 99, 132, 0.5)"
              colorColor="white"
            />
            <DashboardCard
              title="event"
              subTitle1="Commission"
              subTitle2="Sale"
              subData2={parseFloat(data?.event?.totalSale || 0).toFixed(2)}
              amount={parseFloat(data.event?.total || 0).toFixed(2)}
              backgroundColor="rgba(53, 162, 235, 0.5)"
              colorColor="white"
            />
            <DashboardCard
              title="music"
              subTitle1="Commission"
              subTitle2="Sale"
              subData2={parseFloat(data?.music?.totalSale || 0).toFixed(2)}
              amount={parseFloat(data.music?.totalRevenue || 0).toFixed(2)}
              backgroundColor="rgb(152,130,73, 0.5)"
              colorColor="white"
            />
          </div>
          <div className="my-4">
            <DashboardChart
              data={
                chartData.datasets.length > 0 ? chartData : { datasets: [] }
              }
            />
          </div>
        </div>
      </section>
      {/* </section> */}
    </div>
  );
};

export default Dashboard;
