import React, { useEffect, useState } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateVendor } from "../actions/userActions";
import Axios from "axios";
import Loader from "../components/Loader";

const StripeConnectSuccess = ({ match }) => {
  let dispatch = useDispatch();

  const url = match.params.url;
  const isMusic = match.params.isMusic;
  const isEvent = match.params.isEvent;
  const isClasses = match.params.isClasses;
  const isTv = match.params.isTv;


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [registered, setRegistered] = useState(() => true);
  const [loading, setLoading] = useState(() => true);

  useEffect(() => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.put(
        `/api/users/register-vendor`,
        {
          type:
            isMusic === "true"
              ? "music"
              : isClasses === "true"
              ? "classes"
              : isEvent === "true"
              ? "event": isTv === "true"?"tv"
              : "merch",
        },
        config
      )
        .then((res) => {
          if (res.data.vendor) {
            setRegistered(true);
            setLoading(false);
            if (
              isMusic === "true" ||
              isEvent === "true" ||
              isClasses === "true"||
              isTv==="true"
            ) {
              let buff = new Buffer(url, "base64");
              let base64ToStringNew = buff.toString("ascii");
              // window.location.href = base64ToStringNew; //***Important */
            } else {
              dispatch(updateVendor());
            }
          } else {
            setRegistered(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setRegistered(false);
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Result
          status={registered ? "success" : "warning"}
          title={
            registered
              ? "Congratulations you have successfully register as a Seller"
              : "Your account has been registered but you need to fill other details to get registered as vendor."
          }
          subTitle={
            registered
              ? "Thank you your onboarding process is completed."
              : "Onboarding process incomplete."
          }
          extra={[
            <Link type="primary" to="/profile" key="console">
              <Button type="primary" key="console">
                Go to Dashboad
              </Button>
            </Link>,
          ]}
        />
      )}
    </>
  );
};
export default StripeConnectSuccess;
