import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Axios from "axios";
import Product from "../components/Product";
import { Empty } from "antd";
import { TV_URL, SOCIAL_URL, MUSIC_URL } from "../urls/OtherUrls";
import { useDispatch, useSelector } from "react-redux";
import VendorSidebar from "./VendorSidebar";

const VendorScreen = ({ match, location, history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo: UI } = userLogin;

  const keyword = match.params.keyword ? match.params.keyword : "";
  const vendorId = match.params.vendorId;
  const categoryId = match.params.categoryId;
  const name = match.params.name;
  const pageNumber = match.params.pageNumber || 1;

  //category

  const [loading, setLoading] = useState(() => true);
  const [noti, setNoti] = useState(() => "");
  const [page, setPage] = useState(() => 0);
  const [pages, setPages] = useState(() => 0);

  const [profile, setProfile] = useState(() => false);

  const [wishlist, setWishlist] = useState(() => []);

  const [userInfo, setUserInfo] = useState(() => {});
  const [categories, setCategories] = useState(() => []);
  const [active, setActive] = useState(() => "");
  const [followings, setFollowings] = useState(() => {
    return { follower: 0, following: 0 };
  });

  useEffect(() => {
    console.log(categoryId);
    setLoading(true);
    // if
    if (vendorId) {
      if (categoryId) {
        Axios.get(
          `/api/products/vendor-get-products-category/${vendorId}/${categoryId}`
        )
          .then((res) => {
            console.log(res.data);
            setUserInfo(res.data.vendor);
            setCategories(res.data.products);
            if (UI) {
              if (UI._id == res.data.vendor._id) {
                setProfile(true);
              }
            }
            if (res.data.data.length != 0) {
              setActive(res.data.data[0].categoryId);
            }
            setLoading(false);
            console.log(res.data);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        Axios.get(`/api/products/vendor-get-products-category/${vendorId}`)
          .then((res) => {
            console.log(res.data);
            setUserInfo(res.data.vendor);
            setCategories(res.data.products);
            if (UI) {
              if (UI._id == res.data.vendor._id) {
                setProfile(true);
              }
            }
            if (res.data.data.length != 0) {
              setActive(res.data.data[0].categoryId);
            }
            setLoading(false);
            console.log(res.data);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  }, [vendorId, categoryId]);

  useEffect(() => {
    if (UI) {
      let config = {
        headers: {
          Authorization: `Bearer ${UI.token}`,
        },
      };
      Axios.get(`/api/users/get-followers`, config)
        .then((res) => {
          console.log(res.data);
          setFollowings(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [UI]);

  return (
    <section className="accountMain-wraper">
      <Container>
        <Row>
          <Col md={12}>{/* <h1 className="main-heading"> </h1> */}</Col>
        </Row>
        <Row>
          <>
            <VendorSidebar match={match} profile={profile} />
          </>
          <Col md={12} lg={9} xl={9}>
            <div className="paymentMethod-main">
              <div className="products-listing">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {/* {noti? <Message>{noti}</Message>: <></>} */}
                    <div className="products products-grid mgs-products">
                      <div className="mgs-productsWraper">
                        {/* {
                  categories && categories.length != 0?
                  categories.map((val)=>(
                    <div style={{display: val.categoryId == active?'initial':'none'}}>
                    <Row  key={Math.random()}> */}
                        <Row>
                          {categories.length != 0 ? (
                            categories.map((product) => (
                              <Col
                                key={product._id}
                                className="col-xl-4 col-lg-6 col-md-6 col-sm-12"
                              >
                                <Product
                                  setNoti={setNoti}
                                  wishlist={wishlist}
                                  setWishlist={setWishlist}
                                  userInfo={userInfo}
                                  product={product}
                                  vendor={true}
                                />
                              </Col>
                            ))
                          ) : (
                            <div style={{ margin: "auto" }}>
                              <Empty
                                image="/assets/img/logo.png"
                                description={
                                  <span style={{ fontWeight: "bold" }}>
                                    No Product(s) to display
                                  </span>
                                }
                              ></Empty>
                            </div>
                          )}
                        </Row>
                        {/* </Row>
                </div>
                  ))
                  :
                  <div style={{margin: 'auto'}}>
                  <Empty
                      image="/assets/img/logo.png"
                      description={
                        <span style={{fontWeight: 'bold'}}>
                          No Product(s) to display
                        </span>
                      }
                    ></Empty>
                    </div>
                } */}
                      </div>
                    </div>
                    {/*                 
                <Paginate
                  pages={pages}
                  page={page}
                  keyword={keyword ? keyword : ''}
                /> */}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
// const ProfileScreen=[]
export default VendorScreen;
