import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import {
  TV_URL,
  SOCIAL_URL,
  MUSIC_URL,
  EVENT_URL,
  CLASSES_URL,
} from "../urls/OtherUrls";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { updateCoverPic, updateProfilePic } from "../actions/userActions";
import Message from "../components/Message";
import axios from "axios";
import Loader from "../components/Loader";
import { Modal, Button, Row, Col, Image, Form } from "react-bootstrap";
import VendorDrawerForm from "../components/vendorDrowerForm";

const VendorSidebar = ({
  match,
  profile = false,
  home = false,
  category = false,
}) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [categories, setCategories] = useState(() => []);
  const [active, setActive] = useState(() => "");
  const [loading, setLoading] = useState(() => true);
  const [activeMenu, setActiveMenu] = useState(() => true);
  const [followings, setFollowings] = useState(() => {
    return { follower: 0, following: 0 };
  });

  const [show, setShow] = useState(() => false);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(() => "");
  const [src, setSrc] = useState(() =>
    userInfo
      ? userInfo.profilePic
        ? userInfo.profilePic
        : "/assets/img/logo.png"
      : "/assets/img/logo.png"
  );
  const [coverSrc, setCoverSrc] = useState(() =>
    userInfo
      ? userInfo.coverPic
        ? userInfo.coverPic
        : "/img/login-bg-1.png"
      : "/img/login-bg-1.png"
  );

  const [imgType, setImgType] = useState(() => "");

  const [info, setInfo] = useState(() => {
    return {};
  });

  const [error, setError] = useState(() => false);
  const hideModal = () => {
    setSrc(() =>
      userInfo
        ? userInfo.profilePic
          ? userInfo.profilePic
          : "/assets/img/logo.png"
        : "/assets/img/logo.png"
    );
    setCoverSrc(() =>
      userInfo
        ? userInfo.coverPic
          ? userInfo.coverPic
          : "/img/login-bg-1.png"
        : "/img/login-bg-1.png"
    );
    setImage(null);
    setImageName("");
    setShow(false);
  };

  useEffect(() => {
    if (match) {
      Axios.get(
        `/api/products/vendor-get-products-category/${match.params.vendorId}`
      )
        .then((res) => {
          setCategories(res.data.data);
          if (res.data.data.length != 0) {
            setActive(res.data.data[0].categoryId);
          }
          setInfo(res.data.vendor);
          setLoading(false);
        })
        .catch((err) => {});

      Axios.get(`/api/users/get-followers/${match.params.vendorId}`)
        .then((res) => {
          setFollowings(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (userInfo) {
      Axios.get(`/api/products/vendor-get-products-category/${userInfo._id}`)
        .then((res) => {
          setCategories(res.data.data);
          if (res.data.data.length != 0) {
            setActive(res.data.data[0].categoryId);
          }

          setInfo(res.data.vendor);

          setLoading(false);
          console.log(res.data);
        })
        .catch((err) => {});
      Axios.get(`/api/users/get-followers/${userInfo._id}`)
        .then((res) => {
          setFollowings(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleLinkClick = (link, platform, redirect = false) => {
    if (userInfo) {
      let config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      Axios.post(`/api/users/tv-login`, {}, config)
        .then((res) => {
          if (res.data.isLoggedIn) {
            if (platform == "tv") {
              if (match) {
                window.location.href = `${link}/index.php?link1=external_auth&authId=${res.data.authKey}&redirect=${info.userName}`;
              } else if (redirect) {
                window.location.href = `${link}/index.php?link1=external_auth&authId=${res.data.authKey}&redirect=${userInfo.userName}`;
              } else {
                window.location.href = `${link}/index.php?link1=external_auth&authId=${res.data.authKey}`;
              }
            } else if (platform == "music") {
              if (match) {
                window.location.href = `${link}/externalLogin?authId=${res.data.authKey}&redirect=${info.userName}`;
              } else if (redirect) {
                window.location.href = `${link}/externalLogin?authId=${res.data.authKey}&redirect=${userInfo.userName}`;
              } else {
                window.location.href = `${link}/externalLogin?authId=${res.data.authKey}`;
              }
            } else if (platform == "social") {
              if (match) {
                window.location.href = `${link}/user/auth/login?authId=${res.data.authKey}&redirect=${info.userName}`;
              } else if (redirect) {
                window.location.href = `${link}/user/auth/login?authId=${res.data.authKey}&redirect=${userInfo.userName}`;
              } else {
                window.location.href = `${link}/user/auth/login?authId=${res.data.authKey}`;
              }
            } else if (platform == "event") {
              if (match) {
                window.location.href = `${link}/Auth/${res.data.authKey}?userName=${info.userName}`;
              } else if (redirect) {
                window.location.href = `${link}/Auth/${res.data.authKey}`;
              } else {
                window.location.href = `${link}/Auth/${res.data.authKey}`;
              }
              // window.location.href = `${link}/Auth/${res.data.authKey}`;
            } else if (platform == "class") {
              if (match) {
                window.location.href = `${link}/auth/${res.data.authKey}?userName=${info.userName}`;
              } else if (redirect) {
                window.location.href = `${link}/auth/${res.data.authKey}?profile=ture`;
              } else {
                window.location.href = `${link}/auth/${res.data.authKey}`;
              }
              // window.location.href = `${link}/Auth/${res.data.authKey}`;
            }
          } else {
            window.location.href = `${link}`;
          }
        })
        .catch((err) => {});
    } else {
      window.location.href = `${link}/index.php?link1=external_auth`;
    }
  };

  const uploadHandler = async (e) => {
    e.preventDefault();
    if (image) {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", image);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await axios.post(
          "/api/resize-image/200/profile",
          formData,
          config
        );
        //   const body = {
        //       url : data
        //   }
        const config1 = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        if (imgType == "profile") {
          let response = await axios.put(
            `/api/users/update-profile-pic/${userInfo._id}/profile`,
            { url: data },
            config1
          );
          dispatch(updateProfilePic(data));
        } else {
          let response = await axios.put(
            `/api/users/update-profile-pic/${userInfo._id}/cover`,
            { url: data },
            config1
          );
          dispatch(updateCoverPic(data));
        }

        setLoading(false);
        setImage(null);
        setImageName("");
        setShow(false);
      } catch (err) {
        setLoading(false);
        setImage(null);
        setImageName("");
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } else {
      setLoading(false);
      setShow(false);
    }
  };
  const handleError = (type) => {
    if (type == "profile") {
      let buff = { ...info };
      buff.profilePic = `/assets/img/logo.png`;
      setInfo(buff);
    } else if (type == "cover") {
      let buff = { ...info };
      buff.coverPic = `/img/home-img-4.jpg`;
      setInfo(buff);
    }
  };
  console.log(info, ";;;;;;;;;;;;;;;;;;");
  return (
    <div className={category ? "" : "col-md-12 col-lg-3 col-xl-3"}>
      <div className="account-sidebarLeft">
        {userInfo && (
          <div className="card-wraper userProfile">
            <div className="card-bg">
              <div className="bg-img">
                <img
                  src={
                    info.coverPic
                      ? info.coverPic + `?m=${new Date().getTime()}`
                      : `/img/login-bg-1.png`
                  }
                  alt="img"
                  onError={() => {
                    handleError("cover");
                  }}
                  loading={"lazy"}
                />
                {/* <div className='bg-edit'>
                                    <a href='#' onClick={()=>{showModal('cover')}}>
                                        <i className="fas fa-cloud-upload-alt"></i>
                                    </a>
                                </div> */}
              </div>
              <div className="card-user-wraper">
                <div className="card-user">
                  <div className="user-img-wraper">
                    <div className="user-img">
                      <img
                        src={`${
                          process.env.REACT_APP_SOCIAL_URL
                        }/uploads/profile_image/${
                          info.guid
                        }.jpg?m=${new Date().getTime()}`}
                        alt="img"
                        onError={() => {
                          handleError("profile");
                        }}
                        loading={"lazy"}
                      />
                    </div>
                    {/* <div className="edit-user-img">
                                            <a href="#" onClick={()=>{showModal('profile')}} ><i className="las la-edit"></i></a>
                                        </div> */}
                  </div>
                </div>
                <div className="user-detail">
                  <h4>{info ? info.name : ""}</h4>
                  {/* <p>{info?info.userName:''}</p> */}
                </div>
              </div>
            </div>
            <div className="card-followers">
              <ul>
                <li>
                  <h4>Followers</h4>
                  <p>{followings.follower}</p>
                </li>
                <li>
                  <h4>Following</h4>
                  <p>{followings.following}</p>
                </li>
              </ul>
            </div>
            <div className="box-footer">
              <ul>
                {/* <li>
                                    <a href="#"  onClick={()=>{handleLinkClick(SOCIAL_URL, 'social', true)}}><i className="las la-user"></i> Social Profile</a>
                                </li> */}
                {/* <li>
                                    <Link to="/dashboard">
                                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21 10H17L14 19L8 1L5 10H1" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round" />
                                        </svg>
                                        Dashboard
                                    </Link>
                                </li> */}
              </ul>
            </div>
          </div>
        )}

        <div className="sidebar-accountMenu">
          <div className="sidebar-filter-options">
            <h4>
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 1L1 5V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V5L16 1H4Z"
                  stroke="#2AA8F2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M1 5H19"
                  stroke="#2AA8F2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M14 9C14 10.0609 13.5786 11.0783 12.8284 11.8284C12.0783 12.5786 11.0609 13 10 13C8.93913 13 7.92172 12.5786 7.17157 11.8284C6.42143 11.0783 6 10.0609 6 9"
                  stroke="#2AA8F2"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <span>DanzFlo</span>
            </h4>
            <ul className="nav-filter">
              <li className="has-submenu">
                <Link
                  to={
                    match
                      ? `/${match.params.name}/store/${match.params.vendorId}`
                      : userInfo
                      ? "/merch"
                      : // `/${userInfo.name}/store/${userInfo._id}`
                        ""
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15.15 11.12"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M3.47,4.66,2.33,5.81,0,3.47l.66-.66L3.15.32A1.08,1.08,0,0,1,3.93,0H5.18a.1.1,0,0,1,.11.07A2.43,2.43,0,0,0,7.68,1.59,2.39,2.39,0,0,0,9.85.1.15.15,0,0,1,10,0h1.25A1,1,0,0,1,12,.29c1,1.05,2.1,2.11,3.16,3.16l0,0L12.83,5.8,11.68,4.64v6.48H3.47Z"></path>
                      </g>
                    </g>
                  </svg>
                  Merch
                </Link>
                <ul className={activeMenu ? "active" : ""}>
                  {categories.length != 0 && !home ? (
                    categories.map((e) => (
                      <li key={e.categoryId}>
                        <Link
                          to={
                            match
                              ? `/${match.params.name}/store/${
                                  match.params.vendorId
                                }/${e.category.replaceAll(" ", "-")}/${
                                  e.categoryId
                                }`
                              : userInfo
                              ? `/${userInfo.name}/store/${
                                  userInfo._id
                                }/${e.category.replaceAll(" ", "-")}/${
                                  e.categoryId
                                }`
                              : ""
                          }
                        >
                          <img
                            style={{ height: "16px", width: "16px" }}
                            src={`/assets/img/category.svg`}
                            alt="SVG"
                          />
                          <span>{e.category}</span>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                  {profile && <Sidebar />}
                  {userInfo && profile && (
                    <li>
                      <VendorDrawerForm case={true} />
                    </li>
                  )}
                </ul>

                {!home && (
                  <div
                    className={activeMenu ? "arrow-down active" : "arrow-down "}
                    onClick={() => {
                      setActiveMenu(!activeMenu);
                    }}
                  >
                    <i className="fa fa-angle-right"></i>
                  </div>
                )}
              </li>

              <li>
                <a
                  href="#"
                  onClick={() => {
                    handleLinkClick(MUSIC_URL, "music", true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 11.07 12.17"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M3.5,8.62V8.08c0-1.61,0-3.22,0-4.83a1.6,1.6,0,0,1,1.16-1.6Q7.24.84,9.82.07c.78-.23,1.24.12,1.24.93,0,2.5,0,5,0,7.49a1.85,1.85,0,0,1-.63,1.43,2.75,2.75,0,0,1-2.67.71,1.48,1.48,0,0,1-.89-2.4,2.86,2.86,0,0,1,2.9-1.07l.35.09V6.68c0-1,0-2,0-3,0-.47-.14-.58-.58-.44L5.07,4.61a.84.84,0,0,0-.65.91c0,1.44,0,2.88,0,4.32a2.09,2.09,0,0,1-1.23,2,2.49,2.49,0,0,1-2.58,0A1.42,1.42,0,0,1,.38,9.64,2.9,2.9,0,0,1,3.5,8.62Z"></path>
                      </g>
                    </g>
                  </svg>
                  <span>{`Music  `}</span>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  onClick={() => {
                    handleLinkClick(TV_URL, "tv", true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 13.29 14.17"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M5.66,2.8l-.47-.49-1-1.08A.26.26,0,0,0,4,1.16.59.59,0,0,1,3.4.53.57.57,0,0,1,4.06,0a.56.56,0,0,1,.48.68.17.17,0,0,0,0,.19L6.13,2.5a.18.18,0,0,0,.18.06c.23,0,.45,0,.68,0a.25.25,0,0,0,.15-.05C7.67,2,8.19,1.42,8.71.88c.07-.07,0-.13,0-.2A.57.57,0,0,1,9.87.43a.58.58,0,0,1-.4.7l-.06,0c-.19,0-.28.08-.38.19L7.72,2.71l-.08.09a2.07,2.07,0,0,1,.69.75.23.23,0,0,0,.15.05H11a2.21,2.21,0,0,1,2.27,2.27V11a2.22,2.22,0,0,1-1.87,2.23c-.13,0-.27,0-.4,0s-.13,0-.15.1-.14.35-.2.53a.34.34,0,0,1-.33.25A.34.34,0,0,1,10,14c-.1-.19-.21-.38-.3-.57a.16.16,0,0,0-.16-.1H3.75a.17.17,0,0,0-.16.09c-.09.2-.2.38-.3.58a.35.35,0,0,1-.35.21.34.34,0,0,1-.32-.25l-.19-.51a.16.16,0,0,0-.18-.12,2.21,2.21,0,0,1-1.89-1A2.08,2.08,0,0,1,0,11.09V5.81A2.23,2.23,0,0,1,2.21,3.6h2.6A.15.15,0,0,0,5,3.49,2,2,0,0,1,5.66,2.8ZM6,4.48H2.68A1.77,1.77,0,0,0,.89,6.26v4.36a1.78,1.78,0,0,0,1.79,1.79H9.35a1.76,1.76,0,0,0,1.78-1.79V6.3A1.75,1.75,0,0,0,9.32,4.48ZM11.75,6.6a.47.47,0,0,0,.47.48.48.48,0,1,0-.47-.48Zm.48,2.3a.48.48,0,0,0,.48-.48.49.49,0,0,0-.48-.49.49.49,0,0,0,0,1Zm0,1.76a.48.48,0,0,0,0-1,.47.47,0,0,0-.49.47A.48.48,0,0,0,12.22,10.66Z"></path>
                      </g>
                    </g>
                  </svg>
                  <span>{`TV`}</span>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  onClick={() => {
                    handleLinkClick(SOCIAL_URL, "social", true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12.85 12.85"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M11.81,9.91a1.35,1.35,0,0,0-.88-.74.85.85,0,0,0-.23,0H9.1a3.48,3.48,0,0,0-.43-1.66l.41-.24a1.06,1.06,0,1,1,1.21-1.72,1,1,0,0,1,.34.92,1,1,0,0,1-.56.81,1.86,1.86,0,0,1,1.09,1.2A5.07,5.07,0,0,0,10,2.67a5.14,5.14,0,0,0-6.92-.18,5.07,5.07,0,0,0-1.4,5.88A1.85,1.85,0,0,1,2.71,7.25,1.06,1.06,0,1,1,4,5.6a1.06,1.06,0,0,1-.28,1.64l.41.23a3.64,3.64,0,0,0-.43,1.67H2.2a1,1,0,0,0-.81.37c-.12.14-.22.3-.32.44a6.42,6.42,0,1,1,10.74,0Z"></path>
                        <path d="M8.73,9.13H4.06A2.76,2.76,0,0,1,5.7,6.52a1.46,1.46,0,1,1,1.39,0A2.78,2.78,0,0,1,8.73,9.13Z"></path>
                        <path d="M8.51,11.22a.62.62,0,1,1-.62.62A.6.6,0,0,1,8.51,11.22Z"></path>
                        <path d="M4.38,12.47a.63.63,0,0,1-.63-.62.61.61,0,0,1,.62-.62.6.6,0,0,1,.63.61A.61.61,0,0,1,4.38,12.47Z"></path>
                        <path d="M6.44,11.61a.62.62,0,0,1,.63.62.63.63,0,0,1-.63.62.62.62,0,1,1,0-1.24Z"></path>
                        <path d="M1.69,10.5a.6.6,0,0,1,.61-.61.61.61,0,0,1,.63.63.6.6,0,0,1-.62.61A.6.6,0,0,1,1.69,10.5Z"></path>
                        <path d="M11.2,10.45a.62.62,0,0,1-.62.63.61.61,0,0,1-.61-.63.62.62,0,0,1,1.23,0Z"></path>
                      </g>
                    </g>
                  </svg>
                  <span>{`Social`}</span>
                </a>
              </li>

              <li>
                <a
                  href="#"
                  onClick={() => {
                    handleLinkClick(EVENT_URL, "event", true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14.28 14.46"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M2.69,1.94c0,.62,0,1.2,0,1.79a1.46,1.46,0,1,0,2.92,0c0-.55,0-1.1,0-1.65a1.17,1.17,0,0,1,0-.15H8.8c0,.61,0,1.19,0,1.77a1.49,1.49,0,0,0,1.49,1.61,1.46,1.46,0,0,0,1.43-1.6c0-.58,0-1.16,0-1.73a2,2,0,0,1,2.52,1.85c.06,2.89,0,5.79,0,8.68a2,2,0,0,1-2.24,2c-2.68,0-5.36,0-8,0-.6,0-1.21,0-1.82,0A2,2,0,0,1,0,12.26C0,9.52,0,6.79,0,4.05A2.07,2.07,0,0,1,2.69,1.94ZM.64,5.6c0,.2,0,.38,0,.55v6c0,1.21.5,1.71,1.72,1.71H12c1.21,0,1.71-.5,1.71-1.71,0-2,0-4,0-6,0-.18,0-.35,0-.55Z"></path>
                        <path d="M5,2.34c0,.49,0,1,0,1.46a.88.88,0,1,1-1.76,0q0-1.49,0-3A.87.87,0,0,1,4.2,0,.85.85,0,0,1,5,.88C5.05,1.37,5,1.85,5,2.34Z"></path>
                        <path d="M11.14,2.35c0,.49,0,1,0,1.46a.88.88,0,1,1-1.76,0c0-1,0-1.95,0-2.92A.88.88,0,0,1,10.26,0a.86.86,0,0,1,.88,1C11.15,1.41,11.14,1.88,11.14,2.35Z"></path>
                        <path d="M6.24,10.7c1-1,1.91-2,2.83-2.9.12-.13.24-.25.37-.37a.63.63,0,0,1,.94,0,.6.6,0,0,1,0,.93l-2.89,2.9c-1.27,1.3-1.21,1.26-2.44,0-.35-.37-.72-.72-1.07-1.08a.66.66,0,0,1-.07-1,.65.65,0,0,1,1,0C5.33,9.69,5.74,10.16,6.24,10.7Z"></path>
                      </g>
                    </g>
                  </svg>
                  <span>{`Event`}</span>
                  {/* <span style={{ color: 'red' }}>(Coming Soon)</span> */}
                </a>
              </li>

              <li>
                <a
                  href="#"
                  onClick={() => {
                    handleLinkClick(CLASSES_URL, "class", true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15 12"
                    fill="none"
                  >
                    <path
                      d="M4.85195 0.012665C4.58415 0.00722948 4.324 0.0967234 4.12332 0.263313C3.92265 0.429902 3.79619 0.661359 3.76914 0.911568C3.7293 1.30326 3.76094 1.70265 3.76094 2.07013L5.28438 2.45191V1.47049H13.4395V7.70229H11.9981V6.38199H8.98868V7.7419H7.28477C7.53555 8.10278 7.81329 8.36684 7.91524 8.68151C8.06407 9.14251 8.36173 9.18322 8.78712 9.17882C9.734 9.16782 11.9418 9.15792 13.8625 9.16012C14.0119 9.16026 14.1598 9.13276 14.2979 9.07919C14.4359 9.02563 14.5614 8.94703 14.6671 8.84792C14.7727 8.7488 14.8566 8.6311 14.9138 8.50154C14.971 8.37199 15.0004 8.23312 15.0004 8.09288V1.0843C15.0004 0.803236 14.8825 0.533483 14.6721 0.333505C14.4618 0.133528 14.1759 0.0194391 13.8766 0.0159656C11.0055 -0.0115406 7.39844 0.00276277 4.85195 0.012665Z"
                      fill="black"
                    />
                    <path
                      d="M0 9.69498C0.379688 8.70476 1.02188 7.9731 2.19024 7.79486C2.43047 7.75855 2.68594 7.81136 2.93321 7.81356C3.4711 7.81356 4.02423 7.89498 4.54337 7.80256C6.0879 7.5308 7.6758 8.8753 7.49298 10.3518C7.47112 10.5996 7.34778 10.8298 7.1489 10.9939C6.95002 11.1581 6.6911 11.2434 6.42657 11.232C4.66876 11.2375 2.91915 11.2122 1.16602 11.2463C0.580081 11.2573 0.284766 10.957 0 10.5752V9.69498Z"
                      fill="black"
                    />
                    <path
                      d="M5.99291 4.94052C5.99623 5.21978 5.93992 5.49683 5.82731 5.75533C5.71469 6.01382 5.54804 6.2485 5.33722 6.44552C5.12639 6.64254 4.87565 6.79789 4.59981 6.90242C4.32396 7.00694 4.02861 7.05852 3.73118 7.05409C3.43399 7.05418 3.13979 6.99841 2.86592 6.89007C2.59205 6.78172 2.34405 6.623 2.13654 6.42325C1.92903 6.22351 1.76621 5.98678 1.65768 5.72702C1.54915 5.46727 1.49711 5.18974 1.50462 4.91081C1.5083 4.35745 1.74407 3.82774 2.16084 3.43645C2.57761 3.04516 3.1418 2.8238 3.73118 2.82034C4.02877 2.8174 4.32399 2.87021 4.59958 2.97568C4.87517 3.08115 5.12559 3.23715 5.33619 3.43457C5.54679 3.63199 5.71334 3.86686 5.8261 4.12544C5.93887 4.38402 5.99557 4.66111 5.99291 4.94052Z"
                      fill="black"
                    />
                  </svg>
                  {}
                  <span>{`Classes   ${process.env.REACT_APP_BETA}`}</span>
                  {/* <span style={{ color: 'red' }}>(Coming Soon)</span> */}
                </a>
              </li>

              {/* <li>
                              <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.94 13.97"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M17.82,2.94c-.25-.76-.53-1.51-.75-2.28a.79.79,0,0,0-.9-.66C13.79,0,11.4,0,9,0S4.16,0,1.73,0A.77.77,0,0,0,.87.63C.64,1.42.35,2.18.1,3A2,2,0,0,0,.76,5.1a2.39,2.39,0,0,0,1.36.68V12.6A1.37,1.37,0,0,0,3.49,14h11a1.38,1.38,0,0,0,1.37-1.37V5.8a2.3,2.3,0,0,0,1.4-.7A2,2,0,0,0,17.82,2.94ZM5.8,13.35H4a.46.46,0,0,1,0-.91H5.8a.46.46,0,0,1,0,.91Zm4.05,0H8.09a.46.46,0,1,1,0-.91H9.85a.46.46,0,1,1,0,.91Zm4.06,0H12.14a.46.46,0,0,1,0-.91h1.77a.46.46,0,0,1,0,.91ZM3.11,11.78v-6A6.73,6.73,0,0,0,5,5.07c1.47,1,2.39,1,4-.11a2.86,2.86,0,0,0,3.89.11,4.18,4.18,0,0,0,1.92.74v6ZM16.33,4.5a2.29,2.29,0,0,1-2.65-.39c-.58-.54-.58-.54-1.18,0a1.94,1.94,0,0,1-3-.17c-.32-.37-.59-.34-.9,0a2,2,0,0,1-3.14.14c-.57-.54-.57-.54-1.14,0a2.33,2.33,0,0,1-2.8.32,1,1,0,0,1-.42-1.23,18.92,18.92,0,0,0,.59-1.8C1.74,1,1.93,1,2.26,1H9c2.22,0,4.43,0,6.64,0,.42,0,.62.11.72.52.15.57.36,1.13.54,1.69A.94.94,0,0,1,16.33,4.5Z"></path><path d="M8.32,6.7a.51.51,0,0,0-.78.44v2.91a.51.51,0,0,0,.81.41l2.21-1.57a.51.51,0,0,0,0-.86Z"></path></g></g></svg> 
                                Classes
                                <span style={{color : 'red'}}>(Coming Soon)</span>
                              </a>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Message variant={"danger"}>
              Upload valid image (JPEG, JPG or PNG)
            </Message>
          )}
          <div style={{ display: loading ? "none" : "initial" }}>
            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={imgType == "profile" ? src : coverSrc}
                  roundedCircle
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center", margin: "5%" }}>
                ----Or----
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="name">
                  <Form.File
                    id="image-file"
                    label={imageName ? imageName : "Upload a picture"}
                    custom={true}
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setImageName(e.target.files[0].name);
                    }}
                    style={{ overflow: "hidden" }}
                  ></Form.File>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div style={{ display: loading ? "initial" : "none" }}>
            <Loader />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
          <Button variant="primary" onClick={uploadHandler}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default VendorSidebar;
